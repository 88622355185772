import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';
import { PackageType } from 'src/components/Order';
import { routeStrings } from 'src/routes';

import { ResponseStatus, SourceType, UserBusinessError } from 'src/types';

const usePaymentPlan = (orderId: string, expectDownPayment: number) => {
  const { sessionId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryString = new URLSearchParams(search);

  const { data: paymentPlans, refetch: refetchPaymentPlan } = useQuery({
    queryKey: ['payment-plan', orderId, expectDownPayment],
    queryFn: async () => {
      try {
        const installmentResponse = await axios.post(
          apiStrings.installment.getList,
          {
            ...generateDefaultAuthValues(),
            sessionId,
            orderId: orderId || '',
            expectDownPayment:
              expectDownPayment === -1 ? null : expectDownPayment,
          }
        );

        if (installmentResponse.data.status === ResponseStatus.BusinessError) {
          throw installmentResponse.data.data;
        }

        return installmentResponse.data.data;
      } catch (error: any) {
        const { resultCode = '' } = error;
        const paymentChannel = queryString.get('paymentChannel') || 'web';
        const scanQr = queryString.get('scanQr') || false;

        if (error?.response && error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&paymentChannel=${paymentChannel}&scanQr=${scanQr}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        if (resultCode === UserBusinessError.UserNotMatch) {
          navigate(`${routeStrings.error}`, {
            state: error,
          });
          throw error;
        }

        if (resultCode === UserBusinessError.UserLocked) {
          navigate(
            `${routeStrings.checkout.defaultPath}/${sessionId}/${routeStrings.checkout.error}`,
            {
              state: error,
            }
          );
          throw error;
        }

        navigate(routeStrings.error, {
          state: {
            sourceType: SourceType.Order,
            sessionId,
            paymentChannel,
            scanQr,
          },
        });
        throw error;
      }
    },
    enabled: false,
  });

  useEffect(() => {
    refetchPaymentPlan();
  }, [expectDownPayment]);

  return {
    paymentPlans:
      paymentPlans && paymentPlans.installments.length > 0
        ? paymentPlans.installments.map((installmentPackage: any) => ({
            packageId: installmentPackage.packageId || '',
            packageName: installmentPackage.packageName || '',
            type:
              installmentPackage.type === PackageType.Revolving
                ? PackageType.Bnpl
                : installmentPackage.type,
            originAmount: 0,
            totalDiscountAmount: installmentPackage.totalDiscountAmount || 0,
            firstInstallmentPercent:
              installmentPackage.firstInstallmentPercent || 0.0,
            firstInstallmentAmount:
              installmentPackage.firstInstallmentAmount || 0,
            subSeqInstallmentAmount:
              installmentPackage.subSeqInstallmentAmount || 0,
            remainingAmount: installmentPackage.remainingAmount || 0,
            tenure: installmentPackage.tenure || 0,
            serviceFee: installmentPackage.serviceFee || 0,
            totalAmount: installmentPackage.totalAmount || 0,
            priority: installmentPackage.priority || 0,
            active: installmentPackage.active || false,
          }))
        : [],
    refetchPaymentPlan,
  };
};

export default usePaymentPlan;
