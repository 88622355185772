import { Input as AntdInput } from 'antd';

import { SearchInputProps } from 'src/components/Input/index.type';

const { Search: AntdSearch } = AntdInput;

const SearchInput = ({
  value,
  placeholder,
  onValueSearched,
  onValueChanged,
  className = '',
}: SearchInputProps) => {
  return (
    <AntdSearch
      placeholder={placeholder}
      classNames={{
        input: className,
      }}
      value={value}
      onChange={onValueChanged}
      onSearch={onValueSearched}
    />
  );
};

export default SearchInput;
