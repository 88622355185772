import MerchantInfo from 'src/components/Order/CheckoutDetails/OrderDetails/MerchantInfo';
import InstallmentPlan from 'src/components/Order/CheckoutDetails/OrderDetails/InstallmentPlan';
import Promotion from 'src/components/Order/CheckoutDetails/OrderDetails/Promotion';

import { OrderDetailsProps } from 'src/components/Order/CheckoutDetails/OrderDetails/OrderDetails.type';

const OrderDetails = ({
  merchantInfo,
  installmentInfo,
  promotionInfo,
  highPriorityPackageNumber,
  appliedPackageDetails,
  dataTracking,
  setAppliedPackage,
  onStepChanged,
  userEkycStatus,
}: OrderDetailsProps) => {
  return (
    <section className="checkout-details">
      <MerchantInfo
        merchantLogo={merchantInfo.merchantLogo}
        merchantName={merchantInfo.merchantName}
        orderId={merchantInfo.orderId}
      />
      <InstallmentPlan
        bnplPlusPercent={installmentInfo.bnplPlusPercent}
        packages={installmentInfo.packages}
        currency={installmentInfo.currency}
        highPriorityPackageNumber={highPriorityPackageNumber}
        appliedPackageDetails={appliedPackageDetails}
        dataTracking={dataTracking}
        setAppliedPackage={setAppliedPackage}
        onStepChanged={onStepChanged}
        userEkycStatus={userEkycStatus}
      />
      <Promotion
        promotions={promotionInfo.promotions}
        packages={installmentInfo.packages}
        appliedPromotions={promotionInfo.appliedPromotions}
        currency={promotionInfo.currency}
        onStepChanged={onStepChanged}
      />
    </section>
  );
};

export default OrderDetails;
