import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import {
  SubmitRefundResponse,
  SubmitRefundVariables,
} from 'src/features/Refund';
import { apiStrings } from 'src/services';

const useRefund = () => {
  const { mutate: submitRefund } = useMutation<
    SubmitRefundResponse,
    unknown,
    SubmitRefundVariables
  >({
    mutationFn: async (variable: SubmitRefundVariables) => {
      try {
        const response = await axios.post(
          apiStrings.refund.submitRefund,
          variable
        );

        return response.data?.data;
      } catch (error) {
        throw error;
      }
    },
  });

  return {
    submitRefund,
  };
};

export default useRefund;
