import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OtpInput } from 'src/components/Input';
import { useRegister } from 'src/hooks/auth';

import { PinModificationProps } from 'src/types';

const PinModification = ({ pin, length }: PinModificationProps) => {
  const { t } = useTranslation();
  const [verifiedPin, setVerifiedPin] = useState('');
  const [errorValidation, setErrorValidation] = useState('');
  const { register } = useRegister();

  useEffect(() => {
    if (verifiedPin.length === 1) {
      setErrorValidation('');
    }
  }, [verifiedPin]);

  const onPinSubmit = () => {
    if (
      pin.length === length &&
      verifiedPin.length === length &&
      pin !== verifiedPin
    ) {
      return setErrorValidation(t('auth:pin.notMatchPin'));
    }

    register({ pin });
  };

  return (
    <OtpInput
      value={verifiedPin}
      syncOtp={setVerifiedPin}
      submit={onPinSubmit}
      length={length}
      error={errorValidation}
      isSecured
    />
  );
};

export default PinModification;
