export enum TooltipPlacement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export interface ICopyBoardProps {
  placement: TooltipPlacement;
  copiedValue: string;
  className?: string;
}
