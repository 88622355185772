import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DecreeModule } from 'src/components/Auth';
import PinCreation from 'src/pages/Auth/Register/PinCreation';
import PinModification from 'src/pages/Auth/Register/PinModification';
import Button from 'src/components/Button';
import { PrimaryLayout } from 'src/components/Layout';
import { AUTH_PIN_LENGTH } from 'src/constants';
import { useConfirmSharingData } from 'src/hooks/auth';
import { routeStrings } from 'src/routes';
import { hideConfirm, showConfirm } from 'src/redux/slices/uiSlice';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const CreatePin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { referenceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [pin, setPin] = useState('');
  const [isCreation, setIsCreation] = useState(true);
  const [isOpen, setIsOpen] = useState(!state?.isDecreeOpen);

  const paymentChannel = state?.paymentChannel
    ? `&paymentChannel=${state?.paymentChannel}`
    : '';
  const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';
  const callback = `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`;

  const { confirmSharingData } = useConfirmSharingData();

  const onBack = () =>
    navigate(callback, {
      state: {
        callbackUrl: state?.callbackUrl || '',
      },
    });

  const onDecreeClose = () => {
    setIsOpen(false);
    dispatch(
      showConfirm({
        open: true,
        title: t('auth:decree.decreeExitTitle'),
        content: t('auth:decree.decreeExitDescription'),
        cancelLabel: t('exit'),
        cancelHandler: () => {
          dispatch(hideConfirm());
          navigate(callback, {
            state,
          });
        },
        actionLabel: t('stay'),
        actionHandler: () => {
          setIsOpen(true);
          dispatch(hideConfirm());
        },
      })
    );
  };

  const onDecreeSubmit = () => {
    confirmSharingData({
      authRequestId: state?.authRequestId,
    });
    setIsOpen(false);
  };

  const onPinCreated = () => {
    setIsCreation(false);
  };

  return (
    <PrimaryLayout
      title={t(isCreation ? 'auth:pin.createPin' : 'auth:pin.reEnterPin')}
      subTitle={t(
        isCreation
          ? 'auth:pin.createPinDescription'
          : 'auth:pin.reEnterPinDescription'
      )}
      onBack={onBack}
    >
      <div className="auth-register">
        {isCreation ? (
          <>
            <PinCreation
              pin={pin}
              setPin={setPin}
              length={AUTH_PIN_LENGTH}
              disabledOtp={isOpen}
            />
            <Button
              title={t('continue')}
              size={ButtonSize.Large}
              type={ButtonType.Default}
              colorType={ButtonColorType.PrimaryGradient}
              onClickHandled={onPinCreated}
              disable={pin.length < AUTH_PIN_LENGTH}
              className="w--100 mt--16"
            />
          </>
        ) : (
          <PinModification pin={pin} length={AUTH_PIN_LENGTH} />
        )}
      </div>

      <DecreeModule
        open={isOpen}
        onSubmit={onDecreeSubmit}
        onClose={onDecreeClose}
      />
    </PrimaryLayout>
  );
};

export default CreatePin;
