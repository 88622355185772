import { QuinaryLayout } from 'src/components/Layout';
import { useTranslation } from 'react-i18next';

import { Steps } from 'src/features/DownloadApp';

const DownloadApp = () => {
  const { t } = useTranslation(['downloadApp']);

  const downloadApp = () => {
    return (window.location.href = process.env.REACT_APP_DYNAMIC_LINK || '');
  };

  return (
    <QuinaryLayout
      confirmButtonLabel={t('actions.download')}
      onConfirmed={downloadApp}
    >
      <div className="download-app">
        <h3 className="heading heading--xs description">{t('description')}</h3>
        <Steps />
      </div>
    </QuinaryLayout>
  );
};

export default DownloadApp;
