import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleQuestion } from 'src/assets/images/svg/circle-question.svg';
import { Drawer } from 'src/components/Drawer';
import { numberFormatter } from 'src/utils';

import { ReceiptItemProps } from 'src/components/Order/CheckoutDetails/ReceiptDetails/ReceiptDetails.type';

const ReceiptItem = ({
  label,
  value,
  currency,
  onDrawerOpened,
  onDrawerClosed,
  showDescription = false,
  description = null,
  openDrawer = false,
}: ReceiptItemProps) => {
  const { t } = useTranslation();

  return (
    <div className="receipt-details-wrapper__item">
      <p className="paragraph paragraph--sm receipt-details-wrapper__label">
        {label}
        {showDescription && description && (
          <CircleQuestion onClick={onDrawerOpened} />
        )}
      </p>
      <p className="paragraph paragraph--sm receipt-details-wrapper__value">
        {`${numberFormatter(value)} `}
        <span className="paragraph paragraph--xs">
          {t(`currency.${currency}`)}
        </span>
      </p>

      {showDescription && description && (
        <Drawer
          open={openDrawer}
          title={t('order:checkout.serviceFeeDrawer.title')}
          onClose={onDrawerClosed}
        >
          {description}
        </Drawer>
      )}
    </div>
  );
};

export default ReceiptItem;
