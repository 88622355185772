import Button from 'src/components/Button';
import CustomModal from 'src/components/Modal/CustomModal';

import { ConfirmModalProps, ModalType } from 'src/components/Modal/Modal.type';
import {
  ButtonType,
  ButtonColorType,
  ButtonSize,
} from 'src/components/Button/Button.type';

const ConfirmModal = ({
  title,
  open,
  content,
  titleClassName = '',
  closeIcon = false,
  cancelLabel,
  actionLabel,
  cancelHandler,
  actionHandler,
}: ConfirmModalProps) => {
  const props: any = {
    className: 'confirm-modal',
    titleClassName,
    open,
    title,
    type: ModalType.Confirm,
    width: 335,
    closeIcon,
    onCancel: () => {},
  };

  if (!!cancelHandler || !!actionHandler) {
    props.footer = (
      <>
        {cancelLabel && (
          <Button
            size={ButtonSize.Large}
            type={ButtonType.Default}
            colorType={ButtonColorType.Gray}
            title={cancelLabel}
            onClickHandled={cancelHandler}
          />
        )}
        {actionLabel && (
          <Button
            title={actionLabel}
            size={ButtonSize.Large}
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            onClickHandled={actionHandler}
          />
        )}
      </>
    );
  }
  return <CustomModal {...props}>{content}</CustomModal>;
};

export default ConfirmModal;
