import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useCreateEkycRequest } from 'src/hooks/ekyc';
import { routeStrings } from 'src/routes';

import { SourceModule, SourceType } from 'src/types';

const Initiate = () => {
  const { state, search } = useLocation();
  const { referenceId } = useParams();
  const { createEkycRequest } = useCreateEkycRequest();

  const params = new URLSearchParams(search);

  const callbackUrl = useMemo(() => {
    if (!!state?.callbackUrl) {
      return state?.callbackUrl;
    }

    return process.env.REACT_APP_FUNDIIN_HOMEPAGE || '';
  }, [state?.callbackUrl]);

  useEffect(() => {
    createEkycRequest({
      callbackUrl,
      redirectUrl: `${window.location.origin}${routeStrings.ekyc.defaultPath}/:ekycId/${routeStrings.ekyc.processing}?sourceType=${(params.get('sourceType') || SourceType.Register).toLowerCase()}`,
      fallbackUrl: `${window.location.origin}${routeStrings.error}`,
      referenceId: referenceId || '',
      sourceType: (
        params.get('sourceType') || SourceType.Register
      ).toUpperCase() as SourceModule,
      previousStep: (
        params.get('previousStep') || ''
      ).toUpperCase() as SourceModule,
      referralId: params.get('referralId') || '',
      paymentChannel: params.get('paymentChannel') || 'web',
      scanQr: params.get('scanQr') || false,
    });
  }, []);

  return <></>;
};

export default Initiate;
