import { Flex, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import Button from 'src/components/Button';
import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { Switch } from 'src/components/Switch';
import {
  BankList,
  RefundAccount,
  RefundDetail,
  useRefund,
  useSavedAccounts,
  AccountRefundType,
  RefundStep,
} from 'src/features/Refund';
import { routeStrings } from 'src/routes';
import { ResponseStatus } from 'src/types';

const Refund = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { refundId } = useParams();

  const { savedAccounts } = useSavedAccounts();
  const defaultAccount = savedAccounts.find(account => account.default);

  const [currentStep, setCurrentStep] = useState<RefundStep>(RefundStep.Refund);
  const [accountRefund, setAccountRefund] = useState<AccountRefundType | null>(
    null
  );
  const { submitRefund } = useRefund();

  const onStepChanged = (step: RefundStep) => setCurrentStep(step);

  const onChooseBank = (_accountRefund: AccountRefundType) => {
    setAccountRefund(_accountRefund);
  };

  const onToggleDefault = () => {
    if (!accountRefund) return;

    setAccountRefund(prevState => ({
      ...accountRefund,
      isDefault: !prevState?.isDefault,
    }));
  };

  const onSelectAccountNumber = ({
    account,
    bank,
    isDefault,
  }: AccountRefundType) => {
    setAccountRefund({
      account: {
        name: account?.name || '',
        number: account?.number || '',
      },
      bank: {
        name: bank?.name || '',
        logo: bank?.logo || '',
        id: bank?.id || '',
      },
      isDefault: !!isDefault,
    });
  };

  const handleRefundSubmit = () => {
    if (!accountRefund?.account.number || !accountRefund.bank.id) return;

    submitRefund(
      {
        accountNumber: accountRefund.account.number,
        bankId: accountRefund.bank.id,
        refundRequestId: refundId || '',
        isDefault: accountRefund.isDefault,
      },
      {
        onSuccess: data => {
          if (data.resultCode === ResponseStatus.Success) {
            navigate(
              `${routeStrings.refund.defaultPath}/${refundId}/${routeStrings.refund.success}`
            );
          } else {
            navigate(
              `${routeStrings.refund.defaultPath}/${refundId}/${routeStrings.refund.error}`,
              {
                state: {
                  resultMsg: data.resultMsg,
                },
                replace: true,
              }
            );
          }
        },
        onError: () => {
          navigate(
            `${routeStrings.refund.defaultPath}/${refundId}/${routeStrings.refund.error}`
          );
        },
      }
    );
  };

  useEffect(() => {
    setAccountRefund({
      account: {
        name: defaultAccount?.accountName || '',
        number: defaultAccount?.accountNumber || '',
      },
      bank: {
        name: defaultAccount?.bankShortName || '',
        logo: defaultAccount?.logo || '',
        id: defaultAccount?.bankId || '',
      },
      isDefault: defaultAccount?.default || false,
    });
  }, [defaultAccount]);

  if (currentStep === RefundStep.BankAccount) {
    return (
      <BankList
        onChooseBank={onChooseBank}
        onClose={() => onStepChanged(RefundStep.Refund)}
      />
    );
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Header>
        <Logo />
      </Layout.Header>
      <Layout.Content>
        <Flex
          vertical
          gap={8}
          style={{ height: '100%' }}
          justify="space-between"
        >
          <Flex vertical style={{ overflow: 'auto' }} gap={8}>
            <RefundDetail />
            <RefundAccount
              accountRefund={accountRefund}
              savedAccounts={savedAccounts}
              onSelectAccountNumber={onSelectAccountNumber}
              onShowBankAccount={() => onStepChanged(RefundStep.BankAccount)}
            />
          </Flex>
          <Flex
            vertical
            justify="space-between"
            style={{ backgroundColor: '#fff', padding: 20, flex: 1 }}
            gap={16}
          >
            <Flex vertical gap={10}>
              {accountRefund?.account.name && (
                <Flex vertical className="refund-box-divider">
                  <p className="label label--xs color--muted">
                    {t('refund:titles.beneficiaryPerson')}
                  </p>
                  <p className="paragraph paragraph--sm">
                    {accountRefund?.account.name}
                  </p>
                </Flex>
              )}

              <Flex justify="space-between" align="center" gap={16}>
                <p className="paragraph paragraph--sm refund-detail__value">
                  {t('refund:titles.setIsDefault')}
                </p>
                <Switch
                  checked={!!accountRefund?.isDefault}
                  onChanged={onToggleDefault}
                />
              </Flex>
            </Flex>

            <Button
              title={t('common:send')}
              type={ButtonType.Default}
              colorType={ButtonColorType.PrimaryGradient}
              size={ButtonSize.Large}
              onClickHandled={handleRefundSubmit}
              disable={
                !accountRefund?.account.number ||
                !accountRefund?.account.name ||
                !accountRefund.bank.id
              }
            />
          </Flex>
        </Flex>
      </Layout.Content>
    </Layout>
  );
};

export default Refund;
