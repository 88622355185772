import { InputNumber as AntdInputNumber } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';

import ValidatedError from 'src/components/ValidatedError';

import { NumberInputProps } from 'src/components/Input/index.type';

const NumberInput = ({
  name,
  placeholder,
  value,
  onValueChanged,
  validate,
  formatter,
  addonAfter,
  addonBefore,
  prefix,
  suffix,
  min = 0,
  max = undefined,
  label = '',
  required = false,
  className = '',
}: NumberInputProps) => {
  const [field, meta] = useField({
    name,
    validate,
    value,
  });

  return (
    <div className="d-flex flex-col">
      {label && (
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>
      )}

      <div className={!!addonAfter || !!addonBefore ? 'input' : ''}>
        <div className="input__addon">{addonBefore && addonBefore}</div>

        <div>
          <AntdInputNumber
            {...field}
            type="tel"
            onChange={onValueChanged}
            placeholder={placeholder}
            className={classNames({
              [className]: !!className,
              'border--error': meta.touched && meta.error,
            })}
            prefix={prefix && prefix}
            suffix={suffix && suffix}
            formatter={formatter && formatter}
            min={min}
            max={max}
          />

          <ValidatedError touched={meta.touched} error={meta.error} />
        </div>

        <div className="input__addon">{addonAfter && addonAfter}</div>
      </div>
    </div>
  );
};

export default NumberInput;
