import classNames from 'classnames';

import { ReactComponent as BackIcon } from 'src/assets/images/svg/arrow-left.svg';

import { PrimaryLayoutProps } from 'src/components/Layout/PrimaryLayout/PrimaryLayout.type';

const PrimaryLayout = ({
  title,
  subTitle,
  children,
  footer,
  isMasterTitle = false,
  className = '',
  subTitleClassName = '',
  onBack,
}: PrimaryLayoutProps) => {
  return (
    <div
      className={classNames('primary-layout', {
        [className]: !!className,
      })}
    >
      <div className="header">
        <div className="header__background" />
        {onBack && <BackIcon className="header__icon" onClick={onBack} />}
        {isMasterTitle && (
          <h1 className="label label--md header__title header__title">
            {title}
          </h1>
        )}
      </div>

      <div className="body">
        {((!isMasterTitle && title) || subTitle) && (
          <div className="body__title">
            {!isMasterTitle && (
              <h1 className="heading heading--xs body__title--primary">
                {title}
              </h1>
            )}
            {subTitle && (
              <p
                className={classNames(
                  'paragraph paragraph--sm body__title--secondary',
                  {
                    [subTitleClassName]: !!subTitleClassName,
                  }
                )}
              >
                {subTitle}
              </p>
            )}
          </div>
        )}

        <div className="body__content">{children}</div>
      </div>

      <div className="footer">{footer}</div>
    </div>
  );
};

export default PrimaryLayout;
