import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// import { useTrueID } from 'src/hooks/ekyc';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultEkycValues } from 'src/utils';

import {
  CreateEkycRequest,
  EkycResultError,
  ResponseStatus,
  SourceType,
} from 'src/types';

const useCreateEkycRequest = () => {
  const navigate = useNavigate();
  // const initTrueID = useTrueID();
  const defaultValues = generateDefaultEkycValues();

  const { mutate: createEkycRequest } = useMutation({
    mutationFn: async (variables: CreateEkycRequest) => {
      try {
        const response = await axios.post(apiStrings.ekyc.create, {
          ...defaultValues,
          callbackUrl: variables.callbackUrl,
          redirectUrl: variables.redirectUrl,
          fallbackUrl: variables.fallbackUrl,
          referenceId: variables.referenceId,
          sourceType: variables.sourceType,
          previousStep: variables.previousStep,
          referralId: variables.referralId,
        });
        return response.data || '';
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async (response, variables) => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === EkycResultError.GetAccessTokenFail) {
          const paymentChannel =
            variables.sourceType.toUpperCase() === SourceType.Order
              ? `&paymentChannel=${variables.paymentChannel}`
              : '';
          const scanQr =
            variables.sourceType.toUpperCase() === SourceType.Order
              ? `&scanQr=${variables.scanQr}`
              : false;

          return navigate(routeStrings.error, {
            state: {
              callbackUrl: `${routeStrings.auth.defaultPath}/${variables.referenceId}?sourceType=${(variables.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
            },
          });
        }

        if (response.data.resultCode === EkycResultError.ManualReject) {
          return navigate(
            `${routeStrings.ekyc.defaultPath}/${variables.referenceId}/${routeStrings.ekyc.failed}`,
            {
              state: {
                callbackUrl: variables.callbackUrl || '',
                sourceType: variables.sourceType,
                paymentChannel: variables.paymentChannel,
                scanQr: variables.scanQr,
              },
            }
          );
        }

        if (response.data.resultCode === EkycResultError.MaximumViolate) {
          return navigate(
            `${routeStrings.ekyc.defaultPath}/${variables.referenceId}/${routeStrings.ekyc.pending}`,
            {
              state: {
                callbackUrl: variables.callbackUrl || '',
                sourceType: variables.sourceType,
                paymentChannel: variables.paymentChannel,
                scanQr: variables.scanQr,
              },
            }
          );
        }

        return navigate(
          `${routeStrings.ekyc.defaultPath}/${variables.referenceId}/${routeStrings.ekyc.success}`,
          {
            state: {
              callbackUrl: variables.callbackUrl || '',
              sourceType: variables.sourceType,
              paymentChannel: variables.paymentChannel,
              scanQr: variables.scanQr,
            },
          }
        );
      }

      navigate(routeStrings.ekyc.starting, {
        state: {
          callbackUrl: variables.callbackUrl || '',
          sourceType: variables.sourceType,
          paymentChannel: variables.paymentChannel,
          scanQr: variables.scanQr,
          redirectUrl: response.data.ekycUrl || '',
          accessToken: response.data.accessToken,
          clientId: response.data.clientId,
          ekycId: response.data.ekycId,
        },
      });
    },

    onError: (error: any, variables) => {
      if (error?.response && error?.response?.status === 401) {
        return navigate(
          `${routeStrings.auth.defaultPath}/${variables.referenceId}?sourceType=${variables.sourceType.toLowerCase()}`,
          {
            state: {
              callbackUrl: variables.callbackUrl || '',
            },
            replace: true,
          }
        );
      }

      navigate(routeStrings.error, {
        state: {
          callbackUrl: variables.callbackUrl || '',
        },
      });
    },
  });

  return { createEkycRequest };
};

export default useCreateEkycRequest;
