import { Form, Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Button from 'src/components/Button';
import { Input } from 'src/components/Input';
import { PrimaryLayout } from 'src/components/Layout';
import { STORAGE_KEY } from 'src/constants';
import { useCreateAuthenRequest } from 'src/hooks/auth';
import { removeItemLocalStorage, validatePhoneNumber } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { SourceModule } from 'src/types';

const InputPhoneNumber = () => {
  const { t } = useTranslation();
  const { referenceId } = useParams();
  const { search, state } = useLocation();
  const queryString = new URLSearchParams(search);

  const paymentChannel = queryString.get('paymentChannel') || 'web';
  const scanQr = queryString.get('scanQr') || false;
  const sourceType = (
    queryString.get('sourceType') || ''
  ).toUpperCase() as SourceModule;

  useEffect(() => {
    removeItemLocalStorage(STORAGE_KEY.accessToken);
  }, []);

  const {
    createAuthenRequest,
    errorMessage,
    setErrorMessage,
    isAuthRequestCreating,
  } = useCreateAuthenRequest();

  const callbackUrl: string = useMemo(() => {
    if (!!state?.callbackUrl) {
      return state?.callbackUrl;
    }

    return process.env.REACT_APP_FUNDIIN_HOMEPAGE || '';
  }, [state?.callbackUrl]);

  const onSubmit = (values: any) => {
    createAuthenRequest({
      phoneNumber: values.phoneNumber,
      sourceType,
      callbackUrl,
      referenceId: referenceId || '',
      paymentChannel,
      scanQr,
    });
  };

  const onValueChanged = (
    event: any,
    setFieldValue: (name: string, value: string) => void
  ) => {
    event.preventDefault();
    setErrorMessage('');
    setFieldValue('phoneNumber', event.target.value);
  };

  return (
    <Formik
      initialValues={{ phoneNumber: '' }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isValid, setFieldValue }) => {
        return (
          <Form className="input-phone">
            <PrimaryLayout title={t('inputPhoneNumber')}>
              <div className="d-flex flex-col gap--16">
                <Input
                  name="phoneNumber"
                  placeholder={t('inputPhoneNumber')}
                  type="tel"
                  className="input paragraph paragraph--md input-phone__input"
                  validate={value =>
                    validatePhoneNumber(
                      t,
                      value,
                      t('validation.phoneNumber').toLowerCase()
                    )
                  }
                  errorMessage={errorMessage}
                  value={values.phoneNumber}
                  onValueChanged={event => onValueChanged(event, setFieldValue)}
                />

                <Button
                  title={t('continue')}
                  type={ButtonType.Default}
                  colorType={ButtonColorType.PrimaryGradient}
                  size={ButtonSize.Large}
                  disable={
                    !values.phoneNumber || !isValid || isAuthRequestCreating
                  }
                  className="w--100"
                  isSubmit
                />
              </div>
            </PrimaryLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InputPhoneNumber;
