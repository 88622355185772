import { StepProps } from 'antd';

export enum StepSize {
  Default = 'default',
  Small = 'small',
}

export enum StepsDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum StepStatus {
  Wait = 'wait',
  Process = 'process',
  Finish = 'finish',
  Error = 'error',
}

export interface StepsProps {
  steps: StepProps[];
  initial?: number;
  current?: number;
  className?: string;
  rootClassName?: string;
  responsive?: boolean;
  status?: StepStatus;
  size?: StepSize;
  direction?: StepsDirection;
}
