import {
  OrderDetails,
  PackageDetails,
} from 'src/components/Order/CheckoutDetails/OrderDetails';
import { ReceiptDetails } from 'src/components/Order/CheckoutDetails/ReceiptDetails';
import { ReceiptExporting } from 'src/components/Order/CheckoutDetails/ReceiptExporting';
import { Policy } from 'src/components/Order/CheckoutDetails/Policy';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { actionTracking, ModuleTracking } from 'src/utils';

import { CheckoutDetailsProps } from 'src/components/Order/CheckoutDetails/CheckoutDetails.type';

const CheckoutDetails = ({
  orderDetails,
  appliedPromotions,
  receiptDetails,
  exportingReceipt,
  currency,
  highPriorityPackageNumber,
  appliedPackageDetails,
  userEkycStatus,
  setAppliedPackage,
  onStepChanged,
}: CheckoutDetailsProps) => {
  const dataTracking = (packageTarget?: PackageDetails) => {
    actionTracking(
      TRIGGER_EVENTS.selectTenor,
      ModuleTracking.Order,
      'order_checkout',
      {
        [DATA_LAYER_VARIABLES.orderId]: orderDetails.merchantInfo.orderId,
        [DATA_LAYER_VARIABLES.ekycStatus]: userEkycStatus,
        [DATA_LAYER_VARIABLES.tenor]: packageTarget?.packageId,
        [DATA_LAYER_VARIABLES.orderAmount]: receiptDetails.orderValue,
        [DATA_LAYER_VARIABLES.totalPayment]: receiptDetails.downPaymentValue,
        [DATA_LAYER_VARIABLES.serviceFee]: receiptDetails.serviceFee,
        [DATA_LAYER_VARIABLES.downPayment]: receiptDetails.downPaymentValue,
      }
    );
  };

  return (
    <div className="checkout">
      <OrderDetails
        merchantInfo={orderDetails.merchantInfo}
        installmentInfo={{
          bnplPlusPercent: orderDetails.installmentPlans.bnplPlusPercent,
          packages: orderDetails.installmentPlans.packages,
          currency,
        }}
        promotionInfo={{
          promotions: orderDetails.promotions,
          appliedPromotions: appliedPromotions,
          currency,
        }}
        highPriorityPackageNumber={highPriorityPackageNumber}
        appliedPackageDetails={appliedPackageDetails}
        dataTracking={dataTracking}
        setAppliedPackage={setAppliedPackage}
        onStepChanged={onStepChanged}
        userEkycStatus={userEkycStatus}
      />
      <ReceiptDetails
        orderValue={receiptDetails.orderValue}
        downPaymentValue={receiptDetails.downPaymentValue}
        payLaterValue={receiptDetails.payLaterValue}
        serviceFee={receiptDetails.serviceFee}
        tempValue={receiptDetails.tempValue}
        currency={currency}
        appliedPromotions={appliedPromotions}
        userEkycStatus={userEkycStatus}
        appliedPackageDetails={appliedPackageDetails}
        onStepChanged={onStepChanged}
      />
      <ReceiptExporting
        exportingReceipt={exportingReceipt}
        onStepChanged={onStepChanged}
      />
      <Policy />
    </div>
  );
};

export default CheckoutDetails;
