import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as VirtualAccountIcon } from 'src/assets/images/svg/money-bill-transfer.svg';
import { ReactComponent as CashIcon } from 'src/assets/images/svg/hand-hold-box.svg';
import { ReactComponent as AtmIcon } from 'src/assets/images/svg/atm.svg';
import { ReactComponent as BankIcon } from 'src/assets/images/svg/bank.svg';
import { ReactComponent as WalletIcon } from 'src/assets/images/svg/wallet.svg';
import { ReactComponent as CreditCardIcon } from 'src/assets/images/svg/credit-card.svg';
import { ReactComponent as CashRegisterIcon } from 'src/assets/images/svg/cash-register.svg';
import { PaymentMethodEnum } from 'src/components/Payment';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import { ResponseStatus, SourceType, UserBusinessError } from 'src/types';

const usePaymentMethodList = () => {
  const navigate = useNavigate();
  const { paymentTransactionId = '' } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: ['get-payment-methods'],
    queryFn: async () => {
      try {
        const response = await axios.post(
          apiStrings.payment.getPaymentMethods,
          {
            ...generateDefaultAuthValues(),
            paymentTransactionId,
          }
        );

        if (response.data.status === ResponseStatus.BusinessError) {
          throw response.data.data;
        }

        return response.data.data;
      } catch (error: any) {
        const { resultCode = '' } = error;
        if (error?.response && error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${paymentTransactionId}?sourceType=${SourceType.Order.toLowerCase()}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        if (resultCode === UserBusinessError.UserNotMatch) {
          navigate(`${routeStrings.error}`, {
            state: error,
          });
          throw error;
        }

        if (resultCode === UserBusinessError.UserLocked) {
          navigate(
            `${routeStrings.checkout.defaultPath}/${paymentTransactionId}/${routeStrings.checkout.error}`,
            {
              state: error,
            }
          );
          throw error;
        }

        navigate(routeStrings.error);
        throw error;
      }
    },
    select: response => {
      const getWhiteLogo = (method: string) => {
        let logoIcon;

        switch (method) {
          case PaymentMethodEnum.VirtualAccount:
            logoIcon = <VirtualAccountIcon />;
            break;
          case PaymentMethodEnum.Atm:
            logoIcon = <AtmIcon />;
            break;
          case PaymentMethodEnum.AppBanking:
            logoIcon = <BankIcon />;
            break;
          case PaymentMethodEnum.CreditCard:
            logoIcon = <CreditCardIcon />;
            break;
          case PaymentMethodEnum.EWallet:
            logoIcon = <WalletIcon />;
            break;
          case PaymentMethodEnum.InStorePayment:
            logoIcon = <CashRegisterIcon />;
            break;
          case PaymentMethodEnum.Cod:
            logoIcon = <CashIcon />;
            break;
          default:
            logoIcon = <></>;
            break;
        }

        return logoIcon;
      };

      return {
        orderId: response?.orderId || '',
        amount: response?.amount || 0,
        currency: response?.currency || '',
        paymentTokens: response?.paymentTokens || [],
        paymentTransactionId: response?.paymentTransactionId || '',
        paymentMethods:
          response?.paymentMethods.length > 0
            ? response?.paymentMethods.map((paymentMethod: any) => {
                return {
                  method: paymentMethod?.method,
                  token: paymentMethod?.method,
                  paymentFee: paymentMethod?.paymentFee,
                  provider: paymentMethod?.provider,
                  paymentLogo: getWhiteLogo(paymentMethod?.method || ''),
                  name: paymentMethod?.name || '',
                };
              })
            : [],
      };
    },
  });

  return {
    isPaymentFetching: isFetching,
    paymentDetails: {
      orderId: data?.orderId || '',
      amount: data?.amount || 0,
      currency: (data?.currency || '').toLowerCase(),
      paymentTokens: data?.paymentTokens || [],
      paymentMethods: data?.paymentMethods || [],
      paymentTransactionId: data?.paymentTransactionId || '',
    },
  };
};

export default usePaymentMethodList;
