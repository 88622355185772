import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as HexagonExclamation } from 'src/assets/images/svg/hexagon-exclamation.svg';
import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';
import { STORAGE_KEY } from 'src/constants';
import { removeItemLocalStorage } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { RefundResponseStatus } from 'src/features/Refund';
import { SourceType } from 'src/types';
import { routeStrings } from 'src/routes';

const Error = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { refundId } = useParams();

  const onBack = () => {
    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const onLogin = () => {
    removeItemLocalStorage(STORAGE_KEY.accessToken);
    navigate(
      `${routeStrings.auth.defaultPath}/${refundId}?sourceType=${SourceType.Refund.toLowerCase()}`,
      {
        state: {
          callbackUrl: `${routeStrings.refund.defaultPath}/${refundId}`,
        },
        replace: true,
      }
    );
  };

  return (
    <>
      <SecondaryLayout
        logo={<Logo />}
        image={<HexagonExclamation />}
        title={t('refund:message.error.errorOccur')}
        description={state?.resultMsg || t('error.systemError')}
        footer={
          <>
            {[RefundResponseStatus.UserIdNotMatch].includes(
              state?.resultCode
            ) && (
              <Button
                title={t('login')}
                type={ButtonType.Default}
                colorType={ButtonColorType.PrimaryGradient}
                size={ButtonSize.Large}
                onClickHandled={onLogin}
              />
            )}
            <Button
              title={t('close')}
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              size={ButtonSize.Large}
              onClickHandled={onBack}
            />
          </>
        }
      />
    </>
  );
};

export default Error;
