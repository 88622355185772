import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import dayjs from 'dayjs';

import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

const useSendFeedback = () => {
  const { mutate: sendFeedback } = useMutation({
    mutationFn: async (variables: {
      content: string;
      referenceId: string;
      sourceType: string;
    }) => {
      try {
        const response = await axios.post(apiStrings.feedback.send, {
          ...generateDefaultAuthValues(),
          startTime: dayjs().toISOString(),
          content: variables.content,
          referenceId: variables.referenceId,
          sourceType: variables.sourceType,
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },
  });

  return { sendFeedback };
};

export default useSendFeedback;
