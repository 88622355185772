import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as ErrorImage } from 'src/assets/images/svg/universal-error.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const Error = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onBack = () => {
    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  return (
    <SecondaryLayout
      logo={<Logo />}
      image={<ErrorImage />}
      title={t('ekyc:identityStatus.errorTitle')}
      description={
        state?.resultMsg
          ? state?.resultMsg
          : t('ekyc:identityStatus.errorDescription')
      }
      footer={
        <Button
          title={t('close')}
          type={ButtonType.Default}
          colorType={ButtonColorType.Gray}
          size={ButtonSize.Large}
          onClickHandled={onBack}
        />
      }
    />
  );
};

export default Error;
