import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import { ResponseStatus, SourceType, UserBusinessError } from 'src/types';

const useQueryStatus = () => {
  const { paymentTransactionId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [ignoreLoading, setIgnoreLoading] = useState<boolean>(false);

  const { data, refetch } = useQuery({
    queryKey: ['get-payment-status'],
    queryFn: async () => {
      try {
        const response = await axios.post(
          apiStrings.payment.getPaymentStatus,
          {
            ...generateDefaultAuthValues(),
            paymentTransactionId,
          },
          {
            params: { ignoreLoading },
          }
        );

        if (response.data.status === ResponseStatus.BusinessError) {
          throw response.data.data;
        }

        return response.data.data;
      } catch (error: any) {
        const { resultCode = '' } = error;

        // Browser reject axios's request
        if (error.code === 'ERR_NETWORK') {
          return;
        }

        if (error?.response && error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${paymentTransactionId}?sourceType=${SourceType.Order.toLowerCase()}`,
            {
              state: {
                ...state,
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        if (resultCode === UserBusinessError.UserNotMatch) {
          navigate(`${routeStrings.error}`, {
            state: error,
          });
          throw error;
        }

        if (resultCode === UserBusinessError.UserLocked) {
          navigate(
            `${routeStrings.checkout.defaultPath}/${paymentTransactionId}/${routeStrings.checkout.error}`,
            {
              state: error,
            }
          );
          throw error;
        }

        navigate(routeStrings.error);
        throw error;
      }
    },
    enabled: false,
  });

  useEffect(() => {
    if (data && ignoreLoading) {
      return;
    }

    setIgnoreLoading(true);
  }, [data]);

  return {
    paymentStatusDetails: data,
    refetchPaymentStatus: refetch,
  };
};

export default useQueryStatus;
