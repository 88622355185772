import { configureStore } from '@reduxjs/toolkit';

import uiReducer from 'src/redux/slices/uiSlice';

const store = configureStore({
  reducer: {
    ui: uiReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type ReduxState = ReturnType<typeof store.getState>;

export default store;
