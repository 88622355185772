import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import { CheckoutStep } from 'src/components/Order';

import { ButtonColorType, ButtonType } from 'src/components/Button/Button.type';
import { ReceiptExportingProps } from 'src/components/Order/CheckoutDetails/ReceiptExporting/ReceiptExporting.type';

const ReceiptExporting = ({
  onStepChanged,
  exportingReceipt,
}: ReceiptExportingProps) => {
  const { t } = useTranslation();

  return (
    <section className="receipt-exporting">
      <div className="receipt-exporting__content">
        <p className="label label--sm receipt-exporting__content--primary">
          {t('order:checkout.receiptExporting.exportEmail')}
        </p>
        {exportingReceipt && (
          <p className="paragraph paragraph--sm receipt-exporting__content--secondary">
            {exportingReceipt.email}
          </p>
        )}
      </div>

      <Button
        type={ButtonType.Text}
        colorType={ButtonColorType.Purple}
        title={t(`${exportingReceipt ? 'edit' : 'addEmail'}`)}
        onClickHandled={() => onStepChanged(CheckoutStep.Billing)}
      />
    </section>
  );
};

export default ReceiptExporting;
