import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import Button from 'src/components/Button';
import { Input, NumberInput, TextArea } from 'src/components/Input';
import { useGetQrDetails, useInitTransaction } from 'src/hooks/staticQr';
import { numberFormatter, validatePhoneNumber } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const StaticQr = () => {
  const { t } = useTranslation();
  const { qrDetails, isQrDetailsFetching } = useGetQrDetails();
  const { initTransaction, isTransactionInitiating } = useInitTransaction();

  const onTransactionInitiated = (values: any) => {
    initTransaction({
      amount: values.paymentAmount,
      description: values.paymentDescription,
      currency: qrDetails?.currency,
      referralCode: values.referralCode || '',
    });
  };

  const validateAmount = (value: number) => {
    if (!value) {
      return t('validation.required', {
        fieldName: t('staticQr:paymentAmount').toLowerCase(),
      });
    }

    if (value < qrDetails?.minOrderValue) {
      return `${t('staticQr:error.min', {
        min: numberFormatter(qrDetails?.minOrderValue || 0),
      })} ${t(`currency.${(qrDetails?.currency || '').toLowerCase()}`)}`;
    }

    if (value > qrDetails?.maxOrderValue) {
      return `${t('staticQr:error.max', {
        max: numberFormatter(qrDetails?.maxOrderValue || 0),
      })} ${t(`currency.${(qrDetails?.currency || '').toLowerCase()}`)}`;
    }

    return '';
  };

  return (
    !isQrDetailsFetching && (
      <Formik
        initialValues={{
          paymentAmount: 0,
          paymentDescription: '',
          referralCode: '',
        }}
        onSubmit={onTransactionInitiated}
      >
        {({ values, setFieldValue }) => {
          const onPaymentAmountChanged = (value: string | number | null) => {
            setFieldValue('paymentAmount', value || 0);
          };

          return (
            <Form className="static-qr">
              <header className="static-qr__header">
                <Logo />
              </header>
              <div className="static-qr-main">
                <h4 className="heading heading--xs static-qr-main__title">
                  {t('staticQr:paymentTitle')}
                </h4>

                <section className="static-qr-main-merchant">
                  <div className="static-qr-main-merchant__name">
                    <p className="label label--sm">{qrDetails?.merchantName}</p>
                    <p className="paragraph paragraph--xs">
                      {qrDetails?.merchantAddress}
                    </p>
                  </div>

                  <img
                    src={qrDetails?.merchantLogo}
                    alt={qrDetails?.merchantName || t('staticQr:altImage')}
                    className="static-qr-main-merchant__logo"
                  />
                </section>

                <NumberInput
                  name="paymentAmount"
                  value={+values.paymentAmount}
                  placeholder={t('staticQr:paymentAmountPlaceholder')}
                  label={t('staticQr:paymentAmount')}
                  prefix={t(
                    `currency.${(qrDetails?.currency || '').toLowerCase()}`
                  )}
                  className="static-qr-main__input"
                  onValueChanged={onPaymentAmountChanged}
                  formatter={numberFormatter}
                  required
                  validate={validateAmount}
                />

                <Input
                  type="tel"
                  name="referralCode"
                  label={t('staticQr:referralCode')}
                  placeholder={t('staticQr:referralCodePlaceholder')}
                  value={values.referralCode}
                  onValueChanged={(event: any) =>
                    setFieldValue('referralCode', event.target.value)
                  }
                  validate={value =>
                    validatePhoneNumber(
                      t,
                      value,
                      t('staticQr:referralCode'),
                      false
                    )
                  }
                  className="input static-qr-main__input"
                />

                <TextArea
                  name="paymentDescription"
                  label={t('staticQr:paymentDescription')}
                  placeholder={t('staticQr:paymentDescriptionPlaceholder')}
                  className="static-qr-main__input"
                  maxLength={160}
                  showCount={{
                    formatter: ({ value, maxLength }) => {
                      return (
                        <span>{`${value.length}/${maxLength} ${t(
                          'characters'
                        )}`}</span>
                      );
                    },
                  }}
                />

                <Button
                  type={ButtonType.Default}
                  colorType={ButtonColorType.PrimaryGradient}
                  size={ButtonSize.Large}
                  title={t('staticQr:continue')}
                  className="w--100 mt--16"
                  isSubmit
                  disable={
                    !values.paymentAmount ||
                    values.paymentAmount < qrDetails?.minOrderValue ||
                    values.paymentAmount > qrDetails?.maxOrderValue ||
                    isTransactionInitiating
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    )
  );
};

export default StaticQr;
