import { useTranslation } from 'react-i18next';

import { MerchantInfoProps } from 'src/components/Order/CheckoutDetails/OrderDetails/OrderDetails.type';

const MerchantInfo = ({
  merchantName,
  merchantLogo,
  orderId,
}: MerchantInfoProps) => {
  const { t } = useTranslation();

  return (
    <section className="merchant-info">
      <div className="merchant-info__content">
        <p className="label label--sm merchant-info__content--primary">
          {merchantName}
        </p>
        <p className="paragraph paragraph--xs merchant-info__content--secondary">{`${t('order:checkout.merchantInfo.orderId')} ${orderId}`}</p>
      </div>

      <img
        src={merchantLogo || ''}
        alt={t('order:checkout.merchantInfo.brandLogo')}
        className="merchant-info__logo"
      />
    </section>
  );
};

export default MerchantInfo;
