import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { find, orderBy, reduce } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  AppliedPromotion,
  CheckoutDetailsType,
  EkycStatus,
  PackageType,
  PromotionStatus,
} from 'src/components/Order';
import { ExportingReceipt } from 'src/components/Order/CheckoutDetails/ReceiptExporting';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import {
  CheckoutResponseStatus,
  EkycPreviousStep,
  ResponseStatus,
  SourceType,
  UserBusinessError,
} from 'src/types';

const initialCheckoutDetails: CheckoutDetailsType = {
  merchantInfo: {
    merchantName: '',
    merchantLogo: '',
    orderId: '',
  },
  installmentPlans: {
    bnplPlusPercent: [-1],
    packages: [],
    currency: '',
  },
  promotions: [],
  appliedPromotions: [],
  receiptDetails: {
    orderValue: 0,
    downPaymentValue: 0,
    payLaterValue: 0,
    serviceFee: 0,
    tempValue: 0,
  },
  currency: '',
  ekycStatus: EkycStatus.Pending,
  highPriorityPackageNumber: 0,
  userId: '',
};

const useCheckoutDetails = () => {
  const { sessionId } = useParams();
  const { search } = useLocation();
  const [appliedCoupon, setAppliedCoupon] = useState<string>('');
  const [appliedPackage, setAppliedPackage] = useState<string>('');
  const [appliedPercent, setAppliedPercent] = useState<number>(-1);
  const [exportingReceipt, setExportingReceipt] =
    useState<ExportingReceipt | null>(null);
  const queryString = new URLSearchParams(search);
  const navigate = useNavigate();

  const {
    data,
    isFetching: isOrderFetching,
    refetch: refetchOrder,
  } = useQuery({
    queryKey: ['get-checkout-details'],
    queryFn: async () => {
      try {
        const orderResponse = await axios.post(apiStrings.order.initOrder, {
          ...generateDefaultAuthValues(),
          sessionId,
          paymentChannel: queryString.get('paymentChannel') || 'web',
          scanQr: queryString.get('scanQr') || false,
          couponCodes: appliedCoupon ? [appliedCoupon] : null,
        });

        if (
          orderResponse.data.status === ResponseStatus.BusinessError ||
          orderResponse.data.data.autoEkyc
        ) {
          throw orderResponse.data.data;
        }

        const { order } = orderResponse.data.data;

        const installmentResponse = await axios.post(
          apiStrings.installment.getList,
          {
            ...generateDefaultAuthValues(),
            sessionId,
            orderId: order.id || '',
            expectDownPayment: appliedPercent === -1 ? null : appliedPercent,
          }
        );

        return {
          order: orderResponse.data.data,
          installment: installmentResponse.data.data,
        };
      } catch (error: any) {
        const { resultCode = '', orderId = '' } = error;
        const paymentChannel = queryString.get('paymentChannel') || 'web';
        const scanQr = queryString.get('scanQr') || false;

        if (error?.response && error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&paymentChannel=${paymentChannel}&scanQr=${scanQr}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        if (error.autoEkyc) {
          navigate(
            `${routeStrings.ekyc.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&previousStep=${EkycPreviousStep.Order}&paymentChannel=${paymentChannel}&scanQr=${scanQr}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
            }
          );
          throw error;
        }

        let destination: string = routeStrings.error;
        let state: any;

        switch (resultCode) {
          case UserBusinessError.UserNotMatch:
            state = error;
            break;
          case CheckoutResponseStatus.UserExceedLimitation:
          case CheckoutResponseStatus.UserNotSupportedPayLater:
            destination = `${routeStrings.checkout.defaultPath}/${sessionId}/${routeStrings.checkout.exceedLimit}`;
            state = error;
            break;
          case CheckoutResponseStatus.OrderIsProcessing:
            destination = `${routeStrings.orderStatus}/${orderId}`;
            break;
          case UserBusinessError.UserLocked:
          case CheckoutResponseStatus.OrderNotFound:
          case CheckoutResponseStatus.OrderExpired:
          case CheckoutResponseStatus.OrderStatusInvalid:
          case CheckoutResponseStatus.PhoneNumberNotMatch:
            destination = `${routeStrings.checkout.defaultPath}/${sessionId}/${routeStrings.checkout.error}`;
            state = {
              ...error,
              sessionId: sessionId,
              paymentChannel,
              scanQr,
            };
            break;
          case CheckoutResponseStatus.ReachOBLimitation:
            destination = routeStrings.downloadApp;
            break;
          default:
            break;
        }

        navigate(destination, { state });
        throw error;
      }
    },
    select: response => {
      const { order, installment } = response;
      const currency = (order.order.currency || '').toLowerCase();

      const getAppliedCoupons = () => {
        if (!appliedPackage || installment.installments.length === 0) {
          return [];
        }

        const packageTarget = find(
          installment.installments,
          packageItem => packageItem.packageId === appliedPackage
        );

        if (!packageTarget || packageTarget?.couponApplies.length === 0) {
          return [];
        }

        return packageTarget?.couponApplies.map((appliedItem: any) => ({
          title: appliedItem.couponName || '',
          code: appliedItem.code || '',
          description: appliedItem.description || '',
          amount: appliedItem.discountAmount || 0,
          status: appliedItem.status || PromotionStatus.Available,
          isDisplay: true,
        }));
      };

      const getHighestPriority = () => {
        if (installment.installments.length < 1) {
          return 0;
        }

        return orderBy(installment.installments, 'priority', 'desc')[0]
          .priority;
      };

      const getReceiptDetails = () => {
        const totalCouponValue = reduce(
          getAppliedCoupons(),
          (sum: number, currentCoupon: AppliedPromotion) =>
            sum +
            (currentCoupon.status === PromotionStatus.Available
              ? currentCoupon.amount
              : 0),
          0
        );

        if (order.user.ekycStatus === EkycStatus.Approved) {
          if (!appliedPackage) {
            const highestPackage = find(
              installment.installments,
              packageItem => packageItem.priority === getHighestPriority()
            );

            return {
              downPaymentValue: highestPackage
                ? highestPackage.firstInstallmentAmount
                : 0,
              payLaterValue: highestPackage
                ? highestPackage.remainingAmount
                : 0,
              serviceFee: highestPackage ? highestPackage.serviceFee : 0,
              tempValue: highestPackage
                ? highestPackage.totalAmount
                : (order.order.originalAmount || 0) - totalCouponValue,
            };
          }

          const target = find(
            installment.installments,
            packageItem => packageItem.packageId === appliedPackage
          );
          return {
            downPaymentValue: target ? target.firstInstallmentAmount : 0,
            payLaterValue: target ? target.remainingAmount : 0,
            serviceFee: target ? target.serviceFee : 0,
            tempValue: target ? target.totalAmount : 0,
          };
        } else {
          const paynowTarget = find(
            installment.installments,
            packageItem =>
              packageItem.packageId === appliedPackage &&
              packageItem.type === PackageType.PayNow
          );

          return {
            downPaymentValue: paynowTarget
              ? paynowTarget.firstInstallmentAmount
              : 0,
            payLaterValue: paynowTarget ? paynowTarget.remainingAmount : 0,
            serviceFee: paynowTarget ? paynowTarget.serviceFee : 0,
            tempValue: paynowTarget
              ? paynowTarget.totalAmount
              : (order.order.originalAmount || 0) - totalCouponValue,
          };
        }
      };

      return {
        merchantInfo: {
          merchantName:
            order.merchant.name ||
            initialCheckoutDetails.merchantInfo.merchantName,
          merchantLogo:
            order.merchant.logo ||
            initialCheckoutDetails.merchantInfo.merchantLogo,
          orderId:
            order.order.id || initialCheckoutDetails.merchantInfo.orderId,
        },
        installmentPlans: {
          bnplPlusPercent:
            installment.supportFirstInstallmentBnplPlus.length > 0
              ? [...installment.supportFirstInstallmentBnplPlus, -1]
              : [-1],
          packages:
            installment.installments.length > 0
              ? installment.installments.map((installmentPackage: any) => ({
                  packageId: installmentPackage.packageId || '',
                  packageName: installmentPackage.packageName || '',
                  type:
                    installmentPackage.type === PackageType.Revolving
                      ? PackageType.Bnpl
                      : installmentPackage.type,
                  originAmount: order.order.originalAmount || 0,
                  totalDiscountAmount:
                    installmentPackage.totalDiscountAmount || 0,
                  firstInstallmentPercent:
                    installmentPackage.firstInstallmentPercent || 0.0,
                  firstInstallmentAmount:
                    installmentPackage.firstInstallmentAmount || 0,
                  subSeqInstallmentAmount:
                    installmentPackage.subSeqInstallmentAmount || 0,
                  remainingAmount: installmentPackage.remainingAmount || 0,
                  tenure: installmentPackage.tenure || 0,
                  serviceFee: installmentPackage.serviceFee || 0,
                  totalAmount: installmentPackage.totalAmount || 0,
                  priority: installmentPackage.priority || 0,
                  active: installmentPackage.active || false,
                }))
              : initialCheckoutDetails.installmentPlans.packages,
          currency,
        },
        promotions:
          order.promotions.length > 0
            ? order.promotions.map((promotion: any) => ({
                title: promotion.couponName || '',
                code: promotion.couponCode || '',
                description: promotion.description || '',
                amount: promotion.discountAmount || 0,
                isDisplay: promotion.isPublish || false,
              }))
            : initialCheckoutDetails.promotions,
        appliedPromotions: getAppliedCoupons(),
        receiptDetails: {
          orderValue: order.order.originalAmount || 0,
          downPaymentValue: getReceiptDetails().downPaymentValue,
          payLaterValue: getReceiptDetails().payLaterValue,
          serviceFee: getReceiptDetails().serviceFee,
          tempValue: getReceiptDetails().tempValue,
        },
        currency,
        ekycStatus: order.user.ekycStatus || EkycStatus.Pending,
        highPriorityPackageNumber: getHighestPriority(),
        userId: order.user.id || '',
      };
    },
    enabled: false,
  });

  useEffect(() => {
    refetchOrder();
  }, [appliedCoupon, appliedPercent]);

  return {
    checkoutDetails: data || initialCheckoutDetails,
    isOrderFetching,
    refetchOrder,
    appliedCoupon,
    appliedPackage,
    appliedPercent,
    exportingReceipt,
    setAppliedCoupon,
    setAppliedPackage,
    setAppliedPercent,
    setExportingReceipt,
  };
};

export default useCheckoutDetails;
