import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { ServiceFeeDrawerProps } from 'src/components/Order/CheckoutDetails/ReceiptDetails/ReceiptDetails.type';

const ServiceFeeDescription = ({ onDrawerClosed }: ServiceFeeDrawerProps) => {
  const { t } = useTranslation();

  return (
    <section className="service-fee">
      <div className="paragraph paragraph--sm service-fee-content">
        <p>{t('order:checkout.serviceFeeDrawer.description')}</p>

        <div className="service-fee-content__item">
          <div className="service-fee-content__item--noted" />
          <p>{t('order:checkout.serviceFeeDrawer.firstParagraph')}</p>
        </div>

        <div className="service-fee-content__item">
          <div className="service-fee-content__item--noted" />
          <p>{t('order:checkout.serviceFeeDrawer.secondParagraph')}</p>
        </div>

        <div className="service-fee-content__item">
          <div className="service-fee-content__item--noted" />
          <p>{t('order:checkout.serviceFeeDrawer.thirdParagraph')}</p>
        </div>
      </div>

      <Button
        type={ButtonType.Default}
        colorType={ButtonColorType.PrimaryGradient}
        title={t('order:checkout.serviceFeeDrawer.btnLabel')}
        className="service-fee-content__btn"
        size={ButtonSize.Large}
        onClickHandled={onDrawerClosed}
      />
    </section>
  );
};

export default ServiceFeeDescription;
