import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'src/assets/images/svg/x-mark.svg';
import Button from 'src/components/Button';
import { Input } from 'src/components/Input';
import { useCreateBilling, useUpdateBilling } from 'src/hooks/billing';
import { routeStrings } from 'src/routes';
import { validateEmail, validateRequired } from 'src/utils';

import { ButtonColorType, ButtonType } from 'src/components/Button/Button.type';
import { BillingFormProps } from 'src/components/Order/CheckoutDetails/BillingInfo/BillingInfo.type';
import { SourceType } from 'src/types';

const BillingForm = ({
  userId,
  billingDetails,
  onBillingDrawerClosed,
  refetchBillings,
}: BillingFormProps) => {
  const { t } = useTranslation();
  const { sessionId = '' } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { createBilling } = useCreateBilling();
  const { updateBilling } = useUpdateBilling();

  const onClosed = () => {
    refetchBillings();
    onBillingDrawerClosed();
  };

  const onTokenExpired = () => {
    const paymentChannel = searchParams.get('paymentChannel') || 'web';
    const scanQr = searchParams.get('scanQr') || false;

    navigate(
      `${routeStrings.auth.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&paymentChannel=${paymentChannel}&scanQr=${scanQr}`,
      {
        state: {
          callbackUrl: window.location.pathname,
        },
        replace: true,
      }
    );
  };

  const onSubmit = (values: any) => {
    if (billingDetails?.id) {
      updateBilling(
        {
          id: billingDetails.id,
          userId,
          billingInfo: values,
        },
        {
          onSuccess: () => onClosed(),
          onError: (error: any) => {
            if (error?.response && error?.response?.status === 401) {
              onTokenExpired();
              throw error;
            }

            onClosed();
            throw error;
          },
        }
      );
    } else {
      createBilling(
        {
          userId,
          billingInfo: values,
        },
        {
          onSuccess: () => onClosed(),
          onError: (error: any) => {
            if (error?.response && error?.response?.status === 401) {
              onTokenExpired();
              throw error;
            }

            onClosed();
            throw error;
          },
        }
      );
    }
  };

  return (
    <Formik
      initialValues={{
        name: billingDetails?.name || '',
        email: billingDetails?.email || '',
        exportingAddress: billingDetails?.exportingAddress || '',
        taxNumber: billingDetails?.taxNumber || '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ initialValues, values, setFieldValue }) => {
        return (
          <Form className="billing-form">
            <div className="billing-form__input">
              <Input
                type="text"
                name="name"
                label={t('order:checkout.billing.fullName')}
                value={values.name}
                onValueChanged={(event: any) =>
                  setFieldValue('name', event.target.value)
                }
                required
                validate={(value: string) =>
                  validateRequired(
                    t,
                    value,
                    t('order:checkout.billing.fullName').toLowerCase()
                  )
                }
                placeholder={t('order:checkout.billing.fullNamePlaceholder')}
              />
              <Input
                type="text"
                name="email"
                label={t('order:checkout.billing.email')}
                value={values.email}
                onValueChanged={(event: any) =>
                  setFieldValue('email', event.target.value)
                }
                required
                validate={(value: string) =>
                  validateEmail(
                    t,
                    value,
                    t('order:checkout.billing.email').toLowerCase()
                  )
                }
                placeholder={t('order:checkout.billing.emailPlaceholder')}
              />
              <Input
                type="text"
                name="exportingAddress"
                label={t('order:checkout.billing.exportingAddress')}
                value={values.exportingAddress}
                onValueChanged={(event: any) =>
                  setFieldValue('exportingAddress', event.target.value)
                }
                required
                validate={(value: string) =>
                  validateRequired(
                    t,
                    value,
                    t('order:checkout.billing.exportingAddress').toLowerCase()
                  )
                }
                placeholder={t(
                  'order:checkout.billing.exportingAddressPlaceholder'
                )}
              />
              <Input
                type="text"
                name="taxNumber"
                label={t('order:checkout.billing.taxNumber')}
                value={values.taxNumber}
                onValueChanged={(event: any) =>
                  setFieldValue('taxNumber', event.target.value)
                }
                placeholder={t('order:checkout.billing.taxNumberPlaceholder')}
                maxLength={10}
              />
            </div>

            <div className="billing-form__icon" onClick={onBillingDrawerClosed}>
              <CloseIcon />
            </div>
            <Button
              type={ButtonType.Text}
              colorType={ButtonColorType.Purple}
              title={t('save')}
              className="billing-form__save"
              disable={isEqual(initialValues, values)}
              isSubmit
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default BillingForm;
