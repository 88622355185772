import { Button as AntdButton, ConfigProvider } from 'antd';
import classNames from 'classnames';

import {
  COLOR_DUST_RED_400,
  COLOR_DUST_RED_600,
  COLOR_GRAY_100,
  COLOR_GRAY_500,
  COLOR_GRAY_900,
  COLOR_PRIMARY_500,
  COLOR_PRIMARY_600,
  COLOR_PRIMARY_GRADIENT,
} from 'src/constants';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
  ButtonProps,
} from 'src/components/Button/Button.type';

const Button = ({
  type,
  colorType,
  title,
  onClickHandled,
  className = '',
  disable = false,
  icon = undefined,
  subIcon = undefined,
  size = ButtonSize.Middle,
  isSubmit = false,
  id = '',
}: ButtonProps) => {
  const getColorText = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
        return COLOR_GRAY_900;
      case ButtonColorType.Purple:
        return COLOR_PRIMARY_600;
      case ButtonColorType.Red:
        return COLOR_DUST_RED_600;
      default:
        return COLOR_GRAY_100;
    }
  };

  const getColorBg = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
      case ButtonColorType.Purple:
      case ButtonColorType.Red:
        return COLOR_GRAY_100;
      case ButtonColorType.PrimaryGradient:
        return COLOR_PRIMARY_GRADIENT;
      default:
        return COLOR_PRIMARY_600;
    }
  };

  const getColorBorder = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
        return COLOR_GRAY_500;
      case ButtonColorType.Purple:
        return COLOR_PRIMARY_600;
      case ButtonColorType.Red:
        return COLOR_DUST_RED_600;
      default:
        return COLOR_GRAY_100;
    }
  };

  const getColorHover = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
        return COLOR_PRIMARY_600;
      case ButtonColorType.Purple:
        return COLOR_PRIMARY_500;
      case ButtonColorType.Red:
        return COLOR_DUST_RED_400;
      case ButtonColorType.PrimaryGradient:
        return COLOR_PRIMARY_GRADIENT;
      default:
        return '';
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBorder: !disable ? getColorBorder() : COLOR_GRAY_500,
            colorBgContainer: getColorBg(),
            colorText: getColorText(),
            colorPrimaryHover: getColorHover(),
          },
        },
      }}
    >
      <AntdButton
        size={size}
        htmlType={isSubmit ? 'submit' : 'button'}
        type={type}
        className={classNames(
          title ? 'btn' : 'btn-icon',
          'label label--sm',
          {
            [className]: !!className,
          },
          !disable &&
            type === ButtonType.Default && {
              'btn-gray': colorType === ButtonColorType.Gray,
              'btn-purple': colorType === ButtonColorType.Purple,
              'btn-red': colorType === ButtonColorType.Red,
              'btn-primary-gradient':
                colorType === ButtonColorType.PrimaryGradient,
            }
        )}
        disabled={disable}
        icon={icon && icon}
        id={id}
        onClick={!isSubmit && onClickHandled ? onClickHandled : undefined}
      >
        {title && (
          <span
            className={classNames({
              'label label--xs': size === ButtonSize.Small,
              'label label--sm': size === ButtonSize.Middle,
              'label label--md': size === ButtonSize.Large,
              'mr--12': subIcon,
            })}
          >
            {title}
          </span>
        )}
        {subIcon && subIcon}
      </AntdButton>
    </ConfigProvider>
  );
};

export default Button;
