import { Platform, SourceModule } from 'src/types';

export enum EkycPreviousStep {
  Order = 'ORDER',
  Register = 'REGISTER',
}

export enum EkycResultError {
  GetAccessTokenFail = 'GET_ACCESS_TOKEN_FAIL',
  ManualReject = 'EKYC_ALREADY_REJECTED',
  MaximumViolate = 'EKYC_ALREADY_MAXIMUM',
  NotExisted = 'EKYC_NOT_EXISTED',
  AlreadyApproved = 'EKYC_ALREADY_APPROVED',
}

export enum EkycResultStatus {
  Pending = 'PENDING',
  AutoApproved = 'AUTO_APPROVED',
  ManualReject = 'MANUAL_REJECT',
}

export enum EkycProcessingStatus {
  Success = 'success',
  Pending = 'pending',
  Reject = 'reject',
}

export interface CreateEkycRequest {
  callbackUrl: string;
  redirectUrl: string;
  fallbackUrl: string;
  referenceId: string;
  sourceType: SourceModule;
  previousStep: SourceModule;
  paymentChannel: string;
  scanQr: string | boolean;
  referralId?: string;
}

export interface InitTrueID {
  accessToken: string;
  clientId: string;
  ekycId: string;
  baseApi: string;
  apiPath: string;
  callbackUrl: string;
  requestId: string;
  platform: Platform;
  clientVer: string;
  referenceId: string;
  sourceType: SourceModule;
  startTime: string;
  paymentChannel: string;
  scanQr: string | boolean;
}
