import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

const useConfirmSharingData = () => {
  const { mutate: confirmSharingData } = useMutation({
    mutationFn: async (variables: { authRequestId: string }) => {
      const response = await axios.post(apiStrings.auth.confirmSharing, {
        ...generateDefaultAuthValues(variables.authRequestId || ''),
        isSharingData: true,
      });
      return response.data;
    },
  });

  return { confirmSharingData };
};

export default useConfirmSharingData;
