import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as TrafficCone } from 'src/assets/images/svg/traffic-cone.svg';
import { SecondaryLayout } from 'src/components/Layout';
import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { CheckoutResponseStatus } from 'src/types';

const ExceedLimit = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const onBack = () => {
    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const onProceedToPay = () => {
    return (window.location.href = process.env.REACT_APP_DYNAMIC_LINK || ''); // TODO: BaoNguyen need to refactor cause dynamic link will be deprecated
  };

  return (
    <SecondaryLayout
      logo={<Logo />}
      image={<TrafficCone />}
      title={t('order:error.exceedLimitTitle')}
      description={state?.resultMsg || ''}
      footer={
        <>
          {state?.resultCode ===
            CheckoutResponseStatus.UserExceedLimitation && (
            <Button
              title={t('order:error.pay')}
              type={ButtonType.Default}
              colorType={ButtonColorType.PrimaryGradient}
              size={ButtonSize.Large}
              onClickHandled={onProceedToPay}
            />
          )}
          <Button
            title={t('close')}
            type={ButtonType.Default}
            colorType={ButtonColorType.Gray}
            size={ButtonSize.Large}
            onClickHandled={onBack}
          />
        </>
      }
    />
  );
};

export default ExceedLimit;
