export enum RefundResponseStatus {
  UserIdNotMatch = 'USER_ID_NOT_MATCH',
  RefundRequestNotFound = 'REFUND_REQUEST_NOT_FOUND',
  UserNameNotMatch = 'USER_NAME_NOT_MATCH',
}

export enum RefundStep {
  Refund = 'REFUND',
  BankAccount = 'BANK_ACCOUNT',
}

export type AccountRefundType = {
  account: {
    name: string;
    number: string;
  };
  bank: {
    name: string;
    id: string;
    logo: string;
  };
  isDefault: boolean;
};

export type BankAccountManyQuery = {
  bankAccounts: Array<{
    accountNumber: string;
    accountName: string;
    bankId: string;
    bankName: string;
    bankCode: string;
    logo: string;
    bankShortName: string;
    default: boolean;
  }>;
};

export type BankInformationManyQuery = Array<{
  id: string;
  bankName: string;
  shortName: string;
  code: string;
  swiftCode: string;
  logo: string;
}>;

export type RefundOneQuery = {
  orderId: string;
  totalAmountPaid: number;
  totalServiceFeeDecrease: number;
  refundFee: number;
  totalNonRefundAbleAmount: number;
  actualRefundAmount: number;
};

export type BankAccountVerifyQueryVariables = {
  beneficiaryNumber: string;
  bin: string;
  accountType?: string;
};

export type BankAccountVerifyQuery = {
  valid?: boolean;
  beneficiaryName?: string;
  beneficiaryNumber?: string;
  resultCode?: string;
  resultMsg?: string;
};

export type SubmitRefundVariables = {
  bankId: string;
  accountNumber: string;
  refundRequestId: string;
  isDefault?: boolean;
};

export type SubmitRefundResponse = {
  resultCode: string;
  resultMsg: string;
};
