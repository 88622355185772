import { fallbackImage } from 'src/constants/images';

interface BankProps {
  logo: string;
  name: string;
}

const Bank = ({ logo, name }: BankProps) => {
  return (
    <div className="bank-info">
      <div className="bank-img">
        <img src={logo || fallbackImage} alt="bank logo" />
      </div>
      <div className="bank-detail">
        <p className="bank-name">{name}</p>
      </div>
    </div>
  );
};

export default Bank;
