import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Policy = () => {
  const { t } = useTranslation();

  return (
    <section className="policy">
      <p className="paragraph paragraph--xs">
        {`${t('order:checkout.policy.firstContent')} `}
        <Link
          to={process.env.REACT_APP_TNC || ''}
          className="color--primary"
          target="_blank"
        >
          {t('order:checkout.policy.buyingPolicy')}
        </Link>
        {` ${t('order:checkout.policy.secondContent')}`}
      </p>
    </section>
  );
};

export default Policy;
