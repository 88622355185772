import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import { CheckoutStep, PromotionStatus } from 'src/components/Order';

import { ButtonColorType, ButtonType } from 'src/components/Button/Button.type';
import { PromotionProps } from 'src/components/Order/CheckoutDetails/OrderDetails/OrderDetails.type';

const Promotion = ({
  packages,
  appliedPromotions,
  onStepChanged,
}: PromotionProps) => {
  const { t } = useTranslation();

  return (
    <section className="applied-promotion">
      <div className="applied-promotion-list">
        <p className="label label--sm">
          {t('order:checkout.promotion.coupon')}
        </p>

        <div className="applied-promotion-list__item">
          {appliedPromotions.map(appliedPromotion => {
            if (appliedPromotion.status === PromotionStatus.Available) {
              return (
                <p
                  key={appliedPromotion.code}
                  className="paragraph paragraph--xs"
                >
                  {appliedPromotion.code}
                </p>
              );
            }

            return (
              <p
                key={appliedPromotion.code}
                className="paragraph paragraph--xs applied-promotion-list__item--error"
              >
                {appliedPromotion.status === PromotionStatus.Unavailable &&
                  t('order:checkout.promotion.unavailableCoupon')}
                {appliedPromotion.status === PromotionStatus.Expired &&
                  t('order:checkout.promotion.expiredCoupon')}
                {appliedPromotion.status === PromotionStatus.TypeNotSupport &&
                  t('order:checkout.promotion.notSupportCoupon')}
              </p>
            );
          })}
        </div>
      </div>

      <Button
        type={ButtonType.Text}
        colorType={ButtonColorType.Purple}
        title={t('choose')}
        onClickHandled={() => onStepChanged(CheckoutStep.Promotion)}
        disable={packages.length === 0}
      />
    </section>
  );
};

export default Promotion;
