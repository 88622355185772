import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numberFormatter } from 'src/utils';

import { useParams } from 'react-router-dom';
import { useRefundDetail } from 'src/features/Refund';

const RefundDetail = () => {
  const { t } = useTranslation();
  const { refundId } = useParams();
  const [isCollapse, setIsCollapse] = useState(true);

  const { refundDetail } = useRefundDetail({ refundRequestId: refundId });

  const toggleCollapse = () => {
    setIsCollapse(prevValue => !prevValue);
  };

  return (
    <>
      <Flex
        vertical
        style={{ backgroundColor: '#fff', padding: '24px 20px 0px 20px' }}
      >
        <h1 className="heading heading--xs body__title--primary">
          {t('refund:titles.refundInfo')}
        </h1>
        <span className="label label--xs my--16 subheading">
          {t('refund:titles.refundDetail')}
        </span>
        <div className="refund-detail__item mb--16" onClick={toggleCollapse}>
          <span className="paragraph paragraph--sm">
            {`${t('refund:labels.order')} ${refundDetail?.orderId}`}
          </span>
          <Flex gap={10}>
            <span className="paragraph paragraph--sm">
              {`${numberFormatter(refundDetail?.totalAmountPaid)} `}
              <span className="paragraph paragraph--xs mr--10">
                {t(`currency.vnd`)}
              </span>
            </span>
            {isCollapse ? <DownOutlined /> : <UpOutlined />}
          </Flex>
        </div>
      </Flex>
      {!isCollapse && (
        <div className="refund-detail">
          <div className="refund-detail__item">
            <p className="paragraph paragraph--sm refund-detail__label">
              {t('refund:labels.totalAmountPaid')}
            </p>
            <p className="paragraph paragraph--sm refund-detail__value">
              {`${numberFormatter(refundDetail?.totalAmountPaid)} `}
              <span className="paragraph paragraph--xs">
                {t(`currency.vnd`)}
              </span>
            </p>
          </div>
          <div className="refund-detail__item">
            <p className="paragraph paragraph--sm refund-detail__label">
              {t('refund:labels.totalNonRefundAbleAmount')}
            </p>
            <p className="paragraph paragraph--sm refund-detail__value">
              {`${numberFormatter(refundDetail?.totalNonRefundAbleAmount)} `}
              <span className="paragraph paragraph--xs">
                {t(`currency.vnd`)}
              </span>
            </p>
          </div>
          <div className="refund-detail-fee">
            <div className="refund-detail-fee__item">
              <p className="paragraph paragraph--sm refund-detail__label">
                {t('refund:labels.totalServiceFeeDecrease')}
              </p>
              <p className="paragraph paragraph--sm refund-detail__value">
                {`${numberFormatter(refundDetail?.totalServiceFeeDecrease)} `}
                <span className="paragraph paragraph--xs">
                  {t(`currency.vnd`)}
                </span>
              </p>
            </div>
            <div className="refund-detail-fee__item">
              <p className="paragraph paragraph--sm refund-detail__label">
                {t('refund:labels.refundFee')}
              </p>
              <p className="paragraph paragraph--sm refund-detail__value">
                {`${numberFormatter(refundDetail?.refundFee)} `}
                <span className="paragraph paragraph--xs">
                  {t(`currency.vnd`)}
                </span>
              </p>
            </div>
          </div>
          <div className="refund-detail__item">
            <p className="paragraph paragraph--sm refund-detail__label">
              {t('refund:labels.actualRefundAmount')}
            </p>
            <p className="paragraph paragraph--sm refund-detail__value">
              {`${numberFormatter(refundDetail?.actualRefundAmount)} `}
              <span className="paragraph paragraph--xs">
                {t(`currency.vnd`)}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default RefundDetail;
