import { StepProps } from 'antd';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from 'src/components/Button';
import { PrimaryLayout } from 'src/components/Layout';
import Steps from 'src/components/Steps';
import { useIdleDetection } from 'src/hooks/common';
import { routeStrings } from 'src/routes';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { StepsDirection, StepSize } from 'src/components/Steps/Steps.type';

const Starting = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { referenceId } = useParams();
  const { isIdle } = useIdleDetection();

  useEffect(() => {
    if (!isIdle) {
      return;
    }

    navigate(
      `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.identityPromotion}`,
      {
        state,
      }
    );
  }, [isIdle]);

  const onStartingEkyc = () => {
    navigate(
      `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.trueID}`,
      {
        state,
      }
    );
  };

  const onBack = () => {
    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const steps: StepProps[] = useMemo(() => {
    return [
      {
        title: (
          <span className="label label--sm starting__progress--text">
            {t('ekyc:starting.takeIdentityPhoto')}
          </span>
        ),
        icon: <div className="starting__progress--active" />,
      },
      {
        title: (
          <span className="label label--sm starting__progress--text">
            {t('ekyc:starting.takeSelfie')}
          </span>
        ),
        icon: <div className="starting__progress--inactive" />,
      },
    ];
  }, []);

  return (
    <PrimaryLayout
      className="starting"
      title={t('ekyc:starting.identityPromotion')}
      subTitle={t('ekyc:starting.identityProgress')}
      onBack={onBack}
      footer={
        <Button
          type={ButtonType.Default}
          colorType={ButtonColorType.PrimaryGradient}
          title={t('ekyc:starting.startIdentity')}
          size={ButtonSize.Large}
          onClickHandled={onStartingEkyc}
        />
      }
    >
      <Steps
        steps={steps}
        size={StepSize.Small}
        direction={StepsDirection.Vertical}
      />
    </PrimaryLayout>
  );
};

export default Starting;
