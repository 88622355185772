import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { includes } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { STORAGE_KEY } from 'src/constants';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import {
  CheckoutResponseStatus,
  ResponseStatus,
  SourceType,
  UserBusinessError,
} from 'src/types';

const useSubmitOrder = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { mutate: submitOrder, isLoading } = useMutation({
    mutationFn: async (variable: any) => {
      try {
        const response = await axios.post(apiStrings.order.submitOrder, {
          ...generateDefaultAuthValues(),
          sessionId: variable.sessionId,
          orderId: variable.orderId,
          packageId: variable.packageId,
          promotionIds: variable.promotionIds,
          billingInfoId: variable.billingInfoId,
          latitude: variable.latitude,
          longitude: variable.longitude,
        });

        return response.data || '';
      } catch (error) {
        throw error;
      }
    },
    onSuccess: (response, variables) => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === UserBusinessError.UserNotMatch) {
          return navigate(`${routeStrings.error}`, {
            state: {
              resultCode: response.data.resultCode,
              resultMsg: response.data.resultMsg,
            },
          });
        }

        if (
          includes(
            [
              CheckoutResponseStatus.OrderNotFound,
              CheckoutResponseStatus.OrderExpired,
              UserBusinessError.UserLocked,
            ],
            response.data.resultCode
          )
        ) {
          return navigate(
            `${routeStrings.checkout.defaultPath}/${variables.sessionId}/${routeStrings.checkout.error}`,
            {
              state: {
                resultCode: response.data.resultCode,
                resultMsg: response.data.resultMsg,
              },
            }
          );
        }

        if (
          response.data.resultCode === CheckoutResponseStatus.UserRequireGps
        ) {
          return setErrorMessage(response.data.resultCode);
        }

        return setErrorMessage(response.data.resultMsg);
      }

      sessionStorage.removeItem(STORAGE_KEY.callbackUrl);
      sessionStorage.removeItem(STORAGE_KEY.checkoutSession);

      if (response.data.nextStep === 'ORDER_STATUS') {
        return navigate(
          `${routeStrings.orderStatus}/${response.data.orderId || ''}`
        );
      }

      navigate(
        `${routeStrings.payment.defaultPath}/${response.data.paymentTransactionId}`
      );
    },
    onError: (error: any, variable) => {
      if (error?.response && error?.response?.status === 401) {
        return navigate(
          `${routeStrings.auth.defaultPath}/${variable.sessionId}?sourceType=${SourceType.Order.toLowerCase()}`,
          {
            state: {
              callbackUrl: window.location.pathname,
            },
            replace: true,
          }
        );
      }

      navigate(routeStrings.error);
    },
  });

  return {
    errorMessage,
    isOrderSubmitting: isLoading,
    submitOrder,
    setErrorMessage,
  };
};

export default useSubmitOrder;
