import { ValidatedErrorProps } from 'src/components/ValidatedError/ValidatedError.type';

const ValidatedError = ({ touched, error }: ValidatedErrorProps) => {
  return (
    <div className="d-flex flex-nowrap mt--4">
      {touched && error && (
        <span className="paragraph paragraph--xs color--error">{error}</span>
      )}
    </div>
  );
};

export default ValidatedError;
