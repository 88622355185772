import { PropsWithChildren, ReactNode } from 'react';

export enum Placement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface DrawerProps extends PropsWithChildren {
  open: boolean;
  title: ReactNode;
  onClose?: () => void;
  placement?: Placement;
  width?: number | string;
  height?: number | string;
  headerClassName?: string;
  bodyClassName?: string;
}
