import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  BankAccountVerifyQuery,
  BankAccountVerifyQueryVariables,
} from 'src/features/Refund';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { SourceType } from 'src/types';

export type UseRefundAccountProps = {
  refundRequestId?: string;
};

const useRefundAccount = ({ refundRequestId }: UseRefundAccountProps) => {
  const navigate = useNavigate();

  const { mutate: detectAccount, isLoading } = useMutation<
    BankAccountVerifyQuery,
    unknown,
    BankAccountVerifyQueryVariables
  >({
    mutationFn: async (variable: BankAccountVerifyQueryVariables) => {
      try {
        const response = await axios.post(
          apiStrings.refund.detectAccount,
          variable,
          {
            params: { ignoreLoading: true },
          }
        );

        return response.data?.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${refundRequestId}?sourceType=${SourceType.Refund.toLowerCase()}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        navigate(routeStrings.error);
        throw error;
      }
    },
  });

  return {
    detectAccount,
    isLoading,
  };
};

export default useRefundAccount;
