import { QRCode as AntdQR } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as VietQR } from 'src/assets/images/svg/viet-qr.svg';
import { ReactComponent as Download } from 'src/assets/images/svg/arrow-down-to-bracket.svg';
import { ReactComponent as Clock } from 'src/assets/images/svg/clock.svg';
import CopyBoard from 'src/components/CopyBoard';
import { PrimaryLayout } from 'src/components/Layout';
import { PaymentStatus } from 'src/components/Order';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { withIcon } from 'src/hocs';
import { useQueryStatus } from 'src/hooks/payment';
import { routeStrings } from 'src/routes';
import {
  actionTracking,
  convertToTime,
  ModuleTracking,
  numberFormatter,
} from 'src/utils';

import { TooltipPlacement } from 'src/components/CopyBoard/CopyBoard.type';

const ClockIcon = withIcon(Clock, {
  width: 16,
  height: 16,
});

const DownloadIcon = withIcon(Download, {
  width: 16,
  height: 16,
});

const VirtualAccount = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { paymentTransactionId } = useParams();
  const { paymentStatusDetails, refetchPaymentStatus } = useQueryStatus();
  const [remainingTime, setRemainingTime] = useState(
    state?.expiredIn - Date.now()
  );

  useEffect(() => {
    if (paymentStatusDetails?.paymentStatus === PaymentStatus.Success) {
      return navigate(
        `${routeStrings.orderStatus}/${paymentStatusDetails?.orderId}`,
        {
          state: {
            paymentTransactionId,
          },
        }
      );
    }

    const intervalId = setInterval(() => {
      refetchPaymentStatus();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [paymentStatusDetails?.paymentStatus]);

  useEffect(() => {
    if (remainingTime < 1000) {
      navigate(`${routeStrings.orderStatus}/${paymentStatusDetails?.orderId}`, {
        state: {
          paymentTransactionId,
        },
      });
    }
  }, [remainingTime]);

  useEffect(() => {
    const timer = setInterval(
      () =>
        setRemainingTime(prev => {
          if (prev <= 0) {
            return prev - 100;
          }
          return prev - 1000;
        }),
      1000
    );

    return () => {
      clearInterval(timer);
    };
  }, []);

  const downloadQRCode = () => {
    actionTracking(
      TRIGGER_EVENTS.downloadVietQR,
      ModuleTracking.Order,
      'order_payment',
      {
        [DATA_LAYER_VARIABLES.paymentTransactionId]: paymentTransactionId,
      }
    );

    const canvas = document
      .getElementById('myqrcode')
      ?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <PrimaryLayout
      title={t('payment:method.virtualAccount')}
      isMasterTitle
      onBack={() => navigate(-1)}
    >
      <div className="va">
        <p className="paragraph paragraph--sm">
          {t('payment:vietQRDescription')}
        </p>

        <section className="va-qr">
          <div className="va-qr-image" id="myqrcode">
            <VietQR />

            <AntdQR value={state?.virtualAccount.qrData || ''} />

            <p className="va-qr-image__countdown color--error">
              <ClockIcon width={16} height={16} />
              <span className="paragraph paragraph--sm">
                {convertToTime(remainingTime)}
              </span>
            </p>

            <div className="va-qr-image__action" onClick={downloadQRCode}>
              <DownloadIcon />
              <p className="label label--sm">{t('payment:download')}</p>
            </div>
          </div>

          <div className="va-qr-content">
            <div className="va-qr-content-item">
              <p className="paragraph paragraph--sm va-qr-content-item__label va-qr-content-item__label--secondary">
                {t('payment:bankName')}
              </p>

              <div className="va-qr-content-item__value va-qr-content-item__value--secondary">
                <p className="label label--sm">
                  {state?.virtualAccount.bankCode || ''}
                </p>
                <p className="paragraph paragraph--sm text-align-right">
                  {state?.virtualAccount.bankName || ''}
                </p>
              </div>
            </div>

            <div className="va-qr-content-item">
              <p className="paragraph paragraph--sm va-qr-content-item__label">
                {t('payment:transferAmount')}
              </p>

              <div className="label label--sm va-qr-content-item__value">
                <span>{`${numberFormatter(state?.amount || 0)} ${t('currency.vnd')}`}</span>
                <CopyBoard
                  placement={TooltipPlacement.Top}
                  copiedValue={(state?.amount || 0).toString()}
                />
              </div>
            </div>

            <div className="va-qr-content-item">
              <p className="paragraph paragraph--sm va-qr-content-item__label">
                {t('payment:bankAccount')}
              </p>

              <div className="paragraph paragraph--sm va-qr-content-item__value">
                <span>{state?.virtualAccount.accountNumber || ''}</span>
                <CopyBoard
                  placement={TooltipPlacement.Top}
                  copiedValue={(
                    state?.virtualAccount.accountNumber || ''
                  ).toString()}
                />
              </div>
            </div>

            <div className="va-qr-content-item">
              <p className="paragraph paragraph--sm va-qr-content-item__label">
                {t('payment:beneficiary')}
              </p>

              <div className="paragraph paragraph--sm va-qr-content-item__value">
                <span>{state?.virtualAccount.beneficiaryName || ''}</span>
                <CopyBoard
                  placement={TooltipPlacement.Top}
                  copiedValue={state?.virtualAccount.beneficiaryName || ''}
                />
              </div>
            </div>
          </div>
        </section>

        <div className="va-note">
          <p className="label label--sm">{t('payment:noteTitle')}</p>
          <p className="paragraph paragraph--xs va-note__content">
            {t('payment:noteContent')}
          </p>
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default VirtualAccount;
