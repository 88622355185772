export enum ResponseStatus {
  Success = 'SUCCESS',
  BadRequest = 'BAD_REQUEST',
  Failed = 'FAIL',
  Unauthorized = 'UNAUTHORIZED',
  TimeOut = 'TIME_OUT',
  Exist = 'EXIST',
  SystemError = 'SYSTEM_ERROR',
  NotFound = 'NOT_FOUND',
  BusinessError = 'BUSINESS_ERROR',
  AuthenRequestExpired = 'AUTHENTICATION_REQUEST_EXPIRED',
  RequestExpired = 'REQUEST_EXPIRED',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
}

export enum Platform {
  Web = 'WEB',
  App = 'APP',
}

export enum SourceType {
  Register = 'REGISTER',
  Ekyc = 'EKYC',
  Order = 'ORDER',
  StaticQr = 'STATICQR',
  Refund = 'REFUND',
}

export type SourceModule =
  | SourceType.Register
  | SourceType.Ekyc
  | SourceType.Order
  | SourceType.StaticQr
  | '';

export interface BaseRequest {
  deviceId: string;
  requestId: string;
  platform: Platform;
  clientVer: string;
  startTime: string;
  userAgent: string;
}

export enum UserBusinessError {
  UserLocked = 'USER_LOCKED',
  UserNotMatch = 'USER_NOT_MATCH',
  PhoneNumberOtpLock = 'PHONE_NUMBER_OTP_LOCK',
  IncorrectOtpCode = 'INCORRECT_OTP_CODE',
}
