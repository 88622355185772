import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import {
  AuthenStep,
  OtpAction,
  ResponseStatus,
  UserBusinessError,
  VerifyOtpRequest,
} from 'src/types';

const useOtp = () => {
  const navigate = useNavigate();
  const { referenceId } = useParams();
  const { state } = useLocation();
  const [otpId, setOtpId] = useState('');
  const [resendCountdown, setResendCountdown] = useState(60 * 1000);
  const [sentChannel, setSentChannel] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const paymentChannel = state?.paymentChannel
    ? `&paymentChannel=${state?.paymentChannel}`
    : '';
  const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';

  const { mutate: requestOtp } = useMutation({
    mutationFn: async () => {
      try {
        const response = await axios.post(apiStrings.auth.otp, {
          ...generateDefaultAuthValues(state?.authRequestId || ''),
          action: OtpAction.RequestOtp,
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: response => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === UserBusinessError.PhoneNumberOtpLock) {
          setErrorCode(response.data.resultCode);
        }
        return setErrorMsg(response.data.resultMsg);
      }
      setOtpId(response.data.otpId);
      setSentChannel(response.data.channel);
    },

    onError: () => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      });
    },
  });

  const { mutate: verifyOtp } = useMutation({
    mutationFn: async (variables: VerifyOtpRequest) => {
      try {
        const response = await axios.post(apiStrings.auth.otp, {
          ...generateDefaultAuthValues(state?.authRequestId || ''),
          otpId,
          action: OtpAction.VerifyOtp,
          otpCode: variables.otpCode,
        });

        return response.data || '';
      } catch (error) {
        throw error;
      }
    },

    onSuccess: response => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === ResponseStatus.AuthenRequestExpired) {
          return navigate(
            `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
            {
              state,
              replace: true,
            }
          );
        }

        if (response.data.resultCode === UserBusinessError.IncorrectOtpCode) {
          setSentChannel('');
        }

        return setErrorMsg(response.data.resultMsg);
      }

      let routePath = routeStrings.error;

      switch (response.data.nextStep) {
        case AuthenStep.Login:
          routePath = `${routeStrings.auth.defaultPath}/${referenceId}/${routeStrings.auth.login}`;
          break;
        case AuthenStep.ResetPin:
        case AuthenStep.Register:
          routePath = `${routeStrings.auth.defaultPath}/${referenceId}/${routeStrings.auth.register}`;
          break;
        default:
          routePath = routeStrings.error;
      }

      setErrorMsg('');
      navigate(routePath, {
        state: {
          ...state,
          isDecreeOpen: response.data.isSharingData,
          isResetPin: response.data.nextStep === AuthenStep.ResetPin,
        },
      });
    },

    onError: () => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      });
    },
  });

  const { mutate: resendOtp, isLoading: isResending } = useMutation({
    mutationFn: async () => {
      try {
        const response = await axios.post(apiStrings.auth.otp, {
          ...generateDefaultAuthValues(state?.authRequestId || ''),
          otpId,
          action: OtpAction.ResendOtp,
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: response => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === ResponseStatus.AuthenRequestExpired) {
          return navigate(
            `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
            {
              state,
              replace: true,
            }
          );
        }

        if (response.data.resultCode === UserBusinessError.PhoneNumberOtpLock) {
          setErrorCode(response.data.resultCode);
        }

        return setErrorMsg(response.data.resultMsg);
      }

      setOtpId(response.data.otpId);
      setSentChannel(response.data.channel);
      setResendCountdown(response.data.expiredTime);
      setErrorMsg('');
    },

    onError: () => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      });
    },
  });

  useEffect(() => {
    requestOtp();
  }, []);

  return {
    errorMsg,
    errorCode,
    sentChannel,
    resendCountdown,
    resendOtp,
    verifyOtp,
    isResending,
    setErrorMsg,
    setErrorCode,
  };
};

export default useOtp;
