import TagManager from 'react-gtm-module';

import { DATA_LAYER_VARIABLES } from 'src/constants';
import { extractInfoFromToken } from 'src/utils';

export enum ModuleTracking {
  Order = 'order',
  Auth = 'auth',
  Ekyc = 'ekyc',
}

export const actionTracking = (
  eventName: string,
  module: ModuleTracking,
  screen: string,
  attributes: { [name: string]: any }
) => {
  TagManager.dataLayer({
    dataLayer: {
      [DATA_LAYER_VARIABLES.event]: eventName,
      [DATA_LAYER_VARIABLES.userId]: extractInfoFromToken('userId'),
      [DATA_LAYER_VARIABLES.userSessionId]:
        extractInfoFromToken('userSessionId'),
      [DATA_LAYER_VARIABLES.module]: module,
      [DATA_LAYER_VARIABLES.screen]: screen,
      ...attributes,
    },
  });
};
