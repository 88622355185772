import { ReactNode } from 'react';
import { PaymentStep } from 'src/components/Payment';

export enum PaymentProvider {
  OnePay = 'ONEPAY',
}

export enum PaymentMethodEnum {
  VirtualAccount = 'VIRTUAL_ACCOUNT',
  Atm = 'ATM',
  EWallet = 'E_WALLET',
  AppBanking = 'APP_BANKING',
  CreditCard = 'CREDIT_CARD',
  Cod = 'COD',
  InStorePayment = 'IN_STORE_PAYMENT',
}

export interface Token {
  cardNumber: string;
  token: string;
  bankCode: string;
  bankLogoUrl: string;
  paymentFee: number;
  tokenType: string;
}

export interface PaymentMethod {
  method: PaymentMethodEnum;
  name: string;
  token: string;
  paymentFee: number;
  provider: PaymentProvider;
  paymentLogo: ReactNode;
}

export interface PaymentDetailsType {
  amount: number;
  currency: string;
  orderId: string;
  paymentTransactionId: string;
  paymentTokens: Token[];
  paymentMethods: PaymentMethod[];
}

export interface PaymentDetailsProps {
  paymentDetails: PaymentDetailsType;
  appliedTokenDetails: PaymentMethod | Token;
  setAppliedToken: (token: string) => void;
  setSelectedToken: (token: string) => void;
  onStepChanged: (step: PaymentStep) => void;
}
