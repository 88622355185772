import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as FailedImage } from 'src/assets/images/svg/failed.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';
import { SupportModule } from 'src/components/SupportModule';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const Failed = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onBack = () => {
    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const onOpenSupportModule = () => setOpen(true);

  const onCloseSupportModule = () => setOpen(false);

  return (
    <>
      <SecondaryLayout
        logo={<Logo />}
        image={<FailedImage />}
        title={t('ekyc:identityStatus.identityFailedTitle')}
        description={t('ekyc:identityStatus.identityFailedDescription')}
        footer={
          <>
            <Button
              title={t('sendSupportRequest')}
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              size={ButtonSize.Large}
              onClickHandled={onOpenSupportModule}
            />
            <Button
              title={t('close')}
              type={ButtonType.Text}
              colorType={ButtonColorType.Purple}
              size={ButtonSize.Large}
              onClickHandled={onBack}
            />
          </>
        }
      />
      <SupportModule open={open} onClose={onCloseSupportModule} />
    </>
  );
};

export default Failed;
