import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DecreeModule } from 'src/components/Auth';
import Button from 'src/components/Button';
import { OtpInput } from 'src/components/Input';
import { PrimaryLayout } from 'src/components/Layout';
import { AUTH_PIN_LENGTH } from 'src/constants';
import { useLogin, useConfirmSharingData, useResetPin } from 'src/hooks/auth';
import { hideConfirm, showConfirm } from 'src/redux/slices/uiSlice';
import { routeStrings } from 'src/routes';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const Login = () => {
  const { t } = useTranslation();
  const { referenceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(!state?.isDecreeOpen);
  const [otp, setOtp] = useState<string>('');

  const { login, loginError, isLogging, setLoginError } = useLogin();
  const { confirmSharingData } = useConfirmSharingData();
  const { resetPin, isPinResetting } = useResetPin();

  const paymentChannel = state?.paymentChannel
    ? `&paymentChannel=${state?.paymentChannel}`
    : '';
  const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';
  const callback = `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`;

  useEffect(() => {
    if (otp.length === 1) {
      setLoginError('');
    }
  }, [otp]);

  const onBack = () =>
    navigate(callback, {
      state: {
        callbackUrl: state?.callbackUrl || '',
      },
    });

  const onDecreeClose = () => {
    setIsOpen(false);
    dispatch(
      showConfirm({
        open: true,
        title: t('auth:decree.decreeExitTitle'),
        content: t('auth:decree.decreeExitDescription'),
        cancelLabel: t('exit'),
        cancelHandler: () => {
          dispatch(hideConfirm());
          navigate(callback);
        },
        actionLabel: t('stay'),
        actionHandler: () => {
          setIsOpen(true);
          dispatch(hideConfirm());
        },
      })
    );
  };

  const onDecreeSubmit = () => {
    confirmSharingData({
      authRequestId: state?.authRequestId || '',
    });
    setIsOpen(false);
  };

  const onPinReset = () =>
    resetPin({
      authRequestId: state?.authRequestId || '',
    });

  const onPhoneChanged = () => {
    navigate(callback, {
      state,
    });
  };

  const onPinSubmit = ({ otpCode }: { otpCode: string }) =>
    login({ pin: otpCode });

  return (
    <PrimaryLayout
      title={t('auth:pin.inputPin')}
      subTitle={
        <>
          {t('auth:pin.inputPinDescription')}
          <strong className="label label--sm">{` ${state?.phoneNumber || ''}`}</strong>
        </>
      }
      onBack={onBack}
    >
      <div className="confirm-pin">
        <OtpInput
          value={otp}
          syncOtp={setOtp}
          error={loginError}
          submit={onPinSubmit}
          isSecured
          length={AUTH_PIN_LENGTH}
        />
        <div className="confirm-pin__actions">
          <Button
            title={t('auth:pin.changePhoneNumber')}
            type={ButtonType.Text}
            colorType={ButtonColorType.Purple}
            size={ButtonSize.Middle}
            onClickHandled={onPhoneChanged}
            disable={isLogging || isPinResetting}
          />
          <Button
            title={t('auth:pin.forgotPin')}
            type={ButtonType.Text}
            colorType={ButtonColorType.Purple}
            size={ButtonSize.Middle}
            onClickHandled={onPinReset}
            disable={isLogging || isPinResetting}
          />
        </div>
      </div>
      <DecreeModule
        open={isOpen}
        onSubmit={onDecreeSubmit}
        onClose={onDecreeClose}
      />
    </PrimaryLayout>
  );
};

export default Login;
