import { createSlice } from '@reduxjs/toolkit';

import { ConfirmModalProps } from 'src/components/Modal/Modal.type';

interface IUiState {
  loading: boolean;
  confirm: ConfirmModalProps;
}

const initialState: IUiState = {
  loading: false,
  confirm: {
    open: false,
    title: '',
    content: '',
    closeIcon: false,
    titleClassName: '',
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showLoading: state => {
      state.loading = true;
    },
    hideLoading: state => {
      state.loading = false;
    },
    showConfirm: (state, action: { payload: ConfirmModalProps }) => {
      state.confirm = {
        ...action.payload,
      };
    },
    hideConfirm: state => {
      state.confirm = initialState.confirm;
    },
  },
});

export const { showLoading, hideLoading, showConfirm, hideConfirm } =
  uiSlice.actions;
export default uiSlice.reducer;
