import { Carousel } from 'antd';
import { lowerCase } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownloadBackground from 'src/assets/images/png/download-bg.png';
import StepDownload from 'src/assets/images/png/step-download.png';
import StepLogin from 'src/assets/images/png/step-login.png';
import StepPayment from 'src/assets/images/png/step-payment.png';
import { Step, StepKey } from 'src/features/DownloadApp';

const Steps = () => {
  const { t } = useTranslation(['downloadApp']);
  const [currentStep, setCurrentStep] = useState<string>(StepKey.Download);

  const steps: Step[] = useMemo(() => {
    return Object.values(StepKey).map(key => {
      let image;

      if (key === StepKey.Download) {
        image = StepDownload;
      } else if (key === StepKey.Login) {
        image = StepLogin;
      } else {
        image = StepPayment;
      }

      return {
        title: t(`steps.${key}.title`),
        description: t(`steps.${key}.description`),
        image: image,
      };
    });
  }, []);

  const onStepBeforeChange = (_: number, nextStep: number) => {
    const arrStep = Object.values(StepKey);

    if (arrStep.length < 1) {
      return;
    }

    setCurrentStep(arrStep[nextStep]);
  };

  return (
    <div className="step">
      <Carousel
        infinite={false}
        arrows
        dots={{
          className: 'step__dots',
        }}
        beforeChange={onStepBeforeChange}
      >
        {steps.map(step => (
          <div className="step-item" key={step.title}>
            <img
              src={DownloadBackground}
              alt="download background"
              className="step-item__bg"
            />
            <img
              src={step.image as any}
              alt={step.title}
              className="step-item__img"
            />
          </div>
        ))}
      </Carousel>

      <div className="step__description">
        <h5 className="label label--md">
          {t(`steps.${lowerCase(currentStep)}.title`)}
        </h5>
        <p className="paragraph paragraph--sm">
          {t(`steps.${lowerCase(currentStep)}.description`)}
        </p>
      </div>
    </div>
  );
};

export default Steps;
