import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as SentRequestImage } from 'src/assets/images/svg/sent-request.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const Success = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const onBack = () => {
    window.location.href = process.env.REACT_APP_FUNDIIN_HOMEPAGE || '';
  };

  return (
    <SecondaryLayout
      logo={<Logo />}
      image={<SentRequestImage />}
      title={t('refund:titles.refundSuccess')}
      description={
        state?.resultMsg
          ? state?.resultMsg
          : t('refund:description.refundSuccess')
      }
      footer={
        <Button
          title={t('backToHomePage')}
          type={ButtonType.Default}
          colorType={ButtonColorType.PrimaryGradient}
          size={ButtonSize.Large}
          onClickHandled={onBack}
        />
      }
    />
  );
};

export default Success;
