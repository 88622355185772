import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';
import { ResponseStatus, SourceType, UserBusinessError } from 'src/types';

const useRetryPayment = () => {
  const { orderId = '' } = useParams();
  const navigate = useNavigate();

  const { mutate: retryPayment } = useMutation({
    mutationFn: async () => {
      try {
        const response = await axios.post(apiStrings.order.retryPayment, {
          ...generateDefaultAuthValues(),
          orderId,
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: response => {
      const { data } = response;

      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === UserBusinessError.UserNotMatch) {
          return navigate(`${routeStrings.error}`, {
            state: {
              resultCode: response.data.resultCode,
              resultMsg: response.data.resultMsg,
            },
          });
        }

        if (response.data.resultCode === UserBusinessError.UserLocked) {
          return navigate(
            `${routeStrings.checkout.defaultPath}/${orderId}/${routeStrings.checkout.error}`,
            {
              state: response.data,
            }
          );
        }
      }

      navigate(
        `${routeStrings.payment.defaultPath}/${data?.paymentTransactionId || ''}`
      );
    },
    onError: (error: any) => {
      if (error?.response && error?.response?.status === 401) {
        return navigate(
          `${routeStrings.auth.defaultPath}/${orderId}?sourceType=${SourceType.Order.toLowerCase()}`,
          {
            state: {
              callbackUrl: window.location.pathname,
            },
            replace: true,
          }
        );
      }

      navigate(routeStrings.error);
    },
  });

  return {
    retryPayment,
  };
};

export default useRetryPayment;
