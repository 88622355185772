import { PropsWithChildren, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { getLocalStorage } from 'src/utils';
import { STORAGE_KEY } from 'src/constants';
import { routeStrings } from 'src/routes';

import { SourceType } from 'src/types';

interface ProtectedRouteProps extends PropsWithChildren {}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const {
    referenceId = '',
    sessionId = '',
    paymentTransactionId = '',
    orderId = '',
    qrId = '',
    refundId = '',
  } = useParams();
  const { search, state } = useLocation();
  const queryString = new URLSearchParams(search);

  const hasToken = useMemo(() => {
    return !!getLocalStorage(STORAGE_KEY.accessToken);
  }, []);

  if (!hasToken) {
    if (sessionId || paymentTransactionId || orderId) {
      const paymentChannel = `&paymentChannel=${queryString.get('paymentChannel') || 'web'}`;
      const scanQr = `&scanQr=${queryString.get('scanQr') || false}`;
      const refId = sessionId
        ? sessionId
        : paymentTransactionId
          ? paymentTransactionId
          : orderId;

      return (
        <Navigate
          to={`${routeStrings.auth.defaultPath}/${refId}?sourceType=${SourceType.Order.toLowerCase()}${paymentChannel}${scanQr}`}
          state={{
            callbackUrl: window.location.pathname,
          }}
          replace
        />
      );
    }

    if (refundId) {
      return (
        <Navigate
          to={`${routeStrings.auth.defaultPath}/${refundId}?sourceType=${SourceType.Refund.toLowerCase()}`}
          state={{
            callbackUrl: window.location.pathname,
          }}
          replace
        />
      );
    }

    if (qrId) {
      return (
        <Navigate
          to={`${routeStrings.auth.defaultPath}/${qrId}?sourceType=${SourceType.Order.toLowerCase()}`}
          state={{
            callbackUrl: window.location.pathname,
          }}
          replace
        />
      );
    }

    return (
      <Navigate
        to={`${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}`}
        state={{
          callbackUrl: state?.callbackUrl,
        }}
        replace
      />
    );
  }

  return children;
};

export default ProtectedRoute;
