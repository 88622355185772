import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { STORAGE_KEY } from 'src/constants';
import { apiStrings } from 'src/services';
import { routeStrings } from 'src/routes';
import { generateDefaultAuthValues, storeLocalStorage } from 'src/utils';

import {
  AuthenStep,
  EkycPreviousStep,
  ResponseStatus,
  SourceType,
} from 'src/types';

const useRegister = () => {
  const { referenceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const paymentChannel = state?.paymentChannel
    ? `&paymentChannel=${state?.paymentChannel}`
    : '';
  const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';

  const { mutate: register } = useMutation({
    mutationFn: async (variables: { pin: string }) => {
      try {
        const response = await axios.post(
          state?.isResetPin
            ? apiStrings.auth.submitPin
            : apiStrings.auth.register,
          {
            ...generateDefaultAuthValues(state?.authRequestId || ''),
            pin: variables.pin,
          }
        );

        return response.data || '';
      } catch (error) {
        throw error;
      }
    },

    onSuccess: response => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === ResponseStatus.RequestExpired) {
          return navigate(
            `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
            {
              state,
              replace: true,
            }
          );
        }
        return;
      }

      storeLocalStorage(
        STORAGE_KEY.accessToken,
        `${response.data.tokenType} ${response.data.accessToken}`
      );

      if (response.data.nextStep === AuthenStep.Ekyc) {
        return navigate(
          `${routeStrings.ekyc.defaultPath}/${referenceId}?sourceType=${(response.data.sourceType || SourceType.Register).toLowerCase()}&previousStep=${EkycPreviousStep.Register.toLowerCase()}${paymentChannel}${scanQr}`,
          {
            state: {
              ...state,
              callbackUrl: response.data.callbackUrl || '',
            },
          }
        );
      }

      if (
        !!response.data.sourceType &&
        response.data.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
      ) {
        return navigate(response.data.callbackUrl, {
          state,
        });
      }

      return (window.location.href =
        process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
    },

    onError: () => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      });
    },
  });

  return { register };
};

export default useRegister;
