import { getLocalStorage } from 'src/utils/helper';
import { STORAGE_KEY } from 'src/constants';
import { jwtDecode } from 'jwt-decode';

export const extractInfoFromToken = (property: string) => {
  const token = getLocalStorage(STORAGE_KEY.accessToken);

  if (!token) {
    return null;
  }

  const tokenInfo: any = jwtDecode(token);
  return tokenInfo[property];
};
