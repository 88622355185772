const apiStrings = {
  ekyc: {
    create: '/ekyc/api/create',
    evaluate: '/ekyc/api/evaluate',
  },
  auth: {
    createAuthen: '/auth/api/create',
    otp: '/auth/api/otp',
    login: '/auth/api/login',
    register: '/auth/api/register',
    resetPin: '/auth/api/reset-pin',
    confirmSharing: '/auth/api/confirm-sharing-data',
    submitPin: '/auth/api/submit-pin',
  },
  order: {
    initOrder: '/order/api/init',
    orderStatus: '/order/api/get-order-status',
    submitOrder: '/order/api/submit',
    retryPayment: '/order/api/retry-payment',
  },
  installment: {
    getList: '/installment/api/fetch',
  },
  payment: {
    getPaymentMethods: '/payment/api/fetch',
    submit: '/payment/api/submit',
    getPaymentStatus: '/payment/api/query',
  },
  feedback: {
    send: '/feedback/api/send',
  },
  staticQr: {
    getQrDetails: '/qr/api/fetch',
    initTransaction: '/qr/api/init',
  },
  billing: {
    getList: '/api/user/configs/billing/fetch',
    create: '/api/user/configs/billing/save',
    update: (id: string) => `/api/user/configs/billing/update/${id}`,
  },
  refund: {
    getRefundDetail: (id: string) => `/refund/request/${id}`,
    getSavedAccounts: '/api/user/get-bank-accounts',
    getBankList: '/banking/banks',
    detectAccount: '/refund/request/verify-bank-account',
    submitRefund: '/refund/request/submit-user-bank-account',
  },
};

export default apiStrings;
