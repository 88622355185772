const routes = {
  defaultPath: '/',
  auth: {
    defaultPath: '/auth',
    otpModule: 'otp',
    register: 'register',
    login: 'login',
  },
  ekyc: {
    defaultPath: '/ekyc',
    starting: 'starting',
    trueID: 'trueID',
    identityPromotion: 'identityPromotion',
    processing: 'processing',
    success: 'success',
    failed: 'failed',
    pending: 'pending',
  },
  checkout: {
    defaultPath: '/checkout',
    exceedLimit: 'exceed-limit',
    error: 'error',
  },
  payment: {
    defaultPath: '/payment',
    virtualAccount: 'virtual-account',
  },
  staticQr: '/static-qr',
  orderStatus: '/order-status',
  error: '/error',
  order: '/order',
  refund: {
    defaultPath: '/refund',
    error: 'error',
    success: 'success',
    failed: 'failed',
  },
  downloadApp: '/download-app',
};

export default routes;
