import { PropsWithChildren } from 'react';
import {
  Navigate,
  NavigationType,
  useLocation,
  useNavigationType,
  useParams,
} from 'react-router-dom';

import { routeStrings } from 'src/routes';

interface AuthRouteProps extends PropsWithChildren {}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const { referenceId } = useParams();
  const navigationType = useNavigationType();
  const { state } = useLocation();

  if (navigationType !== NavigationType.Push) {
    const paymentChannel = state?.paymentChannel
      ? `&paymentChannel=${state?.paymentChannel}`
      : '';
    const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';

    return (
      <Navigate
        to={`${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`}
        state={{
          callbackUrl: state?.callbackUrl,
        }}
        replace
      />
    );
  }

  return children;
};

export default AuthRoute;
