import classNames from 'classnames';
import { cloneDeep, find, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ChevronRight } from 'src/assets/images/svg/chevron-right.svg';
import Button from 'src/components/Button';
import { CheckoutStep, PackageType } from 'src/components/Order';
import { routeStrings } from 'src/routes';
import { numberFormatter } from 'src/utils';

import { ButtonColorType, ButtonType } from 'src/components/Button/Button.type';
import {
  InstallmentPlanProps,
  PackageDetails,
} from 'src/components/Order/CheckoutDetails/OrderDetails/OrderDetails.type';
import { EkycPreviousStep, SourceType } from 'src/types';

const InstallmentPlan = ({
  packages,
  currency,
  highPriorityPackageNumber,
  appliedPackageDetails,
  dataTracking,
  setAppliedPackage,
  onStepChanged,
}: InstallmentPlanProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { sessionId } = useParams();
  const [suggestPlans, setSuggestPlans] = useState<
    (PackageDetails | undefined)[]
  >([]);

  const queryString = new URLSearchParams(search);
  const firstPackage = useMemo(() => {
    return find(
      packages,
      packageItem => packageItem.priority === highPriorityPackageNumber
    );
  }, [packages, highPriorityPackageNumber]);

  const secondPackage = useMemo(() => {
    return find(
      packages,
      packageItem => packageItem.priority === highPriorityPackageNumber - 1
    );
  }, [packages, highPriorityPackageNumber]);

  const uniqSuggestPlans = (
    newSuggestPlans: (PackageDetails | undefined)[]
  ) => {
    return uniqBy(
      newSuggestPlans.filter(packageItem => !!packageItem),
      'packageId'
    );
  };

  useEffect(() => {
    const selectedPlan = find(
      packages,
      packageItem => packageItem?.packageId === appliedPackageDetails?.packageId
    );

    setSuggestPlans(
      uniqSuggestPlans([selectedPlan, firstPackage, secondPackage])
    );
  }, [packages]);

  const onPackageChanged = (changePackageId: string) => {
    const clonedSuggestPlans = cloneDeep(suggestPlans);

    const selectedPlan = find(
      clonedSuggestPlans,
      packageItem => packageItem?.packageId === changePackageId
    );

    if (!selectedPlan?.active) {
      return navigate(
        `${routeStrings.ekyc.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&previousStep=${EkycPreviousStep.Order}&paymentChannel=${queryString.get('paymentChannel') || 'web'}&scanQr=${queryString.get('scanQr') || false}`,
        {
          state: {
            callbackUrl: window.location.pathname,
          },
        }
      );
    }

    dataTracking(selectedPlan);
    setAppliedPackage(changePackageId);
  };

  return (
    <section className="installment">
      <div className="installment__header">
        <div className="text-align-left">
          <p className="label label--sm">
            {t('order:checkout.installment.paymentPlan')}
          </p>

          {packages.length === 0 && (
            <p className="paragraph paragraph--xs color--error">
              {t('order:checkout.installment.noPackages')}
            </p>
          )}
        </div>

        <Button
          type={ButtonType.Text}
          colorType={ButtonColorType.Purple}
          title={t('more')}
          onClickHandled={() => onStepChanged(CheckoutStep.InstallmentPlan)}
          disable={packages.length === 0}
        />
      </div>

      <div className="installment-plan">
        {packages.length > 0 &&
          suggestPlans.map(suggestPlan => {
            if (!suggestPlan?.packageId) {
              return;
            }

            return (
              <div
                key={suggestPlan.packageId}
                className={classNames('installment-plan-item', {
                  'installment-plan-item--selected':
                    appliedPackageDetails?.packageId === suggestPlan.packageId,
                  'installment-plan-item--waiting': !suggestPlan.active,
                })}
                onClick={() => onPackageChanged(suggestPlan?.packageId || '')}
              >
                <div className="installment-plan-item__header">
                  <strong
                    className={classNames('label label--xs', {
                      'installment-plan-item__name':
                        suggestPlan.priority === highPriorityPackageNumber,
                    })}
                  >
                    {suggestPlan.packageName}
                  </strong>

                  {!suggestPlan.active ? (
                    <ChevronRight />
                  ) : (
                    suggestPlan.priority === highPriorityPackageNumber &&
                    suggestPlan.type !== PackageType.PayNow && (
                      <div className="label label--xxs installment-plan-item--suggested">
                        {t('order:checkout.installment.suggestion')}
                      </div>
                    )
                  )}
                </div>

                <span
                  className={classNames('paragraph paragraph--xs', {
                    'installment-plan-item__value--waiting':
                      !suggestPlan.active,
                  })}
                >
                  {!suggestPlan.active
                    ? t('order:checkout.installment.identifyToPayLater')
                    : suggestPlan.type !== PackageType.PayNow
                      ? `${numberFormatter(suggestPlan.subSeqInstallmentAmount)} ${t(`currency.${currency}`)} / ${t('order:checkout.installment.series')}`
                      : `${numberFormatter(suggestPlan.firstInstallmentAmount)} ${t(`currency.${currency}`)}`}
                </span>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default InstallmentPlan;
