import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import {
  CheckoutResponseStatus,
  ResponseStatus,
  SourceType,
  UserBusinessError,
} from 'src/types';

const useOrderStatus = () => {
  const { orderId = '' } = useParams();
  const navigate = useNavigate();
  const [ignoreLoading, setIgnoreLoading] = useState<boolean>(false);

  const { data, refetch: refetchOrderStatus } = useQuery({
    queryKey: ['get-order-status'],
    queryFn: async () => {
      try {
        const orderStatusResponse = await axios.post(
          apiStrings.order.orderStatus,
          {
            ...generateDefaultAuthValues(),
            orderId,
          },
          {
            params: { ignoreLoading },
          }
        );

        if (orderStatusResponse.data.status === ResponseStatus.BusinessError) {
          throw orderStatusResponse.data.data;
        }

        return orderStatusResponse.data.data;
      } catch (error: any) {
        const { resultCode = '' } = error;

        if (error?.response && error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${orderId}?sourceType=${SourceType.Order.toLowerCase()}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        if (
          [
            CheckoutResponseStatus.OrderNotFound,
            UserBusinessError.UserLocked,
          ].includes(resultCode)
        ) {
          navigate(
            `${routeStrings.checkout.defaultPath}/${orderId}/${routeStrings.checkout.error}`,
            {
              state: error,
            }
          );
          throw error;
        }

        if (resultCode === UserBusinessError.UserNotMatch) {
          navigate(`${routeStrings.error}`, {
            state: error,
          });
          throw error;
        }

        navigate(routeStrings.error);
        throw error;
      }
    },
  });

  useEffect(() => {
    if (data && ignoreLoading) {
      return;
    }

    setIgnoreLoading(true);
  }, [data]);

  return {
    orderStatusDetails: {
      orderId: data?.orderId || '',
      orderStatus: data?.orderStatus || '',
      amount: data?.orderAmount || 0,
      currency: data?.currency || '',
      paymentStatus: data?.paymentStatus || '',
      paymentMethod: data?.paymentMethod || '',
      paymentMethodName: data?.paymentMethodName || '',
      paymentTime: data?.paymentTime || '',
      reasonReject: data?.reasonReject || '',
      callbackUrl: data?.callbackUrl || '',
      merchantName: data?.merchantName || '',
    },
    refetchOrderStatus,
  };
};

export default useOrderStatus;
