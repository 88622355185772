import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Drawer } from 'src/components/Drawer';
import SupportForm from 'src/components/SupportModule/SupportForm';
import { useSendFeedback } from 'src/hooks/auth';

import { SupportModuleProps } from 'src/components/SupportModule/SupportModule.type';
import { SourceType } from 'src/types';

const SupportModule = ({
  open,
  onClose,
  sourceType = SourceType.Register,
}: SupportModuleProps) => {
  const { t } = useTranslation();
  const { referenceId, orderId } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { sendFeedback } = useSendFeedback();

  const onSubmit = (values: any) => {
    const id = referenceId ? referenceId : orderId;

    sendFeedback({
      content: '',
      referenceId: id || '',
      sourceType,
    });
    setIsSubmitted(!!values.supportText);
  };

  const onClosed = () => {
    setIsSubmitted(false);
    onClose();
  };

  return (
    <Drawer title={t('sendSupportRequest')} open={open} onClose={onClosed}>
      <SupportForm isSubmitted={isSubmitted} onSubmit={onSubmit} />
    </Drawer>
  );
};

export default SupportModule;
