import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { SecondaryLayout } from 'src/components/Layout';
import Loading from 'src/components/Loading';
import { useEvaluateEkyc } from 'src/hooks/ekyc';
import { SourceType } from 'src/types';

const Processing = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { evaluateEkyc } = useEvaluateEkyc();

  useEffect(() => {
    evaluateEkyc({
      ekycId: state?.ekycId,
      sourceType: state?.sourceType || SourceType.Register.toString(),
    });
  }, []);

  return (
    <SecondaryLayout
      title={t('ekyc:process.processingTitle')}
      description={t('ekyc:process.processingDescription')}
      image={<Loading />}
      logo={<Logo />}
    />
  );
};

export default Processing;
