import { OtpInput } from 'src/components/Input';

import { PinCreationProps } from 'src/types';

const PinCreation = ({
  pin,
  setPin,
  length,
  disabledOtp,
}: PinCreationProps) => {
  return (
    <OtpInput
      value={pin}
      syncOtp={setPin}
      length={length}
      isSecured
      disabled={disabledOtp}
    />
  );
};

export default PinCreation;
