import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SentRequestImage } from 'src/assets/images/svg/sent-request.svg';
import Button from 'src/components/Button';
import { TextArea } from 'src/components/Input';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { SupportFormProps } from 'src/components/SupportModule/SupportModule.type';

const SupportForm = ({ isSubmitted, onSubmit }: SupportFormProps) => {
  const { t } = useTranslation();

  const onBack = () => {
    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  return (
    <Formik initialValues={{ supportText: '' }} onSubmit={onSubmit}>
      {({ dirty }) => {
        return (
          <>
            {!isSubmitted ? (
              <Form className="support-modal">
                <div className="body">
                  <p className="body__title label label--sm">
                    {t('howCanWeHelpYou')}
                  </p>

                  <TextArea
                    name="supportText"
                    placeholder={t('inputSupportRequest')}
                    maxLength={300}
                    showCount={{
                      formatter: ({ value }) => {
                        return (
                          <span>{`${value.length}/300 ${t('characters')}`}</span>
                        );
                      },
                    }}
                    className="body__support-text"
                  />
                </div>

                <div className="footer">
                  <Button
                    title={t('send')}
                    type={ButtonType.Default}
                    colorType={ButtonColorType.PrimaryGradient}
                    isSubmit
                    size={ButtonSize.Large}
                    disable={!dirty}
                  />
                </div>
              </Form>
            ) : (
              <div className="support-result">
                <div className="support-result__content">
                  <SentRequestImage />
                  <p className="paragraph paragraph--sm">
                    {t('thanksToFeedBack')}
                  </p>
                </div>

                <Button
                  type={ButtonType.Default}
                  colorType={ButtonColorType.PrimaryGradient}
                  title={t('backToHomePage')}
                  size={ButtonSize.Large}
                  className="w--100 mt--28"
                  onClickHandled={onBack}
                />
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default SupportForm;
