export enum OtpType {
  Tel = 'tel',
  Password = 'password',
}

export interface OtpInputProps {
  value: string;
  syncOtp: (otpCode: string) => void;
  submit?: ({ otpCode }: { otpCode: string }) => void;
  error?: string;
  length?: number;
  placeholder?: string;
  inputClassName?: string;
  isSecured?: boolean;
  disabled?: boolean;
}
