export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const TRIGGER_EVENTS = {
  viewCheckoutDetail: 'view_checkout_detail',
  viewMoreTenor: 'view_more_tenor',
  selectTenor: 'select_tenor',
  confirmTenor: 'confirm_tenor',
  viewPayment: 'view_payment',
  viewMorePaymentMethod: 'view_more_payment_method',
  selectPaymentMethod: 'select_payment_method',
  confirmPaymentMethod: 'confirm_payment_method',
  downloadVietQR: 'download_vietqr',
  viewPaymentResult: 'view_payment_result',
};

export const DATA_LAYER_VARIABLES = {
  event: 'event',
  userId: 'user_id',
  userSessionId: 'user_session_id',
  module: 'module',
  screen: 'screen',
  category: 'category',
  orderId: 'order_id',
  orderAmount: 'order_amount',
  ekycStatus: 'ekyc_status',
  defaultTenor: 'default_tenor',
  accessMethod: 'access_method',
  tenor: 'tenor',
  totalPayment: 'total_payment',
  serviceFee: 'service_fee',
  downPayment: 'downpayment',
  promotionCode: 'promotion_code',
  promotionDiscountAmount: 'promotion_discount_amount',
  billing: 'billing',
  defaultPaymentMethod: 'default_payment_method',
  paymentMethod: 'payment_method',
  paymentTransactionId: 'payment_transaction_id',
  paymentFee: 'payment_fee',
  orderStatus: 'order_status',
  paymentStatus: 'payment_status',
};
