import Refund from 'src/features/Refund';

const RefundPage = () => {
  return (
    <div className="refund">
      <Refund />
    </div>
  );
};

export default RefundPage;
