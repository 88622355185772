import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { replace, trim } from 'lodash';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideConfirm, showConfirm } from 'src/redux/slices/uiSlice';
import {
  AuthenStep,
  CreateAuthRequest,
  ResponseStatus,
  SourceType,
} from 'src/types';

const useCreateAuthenRequest = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const { mutate: createAuthenRequest, isLoading } = useMutation({
    mutationFn: async (variables: CreateAuthRequest) => {
      try {
        const response = await axios.post(apiStrings.auth.createAuthen, {
          ...generateDefaultAuthValues(),
          phoneNumber: replace(trim(variables.phoneNumber), '+', ''),
          callbackUrl: variables.callbackUrl,
          sourceType: variables.sourceType,
          referenceId: variables.referenceId,
        });

        return response.data || '';
      } catch (error) {
        throw error;
      }
    },

    onSuccess: (response, variables) => {
      if (response.status === ResponseStatus.BusinessError) {
        return setErrorMessage(response.data.resultMsg || '');
      }

      let routePath = routeStrings.error;

      switch (response.data.nextStep) {
        case AuthenStep.Otp: {
          routePath = `${routeStrings.auth.defaultPath}/${variables.referenceId}/${routeStrings.auth.otpModule}`;
          dispatch(
            showConfirm({
              open: true,
              title: t('auth:otp.sendOtp'),
              content: (
                <p className="paragraph paragraph--sm">{`${t('auth:otp.otpDescription')} ${variables.phoneNumber}`}</p>
              ),
              titleClassName: 'text-align-left',
              cancelLabel: t('changePhoneNumber'),
              cancelHandler: () => dispatch(hideConfirm()),
              actionLabel: t('confirm'),
              actionHandler: () => {
                dispatch(hideConfirm());
                navigate(routePath, {
                  state: {
                    ...state,
                    sourceType: variables.sourceType,
                    authRequestId: response.data.authRequestId,
                    phoneNumber: variables.phoneNumber,
                    callbackUrl: variables.callbackUrl,
                    paymentChannel:
                      variables.sourceType === SourceType.Order
                        ? variables.paymentChannel
                        : '',
                    scanQr:
                      variables.sourceType === SourceType.Order
                        ? variables.scanQr
                        : false,
                    isDecreeOpen: response?.data?.isSharingData || false,
                  },
                });
              },
            })
          );
          break;
        }
        case AuthenStep.Login:
          routePath = `${routeStrings.auth.defaultPath}/${variables.referenceId}/${routeStrings.auth.login}`;
          break;
        case AuthenStep.Register:
          routePath = `${routeStrings.auth.defaultPath}/${variables.referenceId}/${routeStrings.auth.register}`;
          break;
        default:
          routePath = routeStrings.error;
      }

      if (response.data.nextStep !== AuthenStep.Otp) {
        navigate(routePath, {
          state: {
            ...state,
            sourceType: variables.sourceType,
            authRequestId: response.data.authRequestId,
            phoneNumber: variables.phoneNumber,
            callbackUrl: variables.callbackUrl,
            paymentChannel:
              variables.sourceType === SourceType.Order
                ? variables.paymentChannel
                : '',
            scanQr:
              variables.sourceType === SourceType.Order
                ? variables.scanQr
                : false,
            isDecreeOpen: response?.data?.isSharingData || false,
          },
        });
      }
    },

    onError: (_, variables) => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: variables.callbackUrl || '',
        },
      });
    },
  });

  return {
    createAuthenRequest,
    errorMessage,
    setErrorMessage,
    isAuthRequestCreating: isLoading,
  };
};

export default useCreateAuthenRequest;
