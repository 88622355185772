import classNames from 'classnames';
import { Formik } from 'formik';
import { BaseSyntheticEvent, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AngleRight } from 'src/assets/images/svg/angle-right.svg';
import { ReactComponent as CheckIcon } from 'src/assets/images/svg/check.svg';
import { ReactComponent as EmptyPromotion } from 'src/assets/images/svg/empty-promotion.svg';
import { ReactComponent as LookupIcon } from 'src/assets/images/svg/lookup.svg';
import PromotionThumbnail from 'src/assets/images/png/promotion-thumbnail.png';
import Button from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Drawer } from 'src/components/Drawer';

import { Promotion as PromotionType } from 'src/components/Order';
import { PromotionProps } from 'src/components/Order/CheckoutDetails/Promotion/Promotion.type';
import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const Promotion = ({
  promotions,
  selectedCoupon,
  setSelectedCoupon,
  onConfirmed,
}: PromotionProps) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');
  const [prevCouponCode] = useState<string>(selectedCoupon);
  const [promotionDetails, setPromotionDetails] =
    useState<PromotionType | null>(null);

  const onCouponSelected = (couponCode: string) => {
    if (couponCode === selectedCoupon) {
      return setSelectedCoupon('');
    }

    setSelectedCoupon(couponCode);
  };

  const onSearchTextChanged = (event: BaseSyntheticEvent) => {
    setSearchText(event.target.value.toUpperCase());
  };

  const onCouponDescriptionShowed = (event: SyntheticEvent, promotion: any) => {
    event.stopPropagation();
    setPromotionDetails(promotion);
  };

  const onCouponDescriptionClosed = () => {
    setPromotionDetails(null);
  };

  const displayedCoupons = useMemo(() => {
    return promotions
      .map(promotion => {
        const targetCompare = searchText.toLowerCase();
        const targetCode = (promotion.code || '').toLowerCase();

        if (
          (searchText &&
            !selectedCoupon &&
            ((promotion.isDisplay && targetCode.indexOf(targetCompare) > -1) ||
              (!promotion.isDisplay && targetCode === targetCompare))) ||
          (searchText &&
            selectedCoupon &&
            ((!promotion.isDisplay && targetCompare === targetCode) ||
              (promotion.isDisplay &&
                targetCode.indexOf(targetCompare) > -1))) ||
          (!searchText && !selectedCoupon && promotion.isDisplay) ||
          (!searchText &&
            selectedCoupon &&
            (promotion.isDisplay ||
              targetCode === selectedCoupon.toLowerCase())) ||
          (!searchText && prevCouponCode && prevCouponCode === promotion.code)
        ) {
          return (
            <div
              key={promotion.code}
              className="promotion-list__item"
              onClick={() => onCouponSelected(promotion.code)}
            >
              <img src={PromotionThumbnail} alt={promotion.title} />
              <div className="promotion-content">
                <div className="promotion-content__text">
                  <p className="label label--sm promotion-content__text--primary">
                    {promotion.title}
                  </p>
                  <p
                    className="label label--sm promotion-content__text--secondary"
                    onClick={(event: SyntheticEvent) =>
                      onCouponDescriptionShowed(event, promotion)
                    }
                  >
                    {promotion.code}
                    <AngleRight />
                  </p>
                </div>
                <div
                  className={classNames('promotion-content__checkbox', {
                    'promotion-content__checkbox--checked':
                      !!selectedCoupon && selectedCoupon === promotion.code,
                  })}
                >
                  {!!selectedCoupon && selectedCoupon === promotion.code && (
                    <CheckIcon />
                  )}
                </div>
              </div>
            </div>
          );
        }

        return;
      })
      .filter(item => !!item);
  }, [searchText, selectedCoupon]);

  return (
    <>
      <Formik
        initialValues={{
          searchText: searchText,
        }}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <section className="promotion">
              <div className="promotion-control">
                <Input
                  type="text"
                  name="searchText"
                  placeholder={t('order:checkout.promotion.inputCouponCode')}
                  value={values.searchText}
                  onValueChanged={onSearchTextChanged}
                  prefix={<LookupIcon />}
                  className="input paragraph paragraph--md promotion-control__search"
                />
              </div>

              <section className="promotion-list">
                {displayedCoupons.length > 0 ? (
                  displayedCoupons
                ) : (
                  <section className="promotion--empty">
                    <EmptyPromotion />
                    <p className="paragraph paragraph--sm">
                      {searchText.length > 0
                        ? t('order:checkout.promotion.searchingPromotion')
                        : t(
                            'order:checkout.promotion.emptyPromotionDescription'
                          )}
                    </p>
                  </section>
                )}
              </section>

              {displayedCoupons.length > 0 && (
                <footer className="footer">
                  <Button
                    type={ButtonType.Default}
                    colorType={
                      prevCouponCode !== selectedCoupon && !selectedCoupon
                        ? ButtonColorType.Gray
                        : ButtonColorType.PrimaryGradient
                    }
                    title={t(
                      `order:checkout.promotion.${prevCouponCode !== selectedCoupon && !selectedCoupon ? 'unselectAndContinue' : 'apply'}`
                    )}
                    onClickHandled={onConfirmed}
                    size={ButtonSize.Large}
                    disable={
                      prevCouponCode === selectedCoupon && !selectedCoupon
                    }
                  />
                </footer>
              )}
            </section>
          );
        }}
      </Formik>

      <Drawer
        open={!!promotionDetails}
        title={t('order:checkout.promotion.couponDescription')}
        onClose={onCouponDescriptionClosed}
      >
        <div className="promotion__description">
          <h5 className="label label--lg promotion__description--primary">
            {promotionDetails && promotionDetails.title
              ? promotionDetails.title
              : ''}
          </h5>
          <p className="paragraph paragraph--sm promotion__description--secondary">
            {promotionDetails && promotionDetails.description
              ? promotionDetails.description
              : ''}
          </p>

          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            size={ButtonSize.Large}
            title={t('order:checkout.promotion.apply')}
            className="w--100"
            onClickHandled={() => {
              setSelectedCoupon(promotionDetails?.code || '');
              onCouponDescriptionClosed();
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

export default Promotion;
