import { Switch as AntdSwitch } from 'antd';

import { SwitchProp } from 'src/components/Switch/Switch.type';

const Switch = ({
  checked,
  onChanged,
  size = 'default',
  className = '',
}: SwitchProp) => {
  return (
    <AntdSwitch
      checked={checked}
      onChange={onChanged}
      size={size}
      className={className}
    />
  );
};

export default Switch;
