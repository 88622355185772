import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';
import { find, includes } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'src/assets/images/svg/x-mark.svg';
import { ReactComponent as BackIcon } from 'src/assets/images/svg/arrow-left.svg';
import { ReactComponent as PlusIcon } from 'src/assets/images/svg/plus.svg';
import { ReactComponent as WarningIcon } from 'src/assets/images/svg/warning.svg';
import { ReactComponent as LocationIcon } from 'src/assets/images/svg/location.svg';
import { PrimaryLayout, QuaternaryLayout } from 'src/components/Layout';
import {
  CheckoutDetails,
  CheckoutStep,
  EkycStatus,
  PackageType,
} from 'src/components/Order';
import { BillingInfo } from 'src/components/Order/CheckoutDetails/BillingInfo';
import { InstallmentSelection } from 'src/components/Order/CheckoutDetails/InstallmentSelection';
import { Promotion } from 'src/components/Order/CheckoutDetails/Promotion';
import {
  DATA_LAYER_VARIABLES,
  STORAGE_KEY,
  TRIGGER_EVENTS,
} from 'src/constants';
import { useCheckoutDetails, useSubmitOrder } from 'src/hooks/checkout';
import Button from 'src/components/Button';
import { actionTracking, ModuleTracking, numberFormatter } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { CheckoutResponseStatus } from 'src/types';

const Checkout = () => {
  const { t } = useTranslation();
  const { sessionId = '' } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(CheckoutStep.Checkout);
  const [openBillingDrawer, setOpenBillingDrawer] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [isFireEvent, setIsFireEvent] = useState<boolean>(false);

  const {
    checkoutDetails,
    appliedCoupon,
    appliedPackage,
    appliedPercent,
    exportingReceipt,
    setAppliedCoupon,
    setAppliedPackage,
    setAppliedPercent,
    setExportingReceipt,
    refetchOrder,
  } = useCheckoutDetails();

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState<string>(appliedCoupon);
  const [selectedPackage, setSelectedPackage] =
    useState<string>(appliedPackage);
  const [selectedPercent, setSelectedPercent] =
    useState<number>(appliedPercent);

  const { errorMessage, isOrderSubmitting, submitOrder, setErrorMessage } =
    useSubmitOrder();

  const appliedPackageDetails = useMemo(() => {
    return find(checkoutDetails.installmentPlans.packages, packageItem =>
      appliedPackage
        ? packageItem.packageId === appliedPackage
        : packageItem.priority === checkoutDetails.highPriorityPackageNumber
    );
  }, [
    checkoutDetails.highPriorityPackageNumber,
    checkoutDetails.installmentPlans.packages,
    appliedPackage,
  ]);

  useEffect(() => {
    if (!checkoutDetails.merchantInfo.orderId || isFireEvent) {
      return;
    }

    actionTracking(
      TRIGGER_EVENTS.viewCheckoutDetail,
      ModuleTracking.Order,
      'order_checkout',
      {
        [DATA_LAYER_VARIABLES.orderId]:
          checkoutDetails.merchantInfo.orderId || sessionId,
        [DATA_LAYER_VARIABLES.orderAmount]:
          checkoutDetails.receiptDetails.orderValue,
        [DATA_LAYER_VARIABLES.ekycStatus]: checkoutDetails.ekycStatus,
        [DATA_LAYER_VARIABLES.defaultTenor]: appliedPackageDetails?.packageId,
      }
    );

    setIsFireEvent(true);
  }, [checkoutDetails, isFireEvent]);

  useEffect(() => {
    if (!appliedPackageDetails?.packageId) {
      setAppliedPackage('');
      setSelectedPackage('');
    }

    setAppliedPackage(appliedPackageDetails?.packageId || '');
    setSelectedPackage(appliedPackageDetails?.packageId || '');
  }, [appliedPackageDetails?.packageId]);

  const onStepChanged = (step: CheckoutStep) => setCurrentStep(step);

  const onOrderSubmitted = (position: any) => {
    submitOrder({
      sessionId,
      orderId: checkoutDetails.merchantInfo.orderId,
      packageId: appliedPackageDetails.packageId || '',
      promotionIds: [appliedCoupon],
      billingInfoId: exportingReceipt?.id || '',
      refetchOrder,
      latitude: position?.latitude || null,
      longitude: position?.longitude || null,
    });
  };

  const onSubmit = () => {
    actionTracking(
      TRIGGER_EVENTS.confirmTenor,
      ModuleTracking.Order,
      'order_checkout',
      {
        [DATA_LAYER_VARIABLES.orderId]:
          checkoutDetails.merchantInfo.orderId || sessionId,
        [DATA_LAYER_VARIABLES.orderAmount]:
          checkoutDetails.receiptDetails.orderValue,
        [DATA_LAYER_VARIABLES.tenor]: appliedPackageDetails?.packageId,
        [DATA_LAYER_VARIABLES.totalPayment]:
          checkoutDetails.receiptDetails.downPaymentValue,
        [DATA_LAYER_VARIABLES.serviceFee]:
          checkoutDetails.receiptDetails.serviceFee,
        [DATA_LAYER_VARIABLES.downPayment]:
          checkoutDetails.receiptDetails.downPaymentValue,
        [DATA_LAYER_VARIABLES.promotionCode]: appliedCoupon,
        [DATA_LAYER_VARIABLES.promotionDiscountAmount]: find(
          checkoutDetails.appliedPromotions,
          item => item.code === appliedCoupon
        )?.amount,
        [DATA_LAYER_VARIABLES.billing]: !!exportingReceipt?.id,
      }
    );

    setOpenInfoModal(true);
  };

  const onCheckoutBack = () => {
    const callbackUrlBeforeCheckout = sessionStorage.getItem(
      STORAGE_KEY.callbackUrl
    );
    const checkoutSession = sessionStorage.getItem(STORAGE_KEY.checkoutSession);

    if (
      !!callbackUrlBeforeCheckout &&
      !!checkoutSession &&
      checkoutSession === sessionId
    ) {
      sessionStorage.removeItem(STORAGE_KEY.callbackUrl);
      sessionStorage.removeItem(STORAGE_KEY.checkoutSession);
      return navigate(callbackUrlBeforeCheckout);
    }

    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  if (currentStep === CheckoutStep.Checkout) {
    return !checkoutDetails.merchantInfo.orderId ? (
      <></>
    ) : (
      <PrimaryLayout
        title={t('order:checkout.orderInfo')}
        onBack={onCheckoutBack}
        isMasterTitle
        footer={
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            size={ButtonSize.Large}
            title={
              <div className="label label--md d-flex gap--8">
                <p>{t('confirm')}</p>
                {(checkoutDetails.ekycStatus !== EkycStatus.Approved &&
                  appliedPackageDetails?.type !== PackageType.PayNow) ||
                (checkoutDetails.ekycStatus === EkycStatus.Approved &&
                  checkoutDetails.installmentPlans.packages.length === 0) ? (
                  <></>
                ) : (
                  <>
                    <div
                      className={classNames('dot', {
                        'dot--disabled':
                          checkoutDetails.installmentPlans.packages.length ===
                          0,
                      })}
                    />
                    <p>
                      {`${numberFormatter(checkoutDetails.receiptDetails.downPaymentValue)} ${t(`currency.${checkoutDetails.currency}`)}`}
                    </p>
                  </>
                )}
              </div>
            }
            onClickHandled={onSubmit}
            disable={
              !(
                checkoutDetails.ekycStatus === EkycStatus.Approved ||
                appliedPackageDetails?.type === PackageType.PayNow
              ) ||
              checkoutDetails.installmentPlans.packages.length === 0 ||
              isOrderSubmitting ||
              disableSubmit
            }
            id="btn-submit-checkout"
          />
        }
      >
        <CheckoutDetails
          orderDetails={{
            merchantInfo: checkoutDetails.merchantInfo,
            installmentPlans: checkoutDetails.installmentPlans,
            promotions: checkoutDetails.promotions,
          }}
          appliedPromotions={checkoutDetails.appliedPromotions}
          receiptDetails={checkoutDetails.receiptDetails}
          exportingReceipt={exportingReceipt}
          currency={checkoutDetails.currency}
          appliedPackageDetails={appliedPackageDetails}
          highPriorityPackageNumber={checkoutDetails.highPriorityPackageNumber}
          userEkycStatus={checkoutDetails.ekycStatus}
          setAppliedPackage={setAppliedPackage}
          onStepChanged={onStepChanged}
        />

        <AntdModal
          open={!!errorMessage}
          footer={
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              size={ButtonSize.Large}
              title={t('close')}
              className="w--100"
              onClickHandled={async () => {
                const reset = async () => {
                  setAppliedCoupon('');
                  setSelectedCoupon('');
                  setAppliedPackage('');
                  setSelectedPackage('');
                  setAppliedPercent(-1);
                  setSelectedPercent(-1);
                  setErrorMessage('');
                  setDisableSubmit(false);
                };

                await reset();
                await refetchOrder();
              }}
            />
          }
          width={375}
          closeIcon={false}
          destroyOnClose
        >
          <div className="checkout-modal">
            <div className="checkout-modal__content">
              <WarningIcon />
              <p className="paragraph paragraph--sm">{errorMessage}</p>
            </div>
          </div>
        </AntdModal>

        <AntdModal
          open={openInfoModal}
          footer={
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.PrimaryGradient}
              size={ButtonSize.Large}
              title={t('order:gps.turnOn')}
              className="w--100"
              onClickHandled={() => {
                navigator.geolocation.getCurrentPosition(
                  position => {
                    onOrderSubmitted(position.coords);
                  },
                  () => onOrderSubmitted(null)
                );
                setDisableSubmit(true);
                setOpenInfoModal(false);
              }}
            />
          }
          width={375}
          closeIcon={false}
          destroyOnClose
        >
          <div className="checkout-modal">
            <label className="label label--md checkout-modal__label">
              {t('order:gps.requestTitle')}
            </label>

            <div className="checkout-modal__content">
              <LocationIcon />
              <p className="paragraph paragraph--sm">
                {t('order:gps.requestContent')}
              </p>
            </div>
          </div>
        </AntdModal>

        <AntdModal
          open={errorMessage === CheckoutResponseStatus.UserRequireGps}
          footer={
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.PrimaryGradient}
              size={ButtonSize.Large}
              title={t('order:gps.understand')}
              className="w--100"
              onClickHandled={() => {
                setDisableSubmit(false);
                setErrorMessage('');
              }}
            />
          }
          width={375}
          closeIcon={false}
          destroyOnClose
        >
          <div className="checkout-modal">
            <div className="checkout-modal__content">
              <h3 className="heading heading--xs text-align-left">
                {t('order:gps.infoTitle')}
              </h3>
              <p className="paragraph paragraph--sm">
                {t('order:gps.infoContent')}
              </p>
            </div>
          </div>
        </AntdModal>
      </PrimaryLayout>
    );
  }

  const getCloseIcon = () => {
    if (currentStep === CheckoutStep.Billing) {
      return <BackIcon />;
    }

    return <CloseIcon />;
  };

  const getActionIcon = () => {
    if (currentStep === CheckoutStep.Billing) {
      return <PlusIcon />;
    }

    return null;
  };

  const getConfirmLabel = () => {
    if (currentStep === CheckoutStep.InstallmentPlan) {
      return t('choose');
    }

    return '';
  };

  const getQuaternaryTitle = () => {
    if (currentStep === CheckoutStep.Promotion) {
      return t('order:checkout.promotion.coupon');
    } else if (currentStep === CheckoutStep.InstallmentPlan) {
      return t('order:checkout.installment.paymentPlan');
    } else {
      return t('order:checkout.billing.manageBilling');
    }
  };

  const onBillingDrawerOpened = () => setOpenBillingDrawer(true);

  const onBillingDrawerClosed = () => setOpenBillingDrawer(false);

  const onClosed = () => {
    onStepChanged(CheckoutStep.Checkout);
    setSelectedCoupon(appliedCoupon);
    setSelectedPackage(appliedPackage);
    setSelectedPercent(appliedPercent);
  };

  const onConfirmed = () => {
    setCurrentStep(CheckoutStep.Checkout);
    setAppliedCoupon(selectedCoupon);
    setAppliedPackage(selectedPackage);
    setAppliedPercent(selectedPercent);
  };

  const getDisableConfirmBtn = () => {
    if (currentStep === CheckoutStep.Promotion) {
      return false;
    }

    if (currentStep === CheckoutStep.InstallmentPlan) {
      if (checkoutDetails.ekycStatus === EkycStatus.Approved) {
        return !selectedPackage;
      }

      return !includes(selectedPackage, PackageType.PayNow);
    }

    return true;
  };

  return (
    <QuaternaryLayout
      title={getQuaternaryTitle()}
      closeIcon={getCloseIcon()}
      onClosed={onClosed}
      actionIcon={getActionIcon()}
      onActioned={onBillingDrawerOpened}
      confirmLabel={getConfirmLabel()}
      onConfirmed={onConfirmed}
      disable={getDisableConfirmBtn()}
    >
      {currentStep === CheckoutStep.Promotion && (
        <Promotion
          promotions={checkoutDetails.promotions}
          selectedCoupon={selectedCoupon}
          setSelectedCoupon={setSelectedCoupon}
          onConfirmed={onConfirmed}
        />
      )}
      {currentStep === CheckoutStep.Billing && (
        <BillingInfo
          userId={checkoutDetails.userId}
          openBillingDrawer={openBillingDrawer}
          onBillingDrawerOpened={onBillingDrawerOpened}
          onBillingDrawerClosed={onBillingDrawerClosed}
          onBack={onClosed}
          setExportingReceipt={setExportingReceipt}
        />
      )}
      {currentStep === CheckoutStep.InstallmentPlan && (
        <InstallmentSelection
          ekycStatus={checkoutDetails.ekycStatus}
          orderId={checkoutDetails.merchantInfo.orderId}
          orderAmount={checkoutDetails.receiptDetails.orderValue}
          packages={checkoutDetails.installmentPlans.packages}
          bnplPlusPercents={checkoutDetails.installmentPlans.bnplPlusPercent}
          selectedPercent={selectedPercent}
          selectedPackage={selectedPackage}
          appliedPackageDetails={appliedPackageDetails}
          currency={checkoutDetails.installmentPlans.currency}
          setSelectedPackage={setSelectedPackage}
          setSelectedPercent={setSelectedPercent}
        />
      )}
    </QuaternaryLayout>
  );
};

export default Checkout;
