import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { includes } from 'lodash';
import { BankInformationManyQuery } from 'src/features/Refund';
import { apiStrings } from 'src/services';
import { ResponseStatus } from 'src/types';
import { toNonAccentVietnamese } from 'src/utils';

const useBankList = ({ search = '' }: { search: string }) => {
  const { data } = useQuery<BankInformationManyQuery>({
    queryKey: ['bank_getBankList'],
    queryFn: async () => {
      try {
        const response = await axios.get(apiStrings.refund.getBankList);
        if (response.data.status === ResponseStatus.BusinessError) {
          throw response.data.data;
        }

        return response.data?.data;
      } catch (error: any) {
        throw error;
      }
    },
  });

  const bankList = data?.filter(bank => {
    return (
      includes(
        toNonAccentVietnamese(bank?.shortName?.toLowerCase() || ''),
        toNonAccentVietnamese(search.toLowerCase())
      ) ||
      includes(
        toNonAccentVietnamese(bank?.code?.toLowerCase() || ''),
        toNonAccentVietnamese(search.toLowerCase())
      ) ||
      includes(
        toNonAccentVietnamese(bank?.bankName?.toLowerCase() || ''),
        toNonAccentVietnamese(search.toLowerCase())
      )
    );
  });

  return {
    bankList: bankList || [],
  };
};

export default useBankList;
