import { Input } from 'antd';
import { fromPairs, groupBy, sortBy, toPairs } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QuaternaryLayout } from 'src/components/Layout';

import { useBankList, Bank, AccountRefundType } from 'src/features/Refund';

import { ReactComponent as LookupIcon } from 'src/assets/images/svg/lookup.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/svg/x-mark.svg';

interface BankListProps {
  onChooseBank: (accountRefund: AccountRefundType) => void;
  onClose: () => void;
}

const BankList = ({ onChooseBank, onClose }: BankListProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const { bankList } = useBankList({ search });

  const groupBank = groupBy(bankList, bank =>
    bank.shortName.charAt(0).toUpperCase()
  );

  const sortedGrouped = fromPairs(sortBy(toPairs(groupBank), 0));

  return (
    <QuaternaryLayout
      title={t('refund:labels.beneficiaryBank')}
      closeIcon={<CloseIcon />}
      onClosed={onClose}
      onActioned={() => {}}
    >
      <div className="promotion-control">
        <Input
          type="text"
          name="searchText"
          placeholder={t('refund:placeholder.bankName')}
          value={search}
          onChange={e => setSearch(e.target.value)}
          prefix={<LookupIcon />}
          className="promotion-control__search"
          variant="borderless"
        />
      </div>

      <section className="bank-list">
        {Object.keys(sortedGrouped).map(letter => {
          return (
            <div className="bank-group" key={letter}>
              <p className="bank-letter">{letter}</p>
              {sortedGrouped[letter].map(({ logo, shortName, id }) => (
                <div
                  key={shortName}
                  onClick={() => {
                    onChooseBank({
                      account: {
                        name: '',
                        number: '',
                      },
                      bank: {
                        name: shortName,
                        logo,
                        id,
                      },
                      isDefault: false,
                    });
                    onClose();
                  }}
                >
                  <Bank logo={logo} name={shortName} />
                </div>
              ))}
            </div>
          );
        })}
      </section>
    </QuaternaryLayout>
  );
};

export default BankList;
