import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ReactComponent as OrderApprovedIcon } from 'src/assets/images/svg/order-approved.svg';
import { ReactComponent as OrderAuthorizedIcon } from 'src/assets/images/svg/order-authorized.svg';
import { ReactComponent as OrderCanceledIcon } from 'src/assets/images/svg/order-canceled.svg';
import { ReactComponent as OrderExpiredIcon } from 'src/assets/images/svg/order-expired.svg';
import { ReactComponent as OrderProcessingIcon } from 'src/assets/images/svg/order-processing.svg';
import { ReactComponent as OrderRejectedIcon } from 'src/assets/images/svg/order-rejected.svg';
import { numberFormatter } from 'src/utils';

import {
  OrderStatus,
  PaymentStatus,
  StatusProps,
} from 'src/components/Order/Status/Status.type';

dayjs.extend(utc);

const Status = ({ status, orderId, paymentDetails }: StatusProps) => {
  const { t } = useTranslation();

  const getOrderStatusTagColor = () => {
    switch (status) {
      case OrderStatus.Approved:
        return 'order-status--approved';
      case OrderStatus.Authorized:
        return 'order-status--authorized';
      case OrderStatus.Canceled:
        return 'order-status--canceled';
      case OrderStatus.Expired:
        return 'order-status--expired';
      case OrderStatus.Processing:
        return 'order-status--processing';
      case OrderStatus.Rejected:
        return 'order-status--rejected';
      default:
        return '';
    }
  };

  const getPaymentStatusTagColor = () => {
    switch (paymentDetails.paymentStatus) {
      case PaymentStatus.Success:
        return 'payment-status--success';
      case PaymentStatus.Waiting:
      case PaymentStatus.Pending:
        return 'payment-status--pending';
      case PaymentStatus.Refunded:
        return 'payment-status--refunded';
      case PaymentStatus.Refunding:
        return 'payment-status--refunding';
      case PaymentStatus.Canceled:
        return 'payment-status--canceled';
      case PaymentStatus.Failed:
        return 'payment-status--failed';
      default:
        return '';
    }
  };

  return (
    <div className="order-status">
      <div className="order-status-amount">
        {status === OrderStatus.Approved && <OrderApprovedIcon />}
        {status === OrderStatus.Authorized && <OrderAuthorizedIcon />}
        {status === OrderStatus.Canceled && <OrderCanceledIcon />}
        {status === OrderStatus.Expired && <OrderExpiredIcon />}
        {status === OrderStatus.Processing && <OrderProcessingIcon />}
        {status === OrderStatus.Rejected && <OrderRejectedIcon />}

        <p className="paragraph paragraph--xs order-status-amount__title">
          {t('order:orderStatus.paymentAmount')}
        </p>

        <p className="heading heading--md order-status-amount__total">
          {numberFormatter(paymentDetails.paymentAmount)}
          <span className="heading heading-sm">
            {t(`currency.${paymentDetails.currency.toLowerCase()}`)}
          </span>
        </p>
      </div>

      <section>
        <div className="order-status-details">
          <p className="paragraph paragraph--xs order-status-details__title">
            {t('order:orderStatus.status')}
          </p>
          <span className={`label label--xs ${getOrderStatusTagColor()}`}>
            {t(`order:orderStatus.${camelCase(status)}`)}
          </span>
        </div>
        <div className="order-status-details">
          <p className="paragraph paragraph--xs order-status-details__title">
            {t('order:orderStatus.orderId')}
          </p>
          <p className="paragraph paragraph--xs order-status-details__value">
            {orderId}
          </p>
        </div>
        {/*{includes([OrderStatus.Canceled, OrderStatus.Rejected], status) && (*/}
        {/*  <div className="order-status-details">*/}
        {/*    <p className="paragraph paragraph--xs order-status-details__title">*/}
        {/*      {t('order:orderStatus.reason')}*/}
        {/*    </p>*/}
        {/*    <p className="paragraph paragraph--xs order-status-details__value">*/}
        {/*      {paymentDetails.reason}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*)}*/}
        {paymentDetails.paymentMethod && (
          <div className="order-status-details">
            <p className="paragraph paragraph--xs order-status-details__title">
              {t('order:orderStatus.paymentMethod')}
            </p>
            <p className="paragraph paragraph--xs order-status-details__value">
              {paymentDetails.paymentMethodName}
            </p>
          </div>
        )}
        <div className="order-status-details">
          <p className="paragraph paragraph--xs order-status-details__title">
            {t('order:orderStatus.paymentStatus')}
          </p>
          <span className={`label label--xs ${getPaymentStatusTagColor()}`}>
            {t(
              `order:paymentStatus.${camelCase(paymentDetails.paymentStatus)}`
            )}
          </span>
        </div>
        <div className="order-status-details">
          <p className="paragraph paragraph--xs order-status-details__title">
            {t('order:orderStatus.payTime')}
          </p>
          <p className="paragraph paragraph--xs order-status-details__value">
            <span className="order-status-details__date">
              {`${dayjs.utc(paymentDetails.paymentTime.toString()).local().format('D')} ${t('order:orderStatus.month')} ${dayjs.utc(paymentDetails.paymentTime.toString()).local().format('M, YYYY')}`}
            </span>
            <span>
              {dayjs
                .utc(paymentDetails.paymentTime.toString())
                .local()
                .format('HH:mm')}
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Status;
