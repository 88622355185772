import { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

export enum ModalType {
  Custom = 'custom',
  Confirm = 'confirm',
}

export interface CustomModalProps extends PropsWithChildren {
  open: boolean;
  title: ReactNode;
  width: number;
  type?: ModalType;
  footer?: ReactNode;
  className?: string;
  titleClassName?: string;
  closeIcon?: boolean;
  onCancel?: (event: SyntheticEvent) => void;
}

export interface ConfirmModalProps {
  open: boolean;
  title: ReactNode;
  content: ReactNode;
  titleClassName?: string;
  closeIcon?: boolean;
  cancelLabel?: ReactNode;
  actionLabel?: ReactNode;
  cancelHandler?: () => void;
  actionHandler?: () => void;
}
