import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ResponseStatus, SourceType } from 'src/types';
import { useNavigate } from 'react-router-dom';
import { routeStrings } from 'src/routes';
import { RefundOneQuery } from 'src/features/Refund';
import { apiStrings } from 'src/services';

export type UseRefundDetailProps = {
  refundRequestId?: string;
};

const useRefundDetail = ({ refundRequestId = '' }: UseRefundDetailProps) => {
  const navigate = useNavigate();

  const { data } = useQuery<RefundOneQuery>({
    queryKey: [
      'refund_getRefundDetail',
      {
        request: {
          refundRequestId,
        },
      },
    ],
    queryFn: async () => {
      try {
        const response = await axios.get(
          apiStrings.refund.getRefundDetail(refundRequestId)
        );
        if (response.data?.status === ResponseStatus.BusinessError) {
          navigate(
            `${routeStrings.refund.defaultPath}/${refundRequestId}/${routeStrings.refund.error}`,
            {
              state: {
                resultCode: response.data.data.resultCode,
                resultMsg: response.data.data.resultMsg,
              },
            }
          );
        }

        return response.data?.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${refundRequestId}?sourceType=${SourceType.Refund.toLowerCase()}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        navigate(routeStrings.error);
        throw error;
      }
    },
    cacheTime: 0,
  });

  return {
    refundDetail: data,
  };
};

export default useRefundDetail;
