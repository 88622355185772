import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { apiStrings } from 'src/services';

const useCreateBilling = () => {
  const { mutate } = useMutation({
    mutationFn: async (variables: any) => {
      try {
        const response = await axios.post(apiStrings.billing.create, {
          userId: variables.userId,
          billingInfo: {
            fullName: variables.billingInfo.name,
            email: variables.billingInfo.email,
            address: variables.billingInfo.exportingAddress,
            tax: variables.billingInfo.taxNumber,
          },
        });

        return response.data;
      } catch (error: any) {
        throw error;
      }
    },
  });

  return {
    createBilling: mutate,
  };
};

export default useCreateBilling;
