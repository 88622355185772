import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as HexagonExclamation } from 'src/assets/images/svg/hexagon-exclamation.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';
import { SupportModule } from 'src/components/SupportModule';
import { STORAGE_KEY } from 'src/constants';
import { routeStrings } from 'src/routes';
import { removeItemLocalStorage } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import {
  CheckoutResponseStatus,
  SourceType,
  UserBusinessError,
} from 'src/types';

const Error = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const onBack = () => {
    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const onLogin = () => {
    removeItemLocalStorage(STORAGE_KEY.accessToken);
    navigate(
      `${routeStrings.auth.defaultPath}/${state?.sessionId || ''}?sourceType=${SourceType.Order.toLowerCase()}&paymentChannel=${state?.paymentChannel || 'web'}&scanQr=${state?.scanQr || false}`,
      {
        state: {
          callbackUrl: `${routeStrings.checkout.defaultPath}/${state?.sessionId || ''}?paymentChannel=${state?.paymentChannel || 'web'}&scanQr=${state?.scanQr || false}`,
        },
        replace: true,
      }
    );
  };

  const onDrawerClosed = () => setOpenDrawer(false);

  return (
    <>
      <SecondaryLayout
        logo={<Logo />}
        image={<HexagonExclamation />}
        title={t('order:error.errorOccur')}
        description={state?.resultMsg || t('error.systemError')}
        footer={
          <>
            {[CheckoutResponseStatus.PhoneNumberNotMatch].includes(
              state?.resultCode
            ) && (
              <Button
                title={t('order:error.login')}
                type={ButtonType.Default}
                colorType={ButtonColorType.PrimaryGradient}
                size={ButtonSize.Large}
                onClickHandled={onLogin}
              />
            )}
            {[UserBusinessError.UserLocked].includes(state?.resultCode) && (
              <Button
                title={t('order:error.contactToFundiin')}
                type={ButtonType.Default}
                colorType={ButtonColorType.PrimaryGradient}
                size={ButtonSize.Large}
                onClickHandled={() => setOpenDrawer(true)}
              />
            )}
            <Button
              title={t('close')}
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              size={ButtonSize.Large}
              onClickHandled={onBack}
            />
          </>
        }
      />
      <SupportModule
        open={openDrawer}
        onClose={onDrawerClosed}
        sourceType={SourceType.Order}
      />
    </>
  );
};

export default Error;
