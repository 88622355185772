import { ConfigProvider } from 'antd';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';

import { COLOR_PRIMARY_600, GTM_ID, STORAGE_KEY } from 'src/constants';
import { QueryClientProvider } from 'src/services';
import { showLoading, hideLoading } from 'src/redux/slices/uiSlice';
import { Routes } from 'src/routes';
import { getLocalStorage } from 'src/utils';

import 'src/styles/main.scss';
import 'src/i18n/config';

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID || '',
    });
  }, []);

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      if (config && config.headers) {
        config.headers['Content-Type'] = 'application/json';
        config.headers.lang = i18n.language;

        const accessToken = getLocalStorage(STORAGE_KEY.accessToken);

        if (accessToken) {
          config.headers.Authorization = accessToken;
        }
      }

      if (config.params?.ignoreLoading) {
        return config;
      }

      dispatch(showLoading());
      return config;
    },
    (error: AxiosError) => {
      dispatch(hideLoading());
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      dispatch(hideLoading());

      return response;
    },
    (error: AxiosError<any>) => {
      dispatch(hideLoading());
      return Promise.reject(error);
    }
  );

  return (
    <QueryClientProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLOR_PRIMARY_600,
            fontFamily: 'Manrope-Regular, sans-serif',
          },
          components: {
            Layout: {
              headerBg: '#fff',
              headerPadding: 20,
              footerPadding: 20,
            },
            Input: {
              paddingBlock: 9,
            },
          },
        }}
      >
        <Routes />
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
