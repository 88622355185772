import { SourceModule } from 'src/types/common';

export enum AuthenStep {
  Otp = 'OTP',
  Login = 'LOGIN',
  Register = 'REGISTER',
  ResetPin = 'RESET_PIN',
  Ekyc = 'EKYC',
}

export enum OtpAction {
  RequestOtp = 'REQUEST_OTP',
  VerifyOtp = 'VERIFY_OTP',
  ResendOtp = 'RESEND_OTP',
}

export interface CreateAuthRequest {
  phoneNumber: string;
  sourceType: SourceModule;
  callbackUrl: string;
  referenceId: string;
  paymentChannel: string;
  scanQr: string | boolean;
}

export interface VerifyOtpRequest {
  otpCode: string;
}

export interface PinCreationProps {
  pin: string;
  setPin: (pin: string) => void;
  length: number;
  disabledOtp: boolean;
}

export interface PinModificationProps {
  pin: string;
  length: number;
}
