import { Spin as AntdSpin } from 'antd';
import { includes } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import Loading from 'src/components/Loading';
import { ConfirmModal } from 'src/components/Modal';
import { ReduxState } from 'src/redux/store';
import { routeStrings } from 'src/routes';

const MainLayout = () => {
  const { referenceId } = useParams();
  const { pathname } = useLocation();
  const { loading, confirm } = useSelector((state: ReduxState) => state.ui);

  const isHideLoading = useMemo(() => {
    return includes(
      [
        `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.processing}`,
      ],
      pathname
    );
  }, [pathname]);

  return (
    <AntdSpin spinning={loading && !isHideLoading} indicator={<Loading />}>
      <div className="main-layout">
        <Outlet />
      </div>

      <ConfirmModal
        open={confirm.open}
        title={confirm.title}
        content={confirm.content}
        titleClassName={confirm.titleClassName}
        actionHandler={confirm.actionHandler}
        actionLabel={confirm.actionLabel}
        cancelHandler={confirm.cancelHandler}
        cancelLabel={confirm.cancelLabel}
        closeIcon={confirm.closeIcon}
      />
    </AntdSpin>
  );
};

export default MainLayout;
