import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as PendingImage } from 'src/assets/images/svg/pending.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';
import { SupportModule } from 'src/components/SupportModule';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { SourceType } from 'src/types';

const Pending = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);

  const onBack = () => {
    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const onOpenSupportModule = () => setOpen(true);

  const onCloseSupportModule = () => setOpen(false);

  useEffect(() => {
    if (
      state?.sourceType &&
      state?.sourceType.toUpperCase() === SourceType.Order &&
      !open
    ) {
      const intervalId = setInterval(onBack, 3000);
      return () => clearInterval(intervalId);
    }

    return;
  }, [open]);

  return (
    <>
      <SecondaryLayout
        logo={<Logo />}
        image={<PendingImage />}
        title={t('ekyc:identityStatus.pendingApprovalTitle')}
        description={t('ekyc:identityStatus.pendingApprovalDescription')}
        footer={
          <>
            {state?.sourceType &&
              state?.sourceType.toUpperCase() === SourceType.Order && (
                <Button
                  title={t('continuePayment')}
                  type={ButtonType.Default}
                  colorType={ButtonColorType.PrimaryGradient}
                  size={ButtonSize.Large}
                  onClickHandled={onBack}
                />
              )}
            <Button
              title={t('sendSupportRequest')}
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              size={ButtonSize.Large}
              onClickHandled={onOpenSupportModule}
            />
            <Button
              title={t('close')}
              type={ButtonType.Text}
              colorType={ButtonColorType.Purple}
              size={ButtonSize.Large}
              onClickHandled={onBack}
            />
          </>
        }
      />
      <SupportModule open={open} onClose={onCloseSupportModule} />
    </>
  );
};

export default Pending;
