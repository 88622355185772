import { useEffect, useState } from 'react';

const useCountDown = (initialTime: number = 5 * 60 * 1000) => {
  const [countdownTime, setCountdownTime] = useState(initialTime);

  useEffect(() => {
    setCountdownTime(initialTime);
  }, [initialTime]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdownTime(prev => {
        if (prev <= 0) {
          return prev;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const resetCountdown = (resetTime: number) => setCountdownTime(resetTime);

  return { countdownTime, resetCountdown };
};
export default useCountDown;
