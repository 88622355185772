export * from 'src/constants/color';
export * from 'src/constants/regexPattern';
export * from 'src/constants/tagManager';

export const TRUEID_VERSION = '3.1.3';
export const CLIENT_VERSION = '1.0.0';
export const AUTH_PIN_LENGTH = 6;

export const STORAGE_KEY = {
  accessToken: 'accessToken',
  deviceId: 'deviceId',
  callbackUrl: 'callbackUrl',
  checkoutSession: 'checkoutSession',
};
