import { Steps as AntdSteps } from 'antd';

import { StepsProps } from 'src/components/Steps/Steps.type';

const Steps = ({
  steps,
  className,
  rootClassName,
  current,
  direction,
  initial,
  responsive,
  size,
  status,
}: StepsProps) => {
  return (
    <AntdSteps
      items={steps}
      className={className}
      rootClassName={rootClassName}
      size={size}
      initial={initial}
      current={current}
      direction={direction}
      responsive={responsive}
      status={status}
    />
  );
};

export default Steps;
