import dayjs from 'dayjs';
import platform from 'platform';
import { v4 as generateUniqueId } from 'uuid';

import { CLIENT_VERSION, STORAGE_KEY, TRUEID_VERSION } from 'src/constants';
import { getLocalStorage, storeLocalStorage } from 'src/utils/helper';

import { BaseRequest, Platform } from 'src/types';

const generateBaseRequest = (): BaseRequest => {
  let deviceId = getLocalStorage(STORAGE_KEY.deviceId);

  if (!deviceId) {
    deviceId = generateUniqueId();
    storeLocalStorage(STORAGE_KEY.deviceId, deviceId);
  }

  return {
    deviceId,
    requestId: generateUniqueId(),
    platform: Platform.Web,
    clientVer: CLIENT_VERSION,
    startTime: dayjs().toISOString(),
    userAgent: JSON.stringify({
      os: {
        name: platform.os?.family,
        architecture: platform.os?.architecture,
        version: platform.os?.version,
      },
      browser: {
        name: platform.name,
        version: platform.version,
      },
      manufacturer: platform.manufacturer,
      layout: platform.layout,
      product: platform.product,
    }),
  };
};

export const generateDefaultEkycValues = (): {
  ekycVersion: string;
} & BaseRequest => {
  return {
    ...generateBaseRequest(),
    ekycVersion: TRUEID_VERSION,
  };
};

export const generateDefaultAuthValues = (
  authRequestId?: string
): { authRequestId?: string } & BaseRequest => {
  const result = generateBaseRequest();

  if (authRequestId) {
    return {
      ...result,
      authRequestId,
    };
  }

  return result;
};
