import { Drawer as AntdDrawer } from 'antd';

import { ReactComponent as CloseIcon } from 'src/assets/images/svg/x-mark.svg';

import { DrawerProps, Placement } from 'src/components/Drawer/Drawer.type';

const Drawer = ({
  open,
  title,
  children,
  onClose,
  placement = Placement.BOTTOM,
  width = '100%',
  height = 'auto',
  headerClassName = '',
  bodyClassName = '',
}: DrawerProps) => {
  return (
    <AntdDrawer
      open={open}
      width={width}
      onClose={onClose}
      title={<p className="label label--md m--0">{title}</p>}
      height={height}
      placement={placement}
      classNames={{
        header: headerClassName,
        body: bodyClassName,
      }}
      closeIcon={onClose ? <CloseIcon /> : false}
      destroyOnClose
    >
      {children}
    </AntdDrawer>
  );
};

export default Drawer;
