import { includes } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { TertiaryLayout } from 'src/components/Layout';
import { ContentStatus } from 'src/components/Order';
import { PaymentMethodEnum } from 'src/components/Payment';
import { SupportModule } from 'src/components/SupportModule';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { useOrderStatus, useRetryPayment } from 'src/hooks/checkout';
import { actionTracking, ModuleTracking } from 'src/utils';

import { OrderStatus as OrderStatusEnum } from 'src/components/Order/Status/Status.type';
import { SourceType } from 'src/types';

const OrderStatus = () => {
  const { t } = useTranslation();
  const { orderId = '' } = useParams();
  const { state } = useLocation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [countdownTime, setCountdownTime] = useState<number>(5);
  const { orderStatusDetails, refetchOrderStatus } = useOrderStatus();
  const { retryPayment } = useRetryPayment();

  useEffect(() => {
    if (
      includes(
        [OrderStatusEnum.Processing, OrderStatusEnum.Authorized],
        orderStatusDetails.orderStatus
      )
    ) {
      const intervalId = setInterval(() => refetchOrderStatus(), 5000);

      return () => {
        clearInterval(intervalId);
      };
    }

    if (
      !includes(
        [OrderStatusEnum.Processing, OrderStatusEnum.Authorized],
        orderStatusDetails.orderStatus
      ) &&
      !!orderStatusDetails.callbackUrl
    ) {
      const intervalId = setInterval(() => {
        if (countdownTime <= 0) {
          return (window.location.href = orderStatusDetails.callbackUrl);
        }

        return setCountdownTime(prevState => prevState - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }

    if (
      includes(
        [OrderStatusEnum.Approved, OrderStatusEnum.Rejected],
        orderStatusDetails.orderStatus
      )
    ) {
      actionTracking(
        TRIGGER_EVENTS.viewPaymentResult,
        ModuleTracking.Order,
        'order_status',
        {
          [DATA_LAYER_VARIABLES.paymentTransactionId]:
            state?.paymentTransactionId,
          [DATA_LAYER_VARIABLES.orderId]: orderId,
          [DATA_LAYER_VARIABLES.orderStatus]: orderStatusDetails.orderStatus,
          [DATA_LAYER_VARIABLES.paymentStatus]:
            orderStatusDetails?.paymentStatus,
        }
      );
    }

    return;
  }, [
    orderStatusDetails.orderStatus,
    orderStatusDetails.callbackUrl,
    countdownTime,
  ]);

  const primaryButtonLabel = useMemo(() => {
    switch (orderStatusDetails?.orderStatus as OrderStatusEnum) {
      case OrderStatusEnum.Approved:
      case OrderStatusEnum.Canceled:
      case OrderStatusEnum.Rejected:
      case OrderStatusEnum.Expired: {
        if (!!orderStatusDetails?.callbackUrl) {
          return t('order:orderStatus.backToMerchantSite', {
            merchantName: orderStatusDetails?.merchantName,
            countdownTime: `00:0${countdownTime}`,
          });
        }

        return t('order:orderStatus.backToHomepage');
      }
      case OrderStatusEnum.Processing:
        if (
          orderStatusDetails?.paymentMethod === PaymentMethodEnum.InStorePayment
        ) {
          return '';
        }
        return t('order:orderStatus.proceedToPay');
      default:
        return '';
    }
  }, [
    t,
    orderStatusDetails?.orderStatus,
    orderStatusDetails.callbackUrl,
    countdownTime,
  ]);

  const secondaryButtonLabel = useMemo(() => {
    switch (orderStatusDetails?.orderStatus as OrderStatusEnum) {
      case OrderStatusEnum.Approved:
        return t('order:orderStatus.showPaymentPlan');
      case OrderStatusEnum.Canceled:
      case OrderStatusEnum.Rejected:
      case OrderStatusEnum.Expired:
        return t('order:orderStatus.contactToFundiin');
      case OrderStatusEnum.Authorized:
      case OrderStatusEnum.Processing:
        return t('order:orderStatus.backToHomepage');
      default:
        return '';
    }
  }, [t, orderStatusDetails?.orderStatus]);

  const onConfirmed = () => {
    switch (orderStatusDetails?.orderStatus as OrderStatusEnum) {
      case OrderStatusEnum.Approved:
      case OrderStatusEnum.Canceled:
      case OrderStatusEnum.Rejected:
      case OrderStatusEnum.Expired: {
        if (!!orderStatusDetails?.callbackUrl) {
          return (window.location.href = orderStatusDetails?.callbackUrl);
        }

        return (window.location.href =
          process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
      }
      case OrderStatusEnum.Processing:
        if (
          orderStatusDetails?.paymentMethod === PaymentMethodEnum.InStorePayment
        ) {
          return;
        }
        return retryPayment();
      default:
        return;
    }
  };

  const onCanceled = () => {
    switch (orderStatusDetails?.orderStatus as OrderStatusEnum) {
      case OrderStatusEnum.Approved:
        return (window.location.href =
          process.env.REACT_APP_DYNAMIC_LINK || ''); // TODO: BaoNguyen need to refactor cause dynamic link will be deprecated
      case OrderStatusEnum.Canceled:
      case OrderStatusEnum.Rejected:
      case OrderStatusEnum.Expired:
        return setOpenDrawer(true);
      case OrderStatusEnum.Authorized:
      case OrderStatusEnum.Processing:
        return (window.location.href =
          process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
      default:
        return;
    }
  };

  const onDrawerClosed = () => setOpenDrawer(false);

  return (
    orderStatusDetails.orderId && (
      <TertiaryLayout
        confirmButtonLabel={primaryButtonLabel}
        cancelButtonLabel={secondaryButtonLabel}
        onConfirmed={onConfirmed}
        onCanceled={onCanceled}
      >
        <ContentStatus
          status={orderStatusDetails?.orderStatus}
          orderId={orderStatusDetails?.orderId}
          paymentDetails={{
            paymentAmount: orderStatusDetails?.amount,
            paymentMethod: orderStatusDetails?.paymentMethod,
            paymentMethodName: orderStatusDetails?.paymentMethodName,
            paymentStatus: orderStatusDetails?.paymentStatus,
            paymentTime: orderStatusDetails?.paymentTime,
            currency: orderStatusDetails?.currency,
            reason: orderStatusDetails?.reasonReject,
          }}
        />

        <SupportModule
          open={openDrawer}
          onClose={onDrawerClosed}
          sourceType={SourceType.Order}
        />
      </TertiaryLayout>
    )
  );
};

export default OrderStatus;
