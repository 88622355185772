import { Radio as AntdRadio } from 'antd';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BankIcon } from 'src/assets/images/svg/bank.svg';
import { PaymentSelectionProps } from 'src/components/Payment';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { actionTracking, ModuleTracking } from 'src/utils';

const PaymentSelection = ({
  paymentTransactionId,
  paymentTokens,
  paymentMethods,
  paymentAmount,
  selectedToken,
  setSelectedToken,
}: PaymentSelectionProps) => {
  const { t } = useTranslation();

  const onTokenSelected = (token: string) => {
    const target = find(
      [...paymentTokens, ...paymentMethods],
      item => item.token === token
    );

    actionTracking(
      TRIGGER_EVENTS.selectPaymentMethod,
      ModuleTracking.Order,
      'order_payment',
      {
        [DATA_LAYER_VARIABLES.paymentTransactionId]: paymentTransactionId,
        [DATA_LAYER_VARIABLES.paymentMethod]: token,
        [DATA_LAYER_VARIABLES.totalPayment]:
          paymentAmount + (target?.paymentFee || 0),
        [DATA_LAYER_VARIABLES.paymentFee]: target?.paymentFee,
      }
    );

    setSelectedToken(token);
  };

  return (
    <div className="payment-selection-wrapper">
      {paymentTokens.length > 0 && (
        <section className="payment-selection">
          <p className="label label--xs payment-selection__header">
            {t('payment:method.saved').toUpperCase()}
          </p>

          <div className="payment-selection-list">
            {paymentTokens.map(paymentToken => (
              <div
                key={paymentToken.token}
                className="payment-selection-list-item"
                onClick={() => onTokenSelected(paymentToken.token)}
              >
                <div className="payment-selection-list-item__name">
                  {paymentToken.bankLogoUrl ? (
                    <img
                      src={paymentToken.bankLogoUrl}
                      alt={paymentToken.bankCode}
                      width={20}
                      height={17}
                    />
                  ) : (
                    <BankIcon />
                  )}
                  <p className="label label--sm pt--16 pb--16">
                    {paymentToken.cardNumber}
                  </p>
                </div>

                <AntdRadio checked={selectedToken === paymentToken.token} />
              </div>
            ))}
          </div>
        </section>
      )}

      <section className="payment-selection">
        <p className="label label--xs payment-selection__header pt--16">
          {t('payment:method.others').toUpperCase()}
        </p>

        <div className="payment-selection-list">
          {paymentMethods.map(paymentMethod => (
            <div
              key={paymentMethod.method}
              className="payment-selection-list-item"
              onClick={() => onTokenSelected(paymentMethod.method)}
            >
              <div className="payment-selection-list-item__name">
                {paymentMethod.paymentLogo}
                <p className="label label--sm pt--16 pb--16">
                  {paymentMethod.name}
                </p>
              </div>

              <AntdRadio checked={selectedToken === paymentMethod.method} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default PaymentSelection;
