import { ReactNode } from 'react';

export enum StepKey {
  Download = 'DOWNLOAD',
  Login = 'LOGIN',
  Payment = 'PAYMENT',
}

export type Step = {
  title: string;
  description: string;
  image: ReactNode;
};
