export enum CheckoutResponseStatus {
  OrderNotFound = 'ORDER_NOT_FOUND',
  OrderExpired = 'ORDER_EXPIRED',
  OrderStatusInvalid = 'ORDER_STATUS_INVALID',
  PhoneNumberNotMatch = 'PHONE_NUMBER_NOT_MATCH',
  UserExceedLimitation = 'USER_EXCEED_LIMITATION',
  UserNotSupportedPayLater = 'USER_NOT_SUPPORTED_PAYLATER',
  OrderIsProcessing = 'ORDER_IS_PROCESSING',
  UserRequireGps = 'USER_REQUIRE_GPS',
  ReachOBLimitation = 'APP_DOWNLOAD_REQUIRED',
}
