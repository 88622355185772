import { find } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'src/assets/images/svg/x-mark.svg';
import Button from 'src/components/Button';
import { PrimaryLayout, QuaternaryLayout } from 'src/components/Layout';
import {
  PaymentDetails,
  PaymentSelection,
  PaymentStep,
} from 'src/components/Payment';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { usePaymentMethodList, useSubmitPayment } from 'src/hooks/payment';
import { actionTracking, ModuleTracking, numberFormatter } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const Payment = () => {
  const { t } = useTranslation();

  const { paymentDetails, isPaymentFetching } = usePaymentMethodList();
  const { submitOrder } = useSubmitPayment();

  const [currentStep, setCurrentStep] = useState(PaymentStep.Details);
  const [appliedToken, setAppliedToken] = useState<string>('');
  const [selectedToken, setSelectedToken] = useState<string>('');
  const [isFireEvent, setIsFireEvent] = useState<boolean>(false);

  useEffect(() => {
    if (!appliedToken) {
      setAppliedToken(paymentDetails.paymentMethods[0]?.token || '');
    }

    if (!selectedToken) {
      setSelectedToken(paymentDetails.paymentMethods[0]?.token || '');
    }
  }, [paymentDetails.paymentMethods]);

  const appliedTokenDetails = useMemo(() => {
    return find(
      [...paymentDetails.paymentMethods, ...paymentDetails.paymentTokens],
      tokenItem => tokenItem.token === appliedToken
    );
  }, [
    paymentDetails.paymentTokens,
    paymentDetails.paymentMethods,
    appliedToken,
  ]);

  useEffect(() => {
    if (!appliedToken || isFireEvent) {
      return;
    }

    actionTracking(
      TRIGGER_EVENTS.viewPayment,
      ModuleTracking.Order,
      'order_payment',
      {
        [DATA_LAYER_VARIABLES.paymentTransactionId]:
          paymentDetails?.paymentTransactionId,
        [DATA_LAYER_VARIABLES.totalPayment]:
          paymentDetails.amount + (appliedTokenDetails?.paymentFee || 0),
        [DATA_LAYER_VARIABLES.defaultPaymentMethod]: appliedToken,
      }
    );

    setIsFireEvent(true);
  }, [appliedToken, isFireEvent]);

  const onStepChanged = (step: PaymentStep) => setCurrentStep(step);

  const onMethodApplied = () => {
    setAppliedToken(selectedToken);
    onStepChanged(PaymentStep.Details);
  };

  const onSelectionClosed = () => {
    setSelectedToken(appliedToken);
    onStepChanged(PaymentStep.Details);
  };

  const onPaymentSubmitted = () => {
    actionTracking(
      TRIGGER_EVENTS.confirmPaymentMethod,
      ModuleTracking.Order,
      'order_payment',
      {
        [DATA_LAYER_VARIABLES.paymentTransactionId]:
          paymentDetails?.paymentTransactionId,
        [DATA_LAYER_VARIABLES.paymentMethod]: appliedTokenDetails?.token,
        [DATA_LAYER_VARIABLES.totalPayment]:
          paymentDetails.amount + (appliedTokenDetails?.paymentFee || 0),
        [DATA_LAYER_VARIABLES.paymentFee]: appliedTokenDetails?.paymentFee || 0,
      }
    );

    submitOrder({
      appliedTokenDetails,
      orderId: paymentDetails.orderId,
    });
  };

  if (currentStep === PaymentStep.Details) {
    return (
      !isPaymentFetching && (
        <PrimaryLayout
          title={t('payment:payment')}
          isMasterTitle
          footer={
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.PrimaryGradient}
              size={ButtonSize.Large}
              title={
                <div className="label label--md d-flex gap--8">
                  <p>{t('payment:payment')}</p>
                  <div className="dot" />
                  <p>
                    {`${numberFormatter(
                      paymentDetails.amount +
                        (appliedTokenDetails?.paymentFee || 0)
                    )} ${t(`currency.${paymentDetails.currency}`)}`}
                  </p>
                </div>
              }
              onClickHandled={onPaymentSubmitted}
              id="btn-submit-payment"
            />
          }
        >
          <PaymentDetails
            paymentDetails={paymentDetails}
            appliedTokenDetails={appliedTokenDetails}
            setAppliedToken={setAppliedToken}
            setSelectedToken={setSelectedToken}
            onStepChanged={onStepChanged}
          />
        </PrimaryLayout>
      )
    );
  }

  return (
    <QuaternaryLayout
      title={t('payment:paymentMethod')}
      closeIcon={<CloseIcon />}
      onClosed={onSelectionClosed}
      confirmLabel={t('choose')}
      onConfirmed={onMethodApplied}
    >
      <PaymentSelection
        paymentTransactionId={paymentDetails.paymentTransactionId}
        paymentTokens={paymentDetails.paymentTokens}
        paymentMethods={paymentDetails.paymentMethods}
        paymentAmount={paymentDetails.amount}
        selectedToken={selectedToken}
        setSelectedToken={setSelectedToken}
      />
    </QuaternaryLayout>
  );
};

export default Payment;
