import React from "react";
import StoreManager from "../appdata";
import { callAPISelfie2 } from "../api";
import Helpers, { screenSelfieState } from "../helpers";
import languageSDK from "../languages";
import TrueID from "../index";
import resource from "../resource";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import * as faceapi from 'face-api.js'; // Import face-api.js
import { FaceDetection } from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
const DRAW_BOUNDING_BOX = false;


export default class TrueIDSelfieScreen extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();
        this.pointRef = React.createRef(); // Tham chiếu cho điểm di chuyển
        this.animationRef = React.createRef();
        this.canvasBoundingBoxRef = React.createRef(); // Thêm canvas để vẽ bounding box

        this.state = {
            width: window.innerWidth - 20,
            selfieState: screenSelfieState.START, // Sử dụng selfieState thay cho status
            canvasWidth: 600,
            canvasHeight: 800,
            errorMessage: '',
            faceInOval: false,
            selfie1Base64: '', // Ảnh selfie đầu tiên
            selfie2Base64: '', // Ảnh selfie thứ hai khi mở rộng oval
            ovalExpanded: false, // Để theo dõi trạng thái mở rộng oval

            angle: 0, // Góc để tính toán vị trí của điểm
            radiusX: 100, // Bán kính X của ellipse
            radiusY: 140, // Bán kính Y của ellipse
            centerX: 150, // Tọa độ tâm X của ellipse
            centerY: 200, // Tọa độ tâm Y của ellipse
            borderColor: 'transparent'
        };
        this.faceStableTimeout = null;
    }

    // async componentDidMount() {
    //     StoreManager.appdata.thisSelfie = this;
    //     Helpers.loading.drawLoadingSpinner();

    //     // Dừng stream trước khi khởi động lại
    //     await StoreManager.stopStream();

    //     setTimeout(() => {
    //         StoreManager.loadSelfieMediaStream();
    //         this.setState({ selfieState: screenSelfieState.FACE_DETECT_DEFAULT })
    //     }, 3000);

    //     setTimeout(() => {
    //         try {
    //             if (StoreManager.appdata.media) {
    //                 this.videoRef.current.srcObject = StoreManager.appdata.media;
    //             }
    //             Helpers.loading.hideLoadingSpinner();
    //             this.setState({ borderColor: StoreManager.appdata.configEkyc.ovalColor })
    //         } catch (error) {
    //             console.log("error", error)
    //         }

    //     }, 4500);
    //     setTimeout(()=>{
    //         try {
    //             this.initFaceDetection();
    //         } catch (error) {
    //             console.log("error", error)
    //         }
    //     }, 5500)

    //     // Cập nhật width khi cửa sổ được resize
    //     window.addEventListener('resize', this.updateDimensions);
    //     if (this.pointRef.current) {
    //         this.movePoint(); // Bắt đầu di chuyển điểm
    //     }

    //     // Bắt đầu nhận diện khuôn mặt
    // }
    async componentDidMount() {
        StoreManager.appdata.thisSelfie = this;
        Helpers.loading.drawLoadingSpinner();
    
        // Dừng stream trước khi khởi động lại
        await StoreManager.stopStream();
    
        try {
            // Đợi loadSelfieMediaStream hoàn thành trước khi tiếp tục
            await StoreManager.loadSelfieMediaStream();
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_DEFAULT });
        } catch (error) {
            console.error("Failed to load media stream:", error);
            return; // Dừng lại nếu có lỗi khi load stream
        }
    
        // Kiểm tra nếu StoreManager.appdata.media có dữ liệu
        if (StoreManager.appdata.media) {
            try {
                this.videoRef.current.srcObject = StoreManager.appdata.media;
                Helpers.loading.hideLoadingSpinner();
                this.setState({ borderColor: StoreManager.appdata.configEkyc.ovalColor });
            } catch (error) {
                console.log("Error setting video source or hiding spinner:", error);
            }
        } else {
            console.error("Media stream not available");
        }
    
        // Tăng thời gian chờ trước khi khởi tạo face detection
        setTimeout(() => {
            try {
                // Khởi tạo face detection
                this.initFaceDetection();
            } catch (error) {
                console.log("Error initializing face detection:", error);
            }
        }, 7000); // Tăng thời gian chờ lên 7000ms để đảm bảo camera đã sẵn sàng
    
        // Cập nhật kích thước khi cửa sổ thay đổi
        window.addEventListener('resize', this.updateDimensions);
    
        // Bắt đầu di chuyển điểm nếu có pointRef
        if (this.pointRef.current) {
            this.movePoint();
        }
    }
    
    
    initFaceDetection = () => {
        console.log("initFaceDetection")

        if (!this.videoRef.current) {
            console.log("Video element is not available.");
            return;
        }

        const faceDetector = new FaceDetection({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
        });

        faceDetector.setOptions({
            model: 'short',
            minDetectionConfidence: 0.5,
        });

        faceDetector.onResults(this.onFaceDetectionResults);

        this.camera = new Camera(this.videoRef.current, {
            onFrame: async () => {
                if (this.videoRef.current && this.videoRef.current.srcObject instanceof MediaStream) {
                    await faceDetector.send({ image: this.videoRef.current });
                } else {
                    console.error("No valid media stream available for Mediapipe.");
                }
                // await faceDetector.send({ image: this.videoRef.current });
            },
            facingMode: 'user'
        });
        console.log("his.camera", this.camera)
        console.log("this.videoRef.current", this.videoRef.current)
        setTimeout(() => {
            this.camera.start();
        }, 2000);
    
       
    };
    
    
        onFaceDetectionResults = (results) => {
            let { faceInOval, selfieState } = this.state;
            if (selfieState.value == screenSelfieState.CALLING_API.value || selfieState.value == screenSelfieState.CALL_API_FAILED.value ) {
                return
            }

            try {
                const canvas = this.canvasBoundingBoxRef.current;
                const context = canvas.getContext("2d");
        
                // Xóa canvas trước khi vẽ
                context.clearRect(0, 0, canvas.width, canvas.height);
        
                if (this.videoRef.current && results && results.detections.length > 0) {
                    const detection = results.detections[0];
                    const { width: videoWidth, height: videoHeight } = this.videoRef.current.getBoundingClientRect();
                    const boundingBox = detection.boundingBox;
        
                    // Tính toán tọa độ và kích thước bounding box cho video lật
                    const boxX = videoWidth - (boundingBox.xCenter * videoWidth + (boundingBox.width * videoWidth) / 2);
                    const boxY = boundingBox.yCenter * videoHeight - (boundingBox.height * videoHeight) / 2;
                    const boxWidth = boundingBox.width * videoWidth;
                    const boxHeight = boundingBox.height * videoHeight;
        
                    // Đặt lại kích thước của canvas cho phù hợp với video
                    canvas.width = videoWidth;
                    canvas.height = videoHeight;
        
                    // Vẽ bounding box
                    context.strokeStyle =  DRAW_BOUNDING_BOX ? "red" : "transparent";
                    context.lineWidth = 2;
                    context.strokeRect(boxX, boxY, boxWidth, boxHeight);
        
                    const faceInOval = this.isFaceInOval(boundingBox, videoWidth, videoHeight);
                    const faceToOvalRatioValid = this.checkFaceToOvalRatio(boundingBox, videoWidth);
        
                    if (faceInOval && faceToOvalRatioValid) {
                        if (!this.state.faceInOval) {
                            this.setState({ faceInOval: true });
                            this.showKeepStableMessage();
                        }
                    } else {
                        this.setState({ faceInOval: false });
                        clearTimeout(this.faceStableTimeout);
                    }
                }
            } catch (error) {
                console.log("Error drawing bounding box:", error);
            }
        };
        


    movePoint = () => {
        let angle = 0; // Bắt đầu từ góc 0
        const numberOfPoints = 40; // Số lượng điểm trên quỹ đạo oval
        const pointsPath1 = []; // Mảng để chứa các điểm của đường thứ nhất
        const pointsPath2 = []; // Mảng để chứa các điểm của đường thứ hai

        // Tạo và thêm các điểm vào DOM cho đường thứ nhất
        for (let i = 0; i < numberOfPoints; i++) {
            const point1 = document.createElement('div');
            point1.style.position = 'absolute';
            point1.style.width = '4px';
            point1.style.height = '4px';
            point1.style.zIndex = 1000 + i;
            point1.style.backgroundColor = StoreManager.appdata.configEkyc.ovalColor;
            point1.style.borderRadius = '50%';
            point1.style.display = 'none'; // Ẩn điểm ban đầu
            this.animationRef.current.appendChild(point1);
            pointsPath1.push(point1); // Lưu điểm vào mảng để cập nhật vị trí sau
        }

        // Tạo và thêm các điểm vào DOM cho đường thứ hai
        for (let i = 0; i < numberOfPoints; i++) {
            const point2 = document.createElement('div');
            point2.style.position = 'absolute';
            point2.style.width = '4px';
            point2.style.height = '4px';
            point2.style.zIndex = 1000 + i;
            point2.style.backgroundColor = StoreManager.appdata.configEkyc.ovalColor;
            point2.style.borderRadius = '50%';
            point2.style.display = 'none'; // Ẩn điểm ban đầu
            this.animationRef.current.appendChild(point2);
            pointsPath2.push(point2); // Lưu điểm vào mảng để cập nhật vị trí sau
        }

        this.pointMovementInterval = setInterval(() => {
            const { ovalExpanded, selfieState } = this.state;

            // Kiểm tra nếu selfieState.value > 0 thì hiển thị các điểm
            if (selfieState.value == 7) {
                pointsPath1.forEach(point => point.style.display = 'block');
                pointsPath2.forEach(point => point.style.display = 'block');
            } else {
                // Ẩn các điểm nếu selfieState.value <= 0
                pointsPath1.forEach(point => point.style.display = 'none');
                pointsPath2.forEach(point => point.style.display = 'none');
                return; // Không cập nhật vị trí khi không cần hiển thị
            }

            angle += 0.05; // Tăng góc để di chuyển

            // Lấy chiều rộng và chiều cao của video để tính toán
            const videoWidth = this.animationRef.current.offsetWidth;
            const videoHeight = this.animationRef.current.offsetHeight;

            // Tâm của ellipse (giữa phần tử)
            const centerX = videoWidth / 2 - 2;
            const centerY = videoHeight / 2 - 2;

            // Bán kính của ellipse, giảm xuống 0.9 lần
   
                const radiusX = videoWidth * (ovalExpanded ? 0.45 : 0.35) * 0.9;
                const radiusY = videoHeight * (ovalExpanded ? 0.45 : 0.35) * 0.9;

            // Cập nhật vị trí cho từng điểm trên đường thứ nhất
            pointsPath1.forEach((point, index) => {
                const pointAngle = angle - index * 0.03; // Giảm khoảng cách góc giữa các điểm để sát nhau hơn
                const x = centerX + radiusX * Math.cos(pointAngle);
                const y = centerY + radiusY * Math.sin(pointAngle);

                point.style.left = `${x}px`;
                point.style.top = `${y}px`;
            });

            // Cập nhật vị trí cho từng điểm trên đường thứ hai, với góc đối diện (180 độ)
            pointsPath2.forEach((point, index) => {
                const pointAngle = angle - index * 0.03 + Math.PI; // Thêm 180 độ để đối diện
                const x = centerX + radiusX * Math.cos(pointAngle);
                const y = centerY + radiusY * Math.sin(pointAngle);

                point.style.left = `${x}px`;
                point.style.top = `${y}px`;
            });
        }, 20); // Cập nhật mỗi 20ms để tạo chuyển động mượt
    }






    componentWillUnmount() {
        clearInterval(this.pointMovementInterval); // Dừng khi component bị hủy

        window.removeEventListener('resize', this.updateDimensions);

        if (document.getElementById('loadingCanvas')) {
            Helpers.loading.hideLoadingSpinner();
        }
    }

    updateDimensions = () => {
        const height = window.innerWidth - 20;
        const width = height * 0.75;
        console.log("updateDimensions", height, width)
        this.setState({
            width: width,
            canvasWidth: width,
            canvasHeight: height,
        });
    };


    checkFaceToOvalRatio = (boundingBox, videoWidth) => {
        const {selfieState} = this.state
        var faceWidth = boundingBox.width * videoWidth;
        if(StoreManager.appdata.isMobile){
            faceWidth = faceWidth * 0.6
        }
        // const ovalWidth = videoWidth * 0.3 * (this.state.ovalExpanded ? 1.4 : 1);
        const ovalWidth = videoWidth * 0.35 * (this.state.ovalExpanded ? 1.4 : 1); // Adjust for larger oval

        const faceToOvalRatio = faceWidth / ovalWidth;
        console.log("videoWidth", videoWidth)
        console.log("ovalWidth", ovalWidth)
        console.log("faceWidth", faceWidth)


        console.log("faceToOvalRatio", faceToOvalRatio)
        if (faceToOvalRatio < 1.0) {
            if (selfieState.value != screenSelfieState.CALLING_API && selfieState.value != screenSelfieState.CALL_API_FAILED ) {
                this.setState({ selfieState: screenSelfieState.FACE_DETECT_SMALL });
            }

            return false;
        } else if (faceToOvalRatio > 1.4) {
            if (selfieState.value != screenSelfieState.CALLING_API && selfieState.value != screenSelfieState.CALL_API_FAILED ) {
                this.setState({ selfieState: screenSelfieState.FACE_DETECT_LARGE });
            }
            return false;
        } else {
            if (selfieState.value != screenSelfieState.CALLING_API && selfieState.value != screenSelfieState.CALL_API_FAILED ) {
                this.setState({ selfieState: screenSelfieState.FACE_DETECT_OK });
            }
            return true;
        }
    };

    isFaceInOval = (boundingBox, width, height) => {
        const { ovalExpanded } = this.state;
        const ovalScale = ovalExpanded ? 1.4 : 1;
        // const ovalWidth = width * 0.5 * ovalScale;
        // const ovalHeight = height * 0.7 * ovalScale;
        const ovalWidth = width * 0.7 * ovalScale; // Increase from 0.5 to 0.7 for larger width
        const ovalHeight = height * 0.9 * ovalScale; // Increase from 0.7 to 0.9 for larger height

        const ovalLeft = (width - ovalWidth) / 2;
        const ovalRight = ovalLeft + ovalWidth;
        const ovalTop = (height - ovalHeight) / 2;
        const ovalBottom = ovalTop + ovalHeight;

        const faceCenterX = boundingBox.xCenter * width;
        const faceCenterY = boundingBox.yCenter * height;

        return faceCenterX >= ovalLeft && faceCenterX <= ovalRight &&
               faceCenterY >= ovalTop && faceCenterY <= ovalBottom;
    };

    showKeepStableMessage = () => {
        console.log("showKeepStableMessage");
        this.faceStableTimeout = setTimeout(() => {
            this.capture();
        }, 1500);
    };

    capture = () => {
        let { faceInOval, selfieState } = this.state;
        if (!faceInOval) {
            return;
        }
        if (selfieState.value != screenSelfieState.FACE_DETECT_OK.value) {
            return
        }

        const canvas = this.canvasRef.current;
        const video = this.videoRef.current;

        if (canvas && video) {
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;

            const currentRatio = videoWidth / videoHeight;
            const targetRatio = 3 / 4;

            let targetWidth, targetHeight;
            let offsetX = 0, offsetY = 0;

            if (currentRatio > targetRatio) {
                targetHeight = videoHeight;
                targetWidth = videoHeight * targetRatio;
                offsetX = (videoWidth - targetWidth) / 2;
            } else {
                targetWidth = videoWidth;
                targetHeight = videoWidth / targetRatio;
                offsetY = (videoHeight - targetHeight) / 2;
            }

            canvas.width = targetWidth;
            canvas.height = targetHeight;

            const context = canvas.getContext('2d');
            context.drawImage(
                video,
                offsetX, offsetY, targetWidth, targetHeight,
                0, 0, targetWidth, targetHeight
            );

            const imageSrc = canvas.toDataURL('image/jpeg');

            if (!this.state.ovalExpanded) {
                // Lưu ảnh đầu tiên
                this.setState({
                    selfie1Base64: imageSrc.replace('data:image/jpeg;base64,', ''),
                    ovalExpanded: true, // Mở rộng oval cho ảnh thứ hai
                    faceInOval: false,
                    selfieState: screenSelfieState.FACE_DETECT_DEFAULT, // Đặt lại trạng thái
                });

                // Mở rộng kích thước oval cho ảnh thứ hai
                this.setState({
                    canvasWidth: this.state.canvasWidth * 1.3,
                    canvasHeight: this.state.canvasHeight * 1.3
                });

            } else {
                // Lưu ảnh thứ hai

                this.setState({
                    selfie2Base64: imageSrc.replace('data:image/jpeg;base64,', '')
                }, () => {
                    console.log("this.state.selfieState", this.state.selfieState)
                    if (this.state.selfieState.value > 0) {
                        Helpers.loading.drawLoadingSpinner();
                        callAPISelfie2(this.state.selfie1Base64, this.state.selfie2Base64);
                        this.setState({
                            selfieState: screenSelfieState.CALLING_API // Cập nhật trạng thái
                        })
                    }


                });
            }
        }
    };

    updateSuccess = () => {
        // Dừng camera nếu đang hoạt động
        if (this.camera) {
            this.camera.stop();
            this.camera = null; // Giải phóng camera instance
        }

        this.setState({ selfieState: screenSelfieState.UPDATE_SUCCESS }, () => {
            StoreManager.appdata.thisApp.nextStep();
        });
    };

    updateError = () => {
        this.setState({ selfieState: screenSelfieState.CALL_API_FAILED });
    };

    actionButton = () => {
        // Đặt lại trạng thái và ovalExpanded về false
        this.setState({
            canvasWidth: 600,
            canvasHeight: 800,
            selfieState: screenSelfieState.START, ovalExpanded: false,
            faceInOval: false
        })
        // this.setState({ });

        // Đặt lại kích thước canvas theo kích thước mới
        // this.updateDimensions();

        // Sau 1.5 giây, chuyển trạng thái về FACE_DETECT_DEFAULT và bắt đầu lại quá trình nhận diện khuôn mặt
        setTimeout(() => {
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_DEFAULT });
        }, 1500);
    };


    closeScreen = () => {
        // Stop the camera if it's currently active
        if (this.camera) {
            this.camera.stop();
            this.camera = null; // Optional: Clear the camera instance
        }
        
        // Call the callback and close the SDK
        StoreManager.appdata.callback({ code: 0, errorMessage: "User Close SDK" });
        TrueID.close();
    };
    

    render() {
        const { width, selfieState, canvasWidth, canvasHeight, faceInOval, ovalExpanded, borderColor } = this.state;
        let configEkyc = StoreManager.appdata.configEkyc;
        const subTitle = selfieState.message; // Lấy trực tiếp message từ selfieState để hiển thị subTitle
        const buttonTitle = selfieState === screenSelfieState.CALL_API_FAILED ? "Thử lại" : `${languageSDK.language.trueID_take_photo}`;

        // Kích thước oval thay đổi dựa trên state 'ovalExpanded'
        // const clipPathSize = ovalExpanded ? 'ellipse(35% 35% at 50% 50%)' : 'ellipse(25% 25% at 50% 50%)';
        const clipPathSize = ovalExpanded ? 'ellipse(45% 45% at 50% 50%)' : 'ellipse(35% 35% at 50% 50%)';

        return (
            <PerfectScrollbar>
                <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', position: 'relative', padding: '10px' }}>
                <canvas
                        ref={this.canvasBoundingBoxRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            pointerEvents: 'none',
                        }}
                        width={canvasWidth}
                        height={canvasHeight}
                    />
                    <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <a onClick={this.closeScreen} style={{ fontSize: '16px', color: configEkyc.closeColor, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                            X
                        </a>
                    </div>

                    <div style={{ display: selfieState === screenSelfieState.CALLING_API ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
                        <canvas id="loadingCanvas" width="50" height="50"></canvas>
                    </div>

                    <p style={{ color: configEkyc.titleColor, fontSize: 20, marginBottom: '20px', fontWeight: 600 }}>{subTitle}</p>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>

                        <div
                            style={{
                                width: width,
                                height: width,
                                maxWidth: 300,
                                maxHeight: 400,
                                overflow: 'hidden',
                                position: 'relative'
                            }}>
                            <div ref={this.animationRef} style={{
                                width: 'calc(100%)',
                                height: 'calc(100%)',
                                position: 'absolute',
                                backgroundColor: 'transparent'
                            }}></div>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                top: 0,
                                boxSizing: 'border-box',
                                display: 'inline-block',
                                backgroundColor: borderColor,
                                clipPath: clipPathSize,  // Thay đổi clipPath tùy theo trạng thái oval
                            }}></div>
                            <video ref={this.videoRef} autoPlay playsInline style={{
                                width: 'calc(100% - 12px)',
                                height: 'calc(100% - 12px)',
                                objectFit: 'cover',
                                transform: 'scaleX(-1)',
                                clipPath: clipPathSize,
                                position: 'absolute',
                                top: '6px',
                                left: '6px',
                            }}></video>
                            <div ref={this.pointRef} style={{
                                position: 'absolute',
                                width: '4px',
                                height: '4px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                            }}></div>

                        </div>
                    </div>

                    <canvas ref={this.canvasRef} style={{ display: 'none' }} width={canvasWidth} height={canvasHeight}></canvas>

                    {/* Hiển thị nút khi ở trạng thái CALL_API_FAILED */}
                    {selfieState === screenSelfieState.CALL_API_FAILED && (
                        <button onClick={this.actionButton} style={{ marginTop: '40px', fontSize: '18px', padding: '10px 20px', borderRadius: '5px', backgroundColor: configEkyc.buttonCaptureColor, color: configEkyc.titleButtonCaptureColor, border: 'none', cursor: 'pointer' }}>
                            {buttonTitle}
                        </button>
                    )}
                </div>
            </PerfectScrollbar>
        );
    }

}
