import classNames from 'classnames';

import { SecondaryLayoutProps } from 'src/components/Layout/SecondaryLayout/SecondaryLayout.type';

const SecondaryLayout = ({
  logo,
  image,
  title,
  description,
  footer,
  className = '',
}: SecondaryLayoutProps) => {
  return (
    <div
      className={classNames('secondary-layout', {
        [className]: !!className,
      })}
    >
      {logo && <div className="header">{logo}</div>}

      <div className="body">
        <div className="body__image">{image}</div>
        <div className="body-content">
          <p className="body-content__title heading heading--xs">{title}</p>
          <p className="body-content__description paragraph paragraph--sm">
            {description}
          </p>
        </div>
      </div>

      <div className="footer">{footer}</div>
    </div>
  );
};

export default SecondaryLayout;
