import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EkycStatus, PackageType, PromotionStatus } from 'src/components/Order';
import ReceiptItem from 'src/components/Order/CheckoutDetails/ReceiptDetails/ReceiptItem';
import ServiceFeeDescription from 'src/components/Order/CheckoutDetails/ReceiptDetails/ServiceFeeDescription';
import { numberFormatter } from 'src/utils';

import { ReceiptDetailsProps } from 'src/components/Order/CheckoutDetails/ReceiptDetails/ReceiptDetails.type';

const ReceiptDetails = ({
  orderValue,
  downPaymentValue,
  payLaterValue,
  serviceFee,
  tempValue,
  currency,
  appliedPackageDetails,
  appliedPromotions,
  userEkycStatus,
}: ReceiptDetailsProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onDescriptionOpened = () => setOpen(true);

  const onDescriptionClosed = () => setOpen(false);

  return (
    <section className="receipt-details">
      <div className="receipt-details-wrapper">
        <p className="label label--sm mb--8 text-align-left receipt-details-wrapper__value">
          {t('order:checkout.receiptDetails.receipt')}
        </p>

        <ReceiptItem
          label={t('order:checkout.receiptDetails.orderValue')}
          value={orderValue}
          currency={currency}
        />

        {appliedPromotions.map(appliedPromotion => {
          if (appliedPromotion.status !== PromotionStatus.Available) {
            return;
          }

          return (
            <div
              key={appliedPromotion.code}
              className="receipt-details-wrapper__item receipt-details-wrapper__promotion"
            >
              <p className="paragraph paragraph--sm">{appliedPromotion.code}</p>
              <p className="paragraph paragraph--sm">
                {`- ${numberFormatter(appliedPromotion.amount)} `}
                <span className="paragraph paragraph--xs">
                  {t(`currency.${currency}`)}
                </span>
              </p>
            </div>
          );
        })}
      </div>

      <div className="receipt-details-wrapper">
        {userEkycStatus === EkycStatus.Approved &&
          (appliedPackageDetails?.type === PackageType.Bnpl ||
            appliedPackageDetails?.type === PackageType.BnplPlus) && (
            <>
              <ReceiptItem
                label={t('order:checkout.receiptDetails.downPayment')}
                value={downPaymentValue}
                currency={currency}
              />
              <ReceiptItem
                label={t('order:checkout.receiptDetails.payLater')}
                value={payLaterValue}
                currency={currency}
              />
            </>
          )}

        <ReceiptItem
          label={t('order:checkout.receiptDetails.serviceFee')}
          value={serviceFee}
          currency={currency}
          showDescription
          openDrawer={open}
          onDrawerOpened={onDescriptionOpened}
          onDrawerClosed={onDescriptionClosed}
          description={
            <ServiceFeeDescription onDrawerClosed={onDescriptionClosed} />
          }
        />
      </div>

      <div className="receipt-details-wrapper">
        <ReceiptItem
          label={t('order:checkout.receiptDetails.tempValue')}
          value={tempValue}
          currency={currency}
        />
      </div>
    </section>
  );
};

export default ReceiptDetails;
