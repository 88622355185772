// COLOR IN THE SYSTEM
export const COLOR_PRIMARY_500: string = '#877bf3';
export const COLOR_PRIMARY_600: string = '#6d5ff0';

export const COLOR_DUST_RED_400: string = '#ff7875';
export const COLOR_DUST_RED_500: string = '#ff4d4f';
export const COLOR_DUST_RED_600: string = '#f5222d';

export const COLOR_GRAY_100: string = '#fff';
export const COLOR_GRAY_200: string = '#f8f8fa';
export const COLOR_GRAY_300: string = '#e1e1e5';
export const COLOR_GRAY_400: string = '#d5d4d9';
export const COLOR_GRAY_500: string = '#d6d5d9';
export const COLOR_GRAY_600: string = '#bcbbbf';
export const COLOR_GRAY_700: string = '#8a898c';
export const COLOR_GRAY_800: string = '#575759';
export const COLOR_GRAY_900: string = '#323233';

export const COLOR_EASTERN_BLUE: string = '#00d6ca';

export const COLOR_PRIMARY_GRADIENT: string = `linear-gradient(90deg, ${COLOR_EASTERN_BLUE} 0%, ${COLOR_PRIMARY_600} 100%)`;
