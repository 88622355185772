import { Route, Routes as RouteWrapper } from 'react-router-dom';

import { MainLayout } from 'src/components/Layout';
import { Login, Register, InputPhoneNumber, OtpModule } from 'src/pages/Auth';
import { Checkout, ExceedLimit, Error as OrderError } from 'src/pages/Checkout';
import DownloadApp from 'src/pages/DownloadApp';
import {
  Failed,
  IdentityPromotion,
  Initiate,
  Pending,
  Processing,
  Starting,
  Success,
  TrueID,
} from 'src/pages/Ekyc';
import Error from 'src/pages/Error';
import OrderStatus from 'src/pages/OrderStatus';
import { PaymentSubmission, VirtualAccount } from 'src/pages/Payment';
import StaticQr from 'src/pages/StaticQr';
import { RefundPage, RefundSuccess, RefundError } from 'src/pages/Refund';
import { AuthRoute, EkycRoute, ProtectedRoute, routeStrings } from 'src/routes';

const Routes = () => {
  return (
    <RouteWrapper>
      <Route element={<MainLayout />}>
        <Route path={routeStrings.defaultPath} element={<></>} />

        <Route path={`${routeStrings.auth.defaultPath}/:referenceId`}>
          <Route index element={<InputPhoneNumber />} />
          <Route
            path={routeStrings.auth.otpModule}
            element={
              <AuthRoute>
                <OtpModule />
              </AuthRoute>
            }
          />
          <Route
            path={routeStrings.auth.register}
            element={
              <AuthRoute>
                <Register />
              </AuthRoute>
            }
          />
          <Route
            path={routeStrings.auth.login}
            element={
              <AuthRoute>
                <Login />
              </AuthRoute>
            }
          />
        </Route>

        <Route path={`${routeStrings.ekyc.defaultPath}/:referenceId`}>
          <Route
            index
            element={
              <EkycRoute skipPopAction>
                <Initiate />
              </EkycRoute>
            }
          />
          <Route
            path={routeStrings.ekyc.starting}
            element={
              <EkycRoute>
                <Starting />
              </EkycRoute>
            }
          />
          <Route
            path={routeStrings.ekyc.trueID}
            element={
              <EkycRoute>
                <TrueID />
              </EkycRoute>
            }
          />
          <Route
            path={routeStrings.ekyc.identityPromotion}
            element={
              <EkycRoute>
                <IdentityPromotion />
              </EkycRoute>
            }
          />
          <Route path={routeStrings.ekyc.processing} element={<Processing />} />
          <Route
            path={routeStrings.ekyc.success}
            element={
              <EkycRoute>
                <Success />
              </EkycRoute>
            }
          />
          <Route
            path={routeStrings.ekyc.failed}
            element={
              <EkycRoute>
                <Failed />
              </EkycRoute>
            }
          />
          <Route
            path={routeStrings.ekyc.pending}
            element={
              <EkycRoute>
                <Pending />
              </EkycRoute>
            }
          />
        </Route>

        <Route path={`${routeStrings.checkout.defaultPath}/:sessionId`}>
          <Route
            index
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path={routeStrings.checkout.exceedLimit}
            element={
              <ProtectedRoute>
                <ExceedLimit />
              </ProtectedRoute>
            }
          />
          <Route
            path={routeStrings.checkout.error}
            element={
              <ProtectedRoute>
                <OrderError />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path={`${routeStrings.payment.defaultPath}/:paymentTransactionId`}
        >
          <Route
            index
            element={
              <ProtectedRoute>
                <PaymentSubmission />
              </ProtectedRoute>
            }
          />
          <Route
            path={routeStrings.payment.virtualAccount}
            element={
              <ProtectedRoute>
                <VirtualAccount />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path={`${routeStrings.staticQr}/:qrId`}
          element={
            <ProtectedRoute>
              <StaticQr />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${routeStrings.orderStatus}/:orderId`}
          element={
            <ProtectedRoute>
              <OrderStatus />
            </ProtectedRoute>
          }
        />

        <Route path={`${routeStrings.refund.defaultPath}/:refundId`}>
          <Route
            index
            element={
              <ProtectedRoute>
                <RefundPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={routeStrings.refund.success}
            element={
              <ProtectedRoute>
                <RefundSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path={routeStrings.refund.error}
            element={
              <ProtectedRoute>
                <RefundError />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path={routeStrings.error} element={<Error />} />

        <Route path={routeStrings.downloadApp} element={<DownloadApp />} />
      </Route>
    </RouteWrapper>
  );
};

export default Routes;
