import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultEkycValues } from 'src/utils';

import { EkycResultStatus, ResponseStatus } from 'src/types';

const useEvaluateEkyc = () => {
  const navigate = useNavigate();
  const { referenceId } = useParams();

  const { mutate: evaluateEkyc } = useMutation({
    mutationFn: async (variables: { ekycId: string; sourceType: string }) => {
      try {
        const response = await axios.post(
          apiStrings.ekyc.evaluate,
          {
            ...generateDefaultEkycValues(),
            ekycId: variables.ekycId,
          },
          {
            timeout: 180000,
          }
        );
        return response.data || '';
      } catch (error) {
        throw error;
      }
    },

    onSuccess: response => {
      // Business Error Case - EKYC_NOT_EXISTED
      if (
        response.status === ResponseStatus.BusinessError ||
        response.data.ekycStatus === EkycResultStatus.ManualReject
      ) {
        return navigate(
          `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.failed}`,
          {
            state: {
              callbackUrl: response.data.callbackUrl || '',
            },
          }
        );
      }

      navigate(
        response.data.ekycStatus === EkycResultStatus.AutoApproved
          ? `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.success}`
          : `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.pending}`,
        {
          state: {
            sourceType: response.data.sourceType,
            callbackUrl: response.data.callbackUrl || '',
          },
        }
      );
    },

    onError: (error: any, variables) => {
      if (error?.response && error?.response?.status === 401) {
        return navigate(
          `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${variables.sourceType.toLowerCase()}`,
          {
            replace: true,
          }
        );
      }

      const response = error?.response?.data;
      navigate(routeStrings.error, {
        state: {
          callbackUrl: response?.data?.callbackUrl || '',
        },
      });
    },
  });

  return { evaluateEkyc };
};

export default useEvaluateEkyc;
