import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import { AuthenStep, ResponseStatus } from 'src/types';

const useResetPin = () => {
  const { referenceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { mutate: resetPin, isLoading } = useMutation({
    mutationFn: async (variables: { authRequestId: string }) => {
      try {
        const response = await axios.post(apiStrings.auth.resetPin, {
          ...generateDefaultAuthValues(variables.authRequestId || ''),
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: response => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === ResponseStatus.AuthenRequestExpired) {
          const paymentChannel = state?.paymentChannel
            ? `&paymentChannel=${state?.paymentChannel}`
            : '';
          const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';

          return navigate(
            `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
            {
              state,
              replace: true,
            }
          );
        }
      }

      if (response.data.nextStep === AuthenStep.Otp) {
        navigate(
          `${routeStrings.auth.defaultPath}/${referenceId}/${routeStrings.auth.otpModule}`,
          {
            state,
          }
        );
      }
    },
    onError: () => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      });
    },
  });

  return { resetPin, isPinResetting: isLoading };
};

export default useResetPin;
