import classNames from 'classnames';
import { camelCase, find, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BankIcon } from 'src/assets/images/svg/bank.svg';
import { ReactComponent as CircleQuestion } from 'src/assets/images/svg/circle-question.svg';
import Button from 'src/components/Button';
import { Drawer } from 'src/components/Drawer';
import {
  PaymentDetailsProps,
  PaymentMethod,
  PaymentMethodEnum,
  PaymentStep,
  Token,
} from 'src/components/Payment';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { actionTracking, ModuleTracking, numberFormatter } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

const PaymentDetails = ({
  paymentDetails,
  appliedTokenDetails,
  setAppliedToken,
  setSelectedToken,
  onStepChanged,
}: PaymentDetailsProps) => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const firstPaymentMethod = useMemo(() => {
    return paymentDetails.paymentMethods.length > 0
      ? paymentDetails.paymentMethods[0]
      : undefined;
  }, [paymentDetails.paymentMethods]);

  const secondPaymentMethod = useMemo(() => {
    return paymentDetails.paymentMethods.length > 0
      ? paymentDetails.paymentMethods[1]
      : undefined;
  }, [paymentDetails.paymentMethods]);

  const uniqSuggestions = (
    newSuggestions: (PaymentMethod | Token | undefined)[]
  ) => {
    return uniqBy(
      newSuggestions.filter(tokenItem => !!tokenItem),
      'token'
    );
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      return;
    }

    const target = find(
      [...paymentDetails.paymentTokens, ...paymentDetails.paymentMethods],
      tokenItem => tokenItem?.token === appliedTokenDetails?.token
    );

    setSuggestions(
      uniqSuggestions([target, firstPaymentMethod, secondPaymentMethod])
    );
  }, [paymentDetails.paymentMethods, paymentDetails.paymentTokens]);

  const onDrawerOpened = () => setOpenDrawer(true);

  const onDrawerClosed = () => setOpenDrawer(false);

  const onSuggestionSelected = (newToken: string) => {
    const target = find(suggestions, item => item.token === newToken);

    actionTracking(
      TRIGGER_EVENTS.selectPaymentMethod,
      ModuleTracking.Order,
      'order_payment',
      {
        [DATA_LAYER_VARIABLES.paymentTransactionId]:
          paymentDetails.paymentTransactionId,
        [DATA_LAYER_VARIABLES.paymentMethod]: newToken,
        [DATA_LAYER_VARIABLES.totalPayment]:
          paymentDetails.amount + (target?.paymentFee || 0),
        [DATA_LAYER_VARIABLES.paymentFee]: target?.paymentFee,
      }
    );

    setAppliedToken(newToken);
    setSelectedToken(newToken);
  };

  return (
    <div className="payment-details">
      <section className="payment-details-control">
        <div className="payment-details-control-header">
          <h5 className="label label--sm">{t('payment:paymentMethod')}</h5>

          <Button
            type={ButtonType.Text}
            colorType={ButtonColorType.Purple}
            title={t('payment:viewAll')}
            className="payment-details-control-header__button"
            onClickHandled={() => onStepChanged(PaymentStep.Selection)}
            disable={
              paymentDetails.paymentTokens.length < 1 &&
              paymentDetails.paymentMethods.length < 1
            }
          />
        </div>

        <div className="payment-details-control-list">
          {suggestions.map((suggestion, index) => (
            <div
              key={suggestion?.token || index}
              className={classNames('payment-details-control-list-item', {
                'payment-details-control-list-item--selected':
                  suggestion?.token === appliedTokenDetails?.token,
              })}
              onClick={() => onSuggestionSelected(suggestion?.token || '')}
            >
              <div className="payment-details-control-list-item__header">
                {!suggestion?.cardNumber && suggestion?.paymentLogo}

                {!!suggestion?.cardNumber && !!suggestion?.bankLogoUrl && (
                  <img
                    src={suggestion?.bankLogoUrl || ''}
                    alt={suggestion?.bankCode || ''}
                    width={20}
                    height={17}
                  />
                )}

                {!!suggestion?.cardNumber && !suggestion?.bankLogoUrl && (
                  <BankIcon />
                )}

                {suggestion?.method === PaymentMethodEnum.VirtualAccount && (
                  <span className="label label--xxs payment-details-control-list-item__header--suggested">
                    {t('payment:suggestion')}
                  </span>
                )}
              </div>

              <p className="label label--xs">
                {!suggestion?.cardNumber
                  ? t(`payment:method.${camelCase(suggestion?.method)}`)
                  : suggestion.cardNumber}
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="payment-details-content">
        <h5 className="label label--sm payment-details-content__header">
          {t('payment:transactionDetails')}
        </h5>

        <div className="payment-details-content-item">
          <p className="paragraph paragraph--sm">{t('payment:payingAmount')}</p>
          <p className="paragraph paragraph--sm payment-details-content-item--primary">
            {numberFormatter(paymentDetails.amount)}
            <span>{t(`currency.${paymentDetails.currency}`)}</span>
          </p>
        </div>

        <div className="payment-details-content-item payment-details-content-item--i-marg">
          <p className="paragraph paragraph--sm">
            {t('payment:serviceFee')}
            <CircleQuestion onClick={onDrawerOpened} />
          </p>
          <p className="paragraph paragraph--sm payment-details-content-item--primary">
            {numberFormatter(appliedTokenDetails?.paymentFee || 0)}
            <span>{t(`currency.${paymentDetails.currency}`)}</span>
          </p>
        </div>

        <div className="payment-details-content-item">
          <p className="paragraph paragraph--sm">{t('payment:totalAmount')}</p>
          <h4 className="heading heading--xs payment-details-content-item--primary">
            {numberFormatter(
              paymentDetails.amount + (appliedTokenDetails?.paymentFee || 0)
            )}
            <span>{t(`currency.${paymentDetails.currency}`)}</span>
          </h4>
        </div>
      </section>

      <Drawer
        open={openDrawer}
        title={t('payment:serviceFee')}
        onClose={onDrawerClosed}
      >
        <div className="payment-drawer">
          <div className="payment-drawer__item">
            <div className="payment-drawer__item--noted" />
            <p className="paragraph paragraph--sm">
              {t('payment:firstServiceFeeContent')}
            </p>
          </div>

          <div className="payment-drawer__item">
            <div className="payment-drawer__item--noted" />
            <p className="paragraph paragraph--sm">
              {t('payment:secondServiceFeeContent')}
            </p>
          </div>

          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            title={t('payment:understand')}
            size={ButtonSize.Large}
            onClickHandled={onDrawerClosed}
            className="w--100 mt--24"
          />
        </div>
      </Drawer>
    </div>
  );
};

export default PaymentDetails;
