import { useTranslation } from 'react-i18next';

import { CustomModal } from 'src/components/Modal';
import DecreeForm from 'src/components/Auth/DecreeModule/DecreeForm';

import { DecreeModalProps } from 'src/components/Auth/DecreeModule/index.type';

const DecreeModal = ({ open, onClose, onSubmit }: DecreeModalProps) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      onCancel={onClose}
      title={t('auth:decree.decreeTitle')}
      width={375}
      className="decree"
      titleClassName="text-align-left"
    >
      <DecreeForm onSubmit={onSubmit} />
    </CustomModal>
  );
};

export default DecreeModal;
