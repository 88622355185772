import TertiaryBackground from 'src/assets/images/png/tertiary-bg.png';
import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';

import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { TertiaryLayoutProps } from 'src/components/Layout/TertiaryLayout/TertiaryLayout.type';

const TertiaryLayout = ({
  children,
  onConfirmed,
  onCanceled,
  confirmButtonLabel = '',
  cancelButtonLabel = '',
}: TertiaryLayoutProps) => {
  return (
    <section className="tertiary-layout">
      <header className="header">
        <Logo />
      </header>

      <main className="body">
        <img src={TertiaryBackground} alt="" className="body__img" />

        <section className="body__content">{children}</section>
      </main>

      <footer className="footer">
        {confirmButtonLabel && onConfirmed && (
          <Button
            title={confirmButtonLabel}
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            size={ButtonSize.Large}
            onClickHandled={onConfirmed}
          />
        )}
        {cancelButtonLabel && onCanceled && (
          <Button
            title={cancelButtonLabel}
            type={ButtonType.Default}
            colorType={ButtonColorType.Gray}
            size={ButtonSize.Large}
            onClickHandled={onCanceled}
          />
        )}
      </footer>
    </section>
  );
};

export default TertiaryLayout;
