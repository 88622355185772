import { Tooltip } from 'antd';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from 'src/assets/images/svg/copy.svg';

import { ICopyBoardProps } from 'src/components/CopyBoard/CopyBoard.type';

const CopyBoard = ({
  placement,
  copiedValue,
  className = '',
}: ICopyBoardProps) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
  };

  const onTextCopied = (event: SyntheticEvent) => {
    event.preventDefault();

    copyTextToClipboard(copiedValue).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <div className={className} onClick={onTextCopied}>
      <Tooltip placement={placement} title={t(isCopied ? 'copied' : 'copy')}>
        <>
          <CopyIcon />
        </>
      </Tooltip>
    </div>
  );
};

export default CopyBoard;
