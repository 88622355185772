import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import enCommon from 'src/i18n/en/common.json';
import enEkyc from 'src/i18n/en/ekyc.json';
import enAuth from 'src/i18n/en/auth.json';
import enOrder from 'src/i18n/en/order.json';
import enPayment from 'src/i18n/en/payment.json';
import enStaticQr from 'src/i18n/en/static-qr.json';
import enRefund from 'src/i18n/en/refund.json';
import enDownloadApp from 'src/i18n/en/download-app.json';
import viCommon from 'src/i18n/vi/common.json';
import viEkyc from 'src/i18n/vi/ekyc.json';
import viAuth from 'src/i18n/vi/auth.json';
import viOrder from 'src/i18n/vi/order.json';
import viPayment from 'src/i18n/vi/payment.json';
import viStaticQr from 'src/i18n/vi/static-qr.json';
import viRefund from 'src/i18n/vi/refund.json';
import viDownloadApp from 'src/i18n/vi/download-app.json';

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'vi',
    fallbackLng: 'vi',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    resources: {
      en: {
        common: enCommon,
        ekyc: enEkyc,
        auth: enAuth,
        order: enOrder,
        payment: enPayment,
        staticQr: enStaticQr,
        refund: enRefund,
        downloadApp: enDownloadApp,
      },
      vi: {
        common: viCommon,
        ekyc: viEkyc,
        auth: viAuth,
        order: viOrder,
        payment: viPayment,
        staticQr: viStaticQr,
        refund: viRefund,
        downloadApp: viDownloadApp,
      },
    },
    ns: [
      'common',
      'ekyc',
      'auth',
      'order',
      'payment',
      'staticQr',
      'downloadApp',
    ],
    defaultNS: 'common',
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br'],
    },
  });
