import { ReactNode, SyntheticEvent } from 'react';

export enum ButtonColorType {
  Gray = 'gray',
  Purple = 'purple',
  Red = 'red',
  PrimaryGradient = 'primary-gradient',
}

export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Text = 'text',
}

export enum ButtonSize {
  Small = 'small',
  Middle = 'middle',
  Large = 'large',
}

export interface ButtonProps {
  type: ButtonType;
  colorType: ButtonColorType;
  size?: ButtonSize;
  title?: string | ReactNode;
  className?: string;
  onClickHandled?: (event: SyntheticEvent) => void;
  disable?: boolean;
  icon?: ReactNode;
  subIcon?: ReactNode;
  isSubmit?: boolean;
  id?: string;
}
