import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { PaymentMethodEnum } from 'src/components/Payment';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';
import { ResponseStatus, SourceType, UserBusinessError } from 'src/types';

const useSubmitPayment = () => {
  const { paymentTransactionId = '' } = useParams();
  const navigate = useNavigate();

  const { mutate: submitOrder } = useMutation({
    mutationFn: async (variable: {
      appliedTokenDetails: any;
      orderId: string;
    }) => {
      const parameter = variable.appliedTokenDetails?.cardNumber
        ? {
            tokenId: variable.appliedTokenDetails?.token,
          }
        : {
            paymentMethod: variable.appliedTokenDetails?.token,
          };

      try {
        const response = await axios.post(apiStrings.payment.submit, {
          ...generateDefaultAuthValues(),
          callbackUrl: `${window.location.protocol}//${window.location.hostname}${routeStrings.orderStatus}/${variable.orderId}`,
          paymentTransactionId,
          ...parameter,
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: response => {
      const {
        paymentAction = '',
        orderId = '',
        amount = 0,
        paymentUrl = '',
        paymentStatus = 'INITIAL',
        virtualAccount = null,
        expiredIn = Date.now(),
        paymentTransactionId: transactionId = '',
      } = response.data;

      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === UserBusinessError.UserNotMatch) {
          return navigate(`${routeStrings.error}`, {
            state: {
              resultCode: response.data.resultCode,
              resultMsg: response.data.resultMsg,
            },
          });
        }

        if (response.data.resultCode === UserBusinessError.UserLocked) {
          return navigate(
            `${routeStrings.checkout.defaultPath}/${paymentTransactionId}/${routeStrings.checkout.error}`,
            {
              state: {
                resultCode: response.data.resultCode,
                resultMsg: response.data.resultMsg,
              },
            }
          );
        }
      }

      if (paymentAction === PaymentMethodEnum.VirtualAccount) {
        return navigate(`${routeStrings.payment.virtualAccount}`, {
          state: {
            virtualAccount,
            expiredIn,
            amount,
            orderId,
            transactionId,
            paymentStatus,
          },
        });
      }

      if (paymentAction === 'REDIRECT' && !!paymentUrl) {
        return (window.location.href = paymentUrl);
      }

      navigate(`${routeStrings.orderStatus}/${orderId}`, {
        state: {
          paymentTransactionId,
        },
      });
    },
    onError: (error: any) => {
      if (error?.response && error?.response?.status === 401) {
        return navigate(
          `${routeStrings.auth.defaultPath}/${paymentTransactionId}?sourceType=${SourceType.Order.toLowerCase()}`,
          {
            state: {
              callbackUrl: window.location.pathname,
            },
            replace: true,
          }
        );
      }

      navigate(routeStrings.error);
    },
  });
  return {
    submitOrder,
  };
};

export default useSubmitPayment;
