import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import { ReactComponent as SuccessfulImage } from 'src/assets/images/svg/successful.svg';
import Button from 'src/components/Button';
import { SecondaryLayout } from 'src/components/Layout';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { SourceType } from 'src/types';

const Success = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onBack = () => {
    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  useEffect(() => {
    if (
      state?.sourceType &&
      state?.sourceType.toUpperCase() === SourceType.Order
    ) {
      const intervalId = setInterval(onBack, 3000);
      return () => clearInterval(intervalId);
    }

    return;
  }, []);

  return (
    <SecondaryLayout
      logo={<Logo />}
      image={<SuccessfulImage />}
      title={t('ekyc:identityStatus.identitySuccessfulTitle')}
      description={t('ekyc:identityStatus.identitySuccessfulDescription')}
      footer={
        <Button
          title={t('done')}
          type={ButtonType.Default}
          colorType={ButtonColorType.PrimaryGradient}
          size={ButtonSize.Large}
          onClickHandled={onBack}
        />
      }
    />
  );
};

export default Success;
