import { PropsWithChildren } from 'react';
import {
  Navigate,
  NavigationType,
  useLocation,
  useNavigationType,
  useParams,
} from 'react-router-dom';

import { ProtectedRoute, routeStrings } from 'src/routes';

import { EkycPreviousStep, SourceType } from 'src/types';

interface EkycRouteProps extends PropsWithChildren {
  skipPopAction?: boolean;
}

const EkycRoute = ({ children, skipPopAction = false }: EkycRouteProps) => {
  const { referenceId } = useParams();
  const navigationType = useNavigationType();
  const { state } = useLocation();

  if (!skipPopAction && navigationType !== NavigationType.Push) {
    const isOrderSourceType =
      (state?.sourceType || '').toUpperCase() === SourceType.Order;
    const paymentChannel = isOrderSourceType
      ? `&paymentChannel=${state?.paymentChannel || 'web'}`
      : '';
    const scanQr = isOrderSourceType ? `&scanQr=${state?.scanQr || false}` : '';

    return (
      <ProtectedRoute>
        <Navigate
          to={`${routeStrings.ekyc.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || SourceType.Register).toLowerCase()}&previousStep=${EkycPreviousStep.Register.toLowerCase()}${paymentChannel}${scanQr}`}
          state={{
            callbackUrl: state?.callbackUrl,
            sourceType: state?.sourceType || SourceType.Register,
          }}
          replace
        />
      </ProtectedRoute>
    );
  }

  return <ProtectedRoute>{children}</ProtectedRoute>;
};

export default EkycRoute;
