import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';

import { ResponseStatus, SourceType } from 'src/types';

const useGetQrDetails = () => {
  const { qrId = '' } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ['get-qr-details'],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${apiStrings.staticQr.getQrDetails}?qrId=${qrId}`
        );

        if (response.data.status === ResponseStatus.BusinessError) {
          throw response.data.data;
        }

        return response?.data;
      } catch (error: any) {
        if (error?.response && error?.response?.status === 401) {
          navigate(
            `${routeStrings.auth.defaultPath}/${qrId}?sourceType=${SourceType.Order.toLowerCase()}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        navigate(routeStrings.error, {
          state: error,
        });
        throw error;
      }
    },
    select: response => {
      const { data: qrDetailsResponse } = response;

      return {
        merchantName: qrDetailsResponse?.merchantProfiles.merchantName || '',
        merchantAddress: qrDetailsResponse?.merchantProfiles.storeAddress || '',
        merchantLogo: qrDetailsResponse?.merchantProfiles.logo || '',
        minOrderValue: qrDetailsResponse?.merchantProfiles.minOrderValue || 0,
        maxOrderValue:
          qrDetailsResponse?.merchantProfiles.maxOrderValue || undefined,
        currency: qrDetailsResponse?.currency || '',
      };
    },
  });

  return {
    qrDetails: data,
    isQrDetailsFetching: isLoading,
  };
};

export default useGetQrDetails;
