import {
  InstallmentPlanType,
  MerchantInfoType,
  PackageDetails,
} from 'src/components/Order/CheckoutDetails/OrderDetails';
import { ReceiptDetailsType } from 'src/components/Order/CheckoutDetails/ReceiptDetails';
import { ExportingReceipt } from 'src/components/Order/CheckoutDetails/ReceiptExporting';

export enum CheckoutStep {
  Checkout = 'CHECKOUT',
  InstallmentPlan = 'INSTALLMENT_PLAN',
  Promotion = 'PROMOTION',
  Billing = 'Billing',
}

export enum PackageType {
  Bnpl = 'BNPL',
  BnplPlus = 'BNPL_PLUS',
  PayNow = 'PAYNOW',
  Revolving = 'REVOLVING',
}

export enum PromotionStatus {
  Available = 'AVAILABLE',
  TypeNotSupport = 'TYPE_NOT_SUPPORT',
  Expired = 'EXPIRED',
  Unavailable = 'UNAVAILABLE',
}

export enum EkycStatus {
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Approved = 'APPROVED',
}

export interface Promotion {
  code: string;
  title: string;
  description: string;
  amount: number;
  isDisplay: boolean;
}

export interface AppliedPromotion extends Promotion {
  status: PromotionStatus;
}

export interface CheckoutDetailsType {
  merchantInfo: MerchantInfoType;
  installmentPlans: InstallmentPlanType;
  promotions: Promotion[];
  appliedPromotions: AppliedPromotion[];
  receiptDetails: ReceiptDetailsType;
  currency: string;
  ekycStatus: EkycStatus;
  highPriorityPackageNumber: number;
  userId: string;
}

export interface CheckoutDetailsProps {
  orderDetails: {
    merchantInfo: MerchantInfoType;
    installmentPlans: InstallmentPlanType;
    promotions: Promotion[];
  };
  appliedPromotions: AppliedPromotion[];
  receiptDetails: ReceiptDetailsType;
  exportingReceipt: ExportingReceipt | null;
  currency: string;
  highPriorityPackageNumber: number;
  appliedPackageDetails: PackageDetails;
  userEkycStatus: EkycStatus;
  setAppliedPackage: (appliedPackage: string) => void;
  onStepChanged: (step: CheckoutStep) => void;
}
