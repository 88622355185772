const APPROVED_ORDER = 'APPROVED';
const AUTHORIZED_ORDER = 'AUTHORIZED';
const CANCELED_ORDER = 'CANCELLED';
const EXPIRED_ORDER = 'EXPIRED';
const PROCESSING_ORDER = 'PROCESSING';
const REJECTED_ORDER = 'REJECTED';
const CANCELED_PAYMENT = 'CANCELLED';
const FAILED_PAYMENT = 'FAIL';
const PENDING_PAYMENT = 'PENDING';
const REFUNDED_PAYMENT = 'REFUNDED';
const REFUNDING_PAYMENT = 'REFUNDING';
const SUCCESS_PAYMENT = 'SUCCESS';
const WAITING_PAYMENT = 'WAITING';

export enum OrderStatus {
  Approved = APPROVED_ORDER,
  Authorized = AUTHORIZED_ORDER,
  Canceled = CANCELED_ORDER,
  Expired = EXPIRED_ORDER,
  Processing = PROCESSING_ORDER,
  Rejected = REJECTED_ORDER,
}

export enum PaymentStatus {
  Canceled = CANCELED_PAYMENT,
  Failed = FAILED_PAYMENT,
  Pending = PENDING_PAYMENT,
  Refunded = REFUNDED_PAYMENT,
  Refunding = REFUNDING_PAYMENT,
  Success = SUCCESS_PAYMENT,
  Waiting = WAITING_PAYMENT,
}

export interface PaymentDetails {
  paymentAmount: number;
  paymentMethod: string;
  paymentMethodName: string;
  paymentStatus: string;
  paymentTime: number;
  currency: string;
  reason?: string;
}

export interface StatusProps {
  status: OrderStatus;
  orderId: string;
  paymentDetails: PaymentDetails;
}
