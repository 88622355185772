import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { STORAGE_KEY } from 'src/constants';
import { apiStrings } from 'src/services';
import { routeStrings } from 'src/routes';
import { generateDefaultAuthValues, storeLocalStorage } from 'src/utils';

import { ResponseStatus } from 'src/types';

const useLogin = () => {
  const navigate = useNavigate();
  const { referenceId } = useParams();
  const { state } = useLocation();
  const [loginError, setLoginError] = useState('');

  const { mutate: login, isLoading } = useMutation({
    mutationFn: async (variables: { pin: string }) => {
      try {
        const response = await axios.post(apiStrings.auth.login, {
          ...generateDefaultAuthValues(state?.authRequestId || ''),
          pin: variables.pin,
        });

        return response.data || '';
      } catch (error) {
        throw error;
      }
    },

    onSuccess: response => {
      if (response.status === ResponseStatus.BusinessError) {
        if (response.data.resultCode === ResponseStatus.RequestExpired) {
          const paymentChannel = state?.paymentChannel
            ? `&paymentChannel=${state?.paymentChannel}`
            : '';
          const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';

          return navigate(
            `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
            {
              state,
              replace: true,
            }
          );
        }

        return setLoginError(response.data.resultMsg);
      }

      storeLocalStorage(
        STORAGE_KEY.accessToken,
        `${response.data.tokenType} ${response.data.accessToken}`
      );

      if (
        state?.callbackUrl &&
        state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
      ) {
        return navigate(response.data.callbackUrl, {
          state,
        });
      }

      return (window.location.href =
        process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
    },

    onError: () => {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      });
    },
  });

  return { login, loginError, isLogging: isLoading, setLoginError };
};

export default useLogin;
