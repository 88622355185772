import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import IdentityPromotionImage from 'src/assets/images/png/continueIdentify.png';
import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';

import { routeStrings } from 'src/routes';

const IdentityPromotion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { referenceId } = useParams();
  const { state } = useLocation();

  const onBack = () => {
    if (
      !!state?.callbackUrl &&
      state?.callbackUrl !== process.env.REACT_APP_FUNDIIN_HOMEPAGE
    ) {
      return navigate(state?.callbackUrl);
    }

    return (window.location.href =
      process.env.REACT_APP_FUNDIIN_HOMEPAGE || '');
  };

  const onContinueIdentity = () => {
    navigate(
      `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.trueID}`,
      {
        state,
      }
    );
  };

  return (
    <div className="identity-promotion">
      <div className="header">
        <h3 className="header__title label label--md">
          {t('ekyc:process.identityPromotionTitle')}
        </h3>
      </div>

      <div className="body">
        <img src={IdentityPromotionImage} alt="" className="body__image" />

        <div className="body-content">
          <p className="label label--lg body-content__title">
            {t('ekyc:process.identityPromotion')}
          </p>
          <p className="paragraph paragraph--sm body-content__description">
            {t('ekyc:process.identityPromotionDescription')}
          </p>
        </div>

        <div className="body-content">
          <p className="label label--lg body-content__title">
            {t('ekyc:process.beTrusted')}
          </p>

          <ul className="paragraph paragraph--sm body-content__description">
            <li>{t('ekyc:process.beTrustedDescription1')}</li>
            <li>{t('ekyc:process.beTrustedDescription2')}</li>
          </ul>
        </div>
      </div>

      <div className="footer">
        <Button
          title={t('ekyc:process.continueToIdentity')}
          type={ButtonType.Default}
          colorType={ButtonColorType.PrimaryGradient}
          size={ButtonSize.Large}
          onClickHandled={onContinueIdentity}
        />
        <Button
          title={t('exit')}
          type={ButtonType.Text}
          colorType={ButtonColorType.Red}
          size={ButtonSize.Large}
          onClickHandled={onBack}
        />
      </div>
    </div>
  );
};

export default IdentityPromotion;
