import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BankAccountManyQuery } from 'src/features/Refund';
import { apiStrings } from 'src/services';
import { ResponseStatus } from 'src/types';

const useSavedAccounts = () => {
  const { data } = useQuery<BankAccountManyQuery>({
    queryKey: ['bank_getSavedAccounts'],
    queryFn: async () => {
      try {
        const response = await axios.post(apiStrings.refund.getSavedAccounts);
        if (response.data.status === ResponseStatus.BusinessError) {
          throw response.data.data;
        }

        return response.data?.data;
      } catch (error: any) {
        throw error;
      }
    },
  });

  return {
    savedAccounts: data?.bankAccounts || [],
  };
};

export default useSavedAccounts;
