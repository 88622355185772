import classNames from 'classnames';
import { cloneDeep, find, orderBy, uniqBy } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'src/assets/images/svg/arrow-right.svg';
import { ReactComponent as CircleInfoIcon } from 'src/assets/images/svg/circle-info.svg';
import { ReactComponent as NeedEkyc } from 'src/assets/images/svg/need-ekyc.svg';
import { ReactComponent as PendingEkyc } from 'src/assets/images/svg/pending-ekyc.svg';
import { Drawer } from 'src/components/Drawer';
import Button from 'src/components/Button';
import { EkycStatus, PackageType } from 'src/components/Order';
import { PackageDetails } from 'src/components/Order/CheckoutDetails/OrderDetails';
import { DATA_LAYER_VARIABLES, TRIGGER_EVENTS } from 'src/constants';
import { usePaymentPlan } from 'src/hooks/checkout';
import { routeStrings } from 'src/routes';
import { actionTracking, ModuleTracking, numberFormatter } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { EkycPreviousStep, SourceType } from 'src/types';
import { InstallmentSelectionProps } from 'src/components/Order/CheckoutDetails/InstallmentSelection/InstallmentSelection.type';

const InstallmentSelection = ({
  ekycStatus,
  orderId,
  orderAmount,
  packages,
  bnplPlusPercents,
  selectedPercent,
  selectedPackage,
  appliedPackageDetails,
  currency,
  setSelectedPercent,
  setSelectedPackage,
}: InstallmentSelectionProps) => {
  const { t } = useTranslation();
  const { sessionId = '' } = useParams();
  const navigate = useNavigate();
  const [openLeastValueDesc, setOpenLeastValueDesc] = useState<boolean>(false);
  const [openTempValueDesc, setOpenTempValueDesc] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>(
    appliedPackageDetails?.type || ''
  );
  const queryString = new URLSearchParams(window.location.search);
  const { paymentPlans } = usePaymentPlan(orderId, selectedPercent);

  useEffect(() => {
    if (selectedPackage) {
      return;
    }

    setSelectedPackage(appliedPackageDetails?.packageId || '');
  }, []);

  const onDrawerClosed = () => {
    if (openLeastValueDesc) {
      return setOpenLeastValueDesc(false);
    }

    setOpenTempValueDesc(false);
  };

  const getDrawerTitle = () => {
    if (openLeastValueDesc) {
      return t('order:checkout.installment.atLeastValue');
    }

    return t('order:checkout.installment.subPaymentAmount');
  };

  const onLeastValueDescOpen = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOpenLeastValueDesc(true);
  };

  const packageTypes = useMemo(() => {
    const cloneDeepPackages = cloneDeep(packages);
    return uniqBy(orderBy(cloneDeepPackages, 'type'), 'type').map(
      packageItem => ({
        label: t(
          `order:checkout.installment.${packageItem.type.toLowerCase()}`
        ),
        value: packageItem.type,
      })
    );
  }, [packages]);

  const targetPackage = useMemo(() => {
    const target = find(
      paymentPlans,
      packageItem => packageItem.packageId === selectedPackage
    );

    return {
      downPayment: target ? target.firstInstallmentAmount : 0,
      payLaterValue: target ? target.subSeqInstallmentAmount : 0,
    };
  }, [paymentPlans, selectedPackage]);

  const dataTracking = (packageTarget?: PackageDetails) => {
    actionTracking(
      TRIGGER_EVENTS.selectTenor,
      ModuleTracking.Order,
      'order_checkout',
      {
        [DATA_LAYER_VARIABLES.orderId]: orderId,
        [DATA_LAYER_VARIABLES.ekycStatus]: ekycStatus,
        [DATA_LAYER_VARIABLES.tenor]: packageTarget?.packageId,
        [DATA_LAYER_VARIABLES.orderAmount]: orderAmount,
        [DATA_LAYER_VARIABLES.totalPayment]:
          packageTarget?.firstInstallmentAmount,
        [DATA_LAYER_VARIABLES.serviceFee]: packageTarget?.serviceFee,
        [DATA_LAYER_VARIABLES.downPayment]:
          packageTarget?.firstInstallmentAmount,
      }
    );
  };

  const onPackageTypeChanged = (newPackageType: string) => {
    if (newPackageType === PackageType.PayNow) {
      const target = find(
        packages,
        packageItem => packageItem.type === newPackageType
      );
      dataTracking(target);
      setSelectedPackage(target?.packageId || '');
    } else {
      setSelectedPackage('');
    }
    setSelectedType(newPackageType);
  };

  const onPercentChanged = (newPercent: number) => {
    setSelectedPackage('');
    setSelectedPercent(newPercent);
  };

  const onUserIdentified = () => {
    navigate(
      `${routeStrings.ekyc.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&previousStep=${EkycPreviousStep.Order}&paymentChannel=${queryString.get('paymentChannel') || 'web'}&scanQr=${queryString.get('scanQr') || false}`,
      {
        state: {
          callbackUrl: window.location.pathname,
        },
      }
    );
  };

  return (
    <section className="installment-selection">
      <div className="installment-choice">
        {packageTypes.map(packageType => (
          <div
            key={packageType.value}
            className={classNames('installment-choice__item', {
              'installment-choice__item--selected':
                selectedType === packageType.value,
            })}
            onClick={() => onPackageTypeChanged(packageType.value)}
          >
            <p className="paragraph paragraph--sm">{packageType.label}</p>
          </div>
        ))}
      </div>

      <div className="installment-desc">
        <div className="installment-desc-amount">
          <div className="installment-desc-amount__item">
            <p className="paragraph paragraph--xs">
              {t('order:checkout.installment.downPayment')}
            </p>

            <p className="heading heading--xs installment-desc-amount__item--primary">
              {`${numberFormatter(targetPackage.downPayment)} ${t(`currency.${currency}`)}`}
            </p>
          </div>

          {selectedType !== PackageType.PayNow && (
            <div className="installment-desc-amount__item">
              <p className="paragraph paragraph--xs">
                {t('order:checkout.installment.subPaymentAmount')}
              </p>

              <p className="heading heading--xs installment-desc-amount__item--primary">
                {`${numberFormatter(targetPackage.payLaterValue)} ${t(`currency.${currency}`)}`}
                <CircleInfoIcon onClick={() => setOpenTempValueDesc(true)} />
              </p>
            </div>
          )}
        </div>

        {ekycStatus !== EkycStatus.Approved &&
          selectedType !== PackageType.PayNow && (
            <div
              className={classNames('installment-desc-ekyc', {
                'installment-desc-ekyc--needed':
                  ekycStatus === EkycStatus.Waiting,
                'installment-desc-ekyc--pending':
                  ekycStatus === EkycStatus.Pending,
              })}
            >
              <div className="installment-desc-ekyc__content">
                <p className="label label--sm">
                  {t(
                    `order:checkout.installment.${ekycStatus === EkycStatus.Waiting ? 'identifyAccount' : 'pendingDocument'}`
                  )}
                </p>

                <p className="paragraph paragraph--xs installment-desc-ekyc__content--secondary">
                  {t(
                    `order:checkout.installment.${ekycStatus === EkycStatus.Waiting ? 'identifyAccountContent' : 'pendingDocumentContent'}`
                  )}
                </p>

                {ekycStatus === EkycStatus.Waiting && (
                  <Button
                    type={ButtonType.Default}
                    colorType={ButtonColorType.PrimaryGradient}
                    size={ButtonSize.Large}
                    title={
                      <p className="label label--sm installment-desc-ekyc__content--center">
                        {`${t('order:checkout.installment.identifyNow')}  `}
                        <ArrowRight />
                      </p>
                    }
                    className="installment-desc-ekyc__button"
                    onClickHandled={onUserIdentified}
                  />
                )}
              </div>

              {ekycStatus === EkycStatus.Waiting ? (
                <NeedEkyc />
              ) : (
                <PendingEkyc />
              )}
            </div>
          )}
      </div>

      {selectedType !== PackageType.PayNow && (
        <>
          {selectedType === PackageType.BnplPlus && (
            <div className="down-payment">
              <p className="label label--xs pb--16">
                {t('order:checkout.installment.downPayment').toUpperCase()}
              </p>

              <div className="down-payment-list">
                {bnplPlusPercents.map(bnplPlusPercent => (
                  <div
                    key={bnplPlusPercent}
                    className={classNames('down-payment-list__item', {
                      'down-payment-list__item--selected':
                        selectedPercent === bnplPlusPercent,
                    })}
                    onClick={() => onPercentChanged(bnplPlusPercent)}
                  >
                    <p className="label label--sm">
                      {bnplPlusPercent === -1
                        ? t('order:checkout.installment.atLeastValue')
                        : `${bnplPlusPercent}%`}
                    </p>

                    {bnplPlusPercent === -1 && (
                      <CircleInfoIcon onClick={onLeastValueDescOpen} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="payment-plan">
            <p className="label label--xs pb--16">
              {t('order:checkout.installment.paymentPlan').toUpperCase()}
            </p>

            <div className="payment-plan-list">
              {paymentPlans
                .filter(
                  (packageItem: PackageDetails) =>
                    packageItem.type === selectedType
                )
                .map((packageItem: PackageDetails) => (
                  <div
                    key={packageItem.packageId}
                    className={classNames('payment-plan-list__item', {
                      'payment-plan-list__item--selected':
                        packageItem.packageId === selectedPackage,
                    })}
                    onClick={() => {
                      dataTracking(packageItem);
                      setSelectedPackage(packageItem.packageId);
                    }}
                  >
                    <p className="label label--sm">{packageItem.packageName}</p>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}

      <Drawer
        open={openLeastValueDesc || openTempValueDesc}
        title={getDrawerTitle()}
        onClose={onDrawerClosed}
      >
        <div className="installment-selection-drawer">
          {openLeastValueDesc && (
            <p className="paragraph paragraph--sm">
              {t('order:checkout.installment.atLeastValueContent')}
            </p>
          )}

          {openTempValueDesc && (
            <>
              <p className="paragraph paragraph--sm installment-selection-drawer__item">
                <div className="installment-selection-drawer__note" />
                {t('order:checkout.installment.firstSubPaymentAmount')}
              </p>
              <p className="paragraph paragraph--sm installment-selection-drawer__item">
                <div className="installment-selection-drawer__note" />
                {t('order:checkout.installment.secondSubPaymentAmount')}
              </p>
            </>
          )}

          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            title={t('order:checkout.installment.close')}
            className="installment-selection-drawer__button"
            size={ButtonSize.Large}
            onClickHandled={onDrawerClosed}
          />
        </div>
      </Drawer>
    </section>
  );
};

export default InstallmentSelection;
