import { Checkbox as AntdCheckbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { DecreeFormProps } from 'src/components/Auth/DecreeModule/index.type';

const DecreeForm = ({ onSubmit }: DecreeFormProps) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => setChecked(e.target.checked);

  return (
    <div className="decree-form">
      <div className="decree-form__content">
        <AntdCheckbox onChange={onChange} />
        <span className="paragraph paragraph--sm">
          {`${t('auth:decree.acceptWith')} `}
          <Link
            to={process.env.REACT_APP_DECREE_13 || ''}
            className="color--primary"
            target="_blank"
          >
            {t('auth:decree.privacy')}
          </Link>
          {`, `}
          <Link
            to={process.env.REACT_APP_CONTRACT_PRINCIPLES || ''}
            className="color--primary"
            target="_blank"
          >
            {t('auth:decree.contractPrinciples')}
          </Link>
          {`, `}
          <Link
            to={process.env.REACT_APP_DEFERRED_PURCHASE_CONTRACT || ''}
            className="color--primary"
            target="_blank"
          >
            {t('auth:decree.deferredPurchaseOrder')}
          </Link>
          {` ${t('auth:decree.and')} `}
          <Link
            to={process.env.REACT_APP_TNC || ''}
            className="color--primary"
            target="_blank"
          >
            {t('auth:decree.purchaseContract')}
          </Link>
          {` ${t('auth:decree.ofFundiin')}`}
        </span>
      </div>
      <Button
        title={t('continue')}
        size={ButtonSize.Large}
        type={ButtonType.Default}
        colorType={ButtonColorType.PrimaryGradient}
        onClickHandled={onSubmit}
        disable={!checked}
      />
    </div>
  );
};

export default DecreeForm;
