import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { apiStrings } from 'src/services';

const useUpdateBilling = () => {
  const { mutate } = useMutation({
    mutationFn: async (variables: any) => {
      try {
        const response = await axios.put(
          apiStrings.billing.update(variables.id),
          {
            userId: variables.userId,
            billingInfo: {
              fullName: variables.billingInfo.name,
              email: variables.billingInfo.email,
              address: variables.billingInfo.exportingAddress,
              tax: variables.billingInfo.taxNumber,
            },
          }
        );

        return response.data;
      } catch (error: any) {
        throw error;
      }
    },
  });

  return {
    updateBilling: mutate,
  };
};

export default useUpdateBilling;
