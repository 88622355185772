import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { QuaternaryLayoutProps } from 'src/components/Layout/QuaternaryLayout/QuaternaryLayout.type';

const QuaternaryLayout = ({
  title,
  closeIcon,
  onClosed,
  children,
  actionIcon,
  onActioned,
  confirmLabel,
  onConfirmed,
  disable = false,
}: QuaternaryLayoutProps) => {
  return (
    <section className="quaternary-layout">
      <header className="header">
        {closeIcon && onClosed && (
          <div className="header__icon" onClick={onClosed}>
            {closeIcon}
          </div>
        )}

        <h1 className="label label--md header__title">{title}</h1>

        {actionIcon && onActioned && (
          <div className="header__icon" onClick={onActioned}>
            {actionIcon}
          </div>
        )}
      </header>

      <main className="body">{children}</main>

      {confirmLabel && onConfirmed && (
        <footer className="footer">
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            title={confirmLabel}
            onClickHandled={onConfirmed}
            size={ButtonSize.Large}
            disable={disable}
          />
        </footer>
      )}
    </section>
  );
};

export default QuaternaryLayout;
