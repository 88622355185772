import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTrueID } from 'src/hooks/ekyc';

const TrueID = () => {
  const { state } = useLocation();
  const initTrueID = useTrueID();

  useEffect(() => {
    initTrueID({
      accessToken: state?.accessToken,
      clientId: state?.clientId,
      ekycId: state?.ekycId,
      sourceType: state?.sourceType,
      callbackUrl: state?.callbackUrl,
    });
  }, []);

  return <></>;
};

export default TrueID;
