import { ReactComponent as LoadingIcon } from 'src/assets/images/svg/loading.svg';

import { LoadingProps } from 'src/components/Loading/Loading.type';

const Loading = ({ width = 100, height = 100 }: LoadingProps) => {
  return (
    <div className="loading">
      <LoadingIcon className="loading-icon" width={width} height={height} />
    </div>
  );
};

export default Loading;
