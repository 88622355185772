import { useNavigate, useParams } from 'react-router-dom';
import {
  COLOR_GRAY_100,
  COLOR_GRAY_700,
  COLOR_GRAY_800,
  COLOR_GRAY_900,
  COLOR_PRIMARY_600,
  STORAGE_KEY,
} from 'src/constants';

import LangJson from 'src/features/Ekyc/lang.json';
import { routeStrings } from 'src/routes';

import { InitTrueID, SourceType } from 'src/types';
import { getLocalStorage } from 'src/utils';

const useTrueID = () => {
  const navigate = useNavigate();
  const { referenceId } = useParams();

  return async (variables: Partial<InitTrueID>) => {
    try {
      const headers = {
        ekycId: variables.ekycId,
        deviceId: getLocalStorage(STORAGE_KEY.deviceId),
      };

      const newConfiguration = {
        domain: process.env.REACT_APP_BASE_URL,
        configEndpoint: {
          front: '/true-id/id/verify/front',
          back: '/true-id/id/verify/back',
          selfie: '/true-id/face/liveness',
          complete: '/true-id/complete',
          nfcqrverify: '/true-id/nfcqr/verify',
          nfcrar: '/true-id/nfcqr/upload',
          ocr: '/true-id/id/ocr',
          nfcbshield: '/true-id/nfcqr/upload',
          createrequest: '/true-id/request/create',
          accesstoken: '',
        },
        clientId: variables.clientId,
        clientSecret: '',
        configHeader: JSON.stringify(headers),
        stepVerification: ['FRONT', 'BACK', 'SELFIE'],
        titleColor: COLOR_GRAY_900,
        subTitleColor: COLOR_GRAY_800,
        closeColor: COLOR_GRAY_700,
        buttonCaptureColor: COLOR_PRIMARY_600,
        titleButtonCaptureColor: COLOR_GRAY_100,
        backgroundColor: COLOR_GRAY_100,
        accessToken: variables.accessToken,
        requestId: '',
        maxRetry: 2,
      };

      (window as any).TrueIDSDK.setLanguageJson('vi', LangJson);
      (window as any).TrueIDSDK.start(newConfiguration, (data: any) => {
        if (data?.code === 1) {
          navigate(
            `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.processing}`,
            {
              state: {
                accessToken: variables?.accessToken,
                clientId: variables?.clientId,
                ekycId: variables?.ekycId,
                sourceType:
                  variables?.sourceType || SourceType.Register.toString(),
              },
            }
          );
        } else if (data?.code === 0) {
          navigate(
            `${routeStrings.ekyc.defaultPath}/${referenceId}/${routeStrings.ekyc.identityPromotion}`,
            {
              state: {
                accessToken: variables?.accessToken,
                clientId: variables?.clientId,
                ekycId: variables?.ekycId,
              },
            }
          );
        } else {
          navigate(routeStrings.error, {
            state: {
              callbackUrl: variables?.callbackUrl || '',
            },
          });
        }
      });
    } catch {
      navigate(routeStrings.error, {
        state: {
          callbackUrl: variables.callbackUrl || '',
        },
      });
    }
  };
};

export default useTrueID;
