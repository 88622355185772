import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'src/assets/images/svg/x-mark.svg';

import { CustomModalProps, ModalType } from 'src/components/Modal/Modal.type';

const CustomModal = ({
  open,
  title,
  children,
  type = ModalType.Custom,
  footer,
  width,
  closeIcon = true,
  onCancel,
  className = '',
  titleClassName = '',
}: CustomModalProps) => {
  return (
    <AntdModal
      open={open}
      className={classNames(className, {
        'custom-modal': type === ModalType.Custom,
        'confirm-modal': type === ModalType.Confirm,
      })}
      title={
        <div className="header">
          <div
            className={classNames('header__title heading heading--xs', {
              [titleClassName]: !!titleClassName,
            })}
          >
            {title}
          </div>

          {closeIcon && onCancel && (
            <CloseIcon className="cursor--pointer" onClick={onCancel} />
          )}
        </div>
      }
      footer={<div className="footer">{footer}</div>}
      width={width}
      closeIcon={false}
      centered
    >
      <div className="body">{children}</div>
    </AntdModal>
  );
};

export default CustomModal;
