import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';

import ValidatedError from 'src/components/ValidatedError';

import { InputProps } from 'src/components/Input/index.type';

const Input = ({
  type,
  name,
  placeholder,
  validate,
  maxLength,
  value = '',
  label = '',
  errorMessage = '',
  required = false,
  className = '',
  showCount = false,
  disabled = false,
  onValueChanged,
  prefix,
}: InputProps) => {
  const [field, meta] = useField({
    name,
    validate,
    value,
  });

  return (
    <div className="d-flex flex-col">
      {label && (
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>
      )}

      <AntdInput
        {...field}
        type={type}
        className={classNames({
          [className]: !!className,
        })}
        onChange={onValueChanged}
        status={meta.touched && meta.error ? 'error' : ''}
        placeholder={placeholder}
        showCount={showCount}
        maxLength={maxLength}
        disabled={disabled}
        prefix={prefix}
      />

      {validate && (
        <div className="input__error">
          <ValidatedError
            touched={meta.touched}
            error={!!errorMessage ? errorMessage : meta.error}
          />
        </div>
      )}
    </div>
  );
};

export default Input;
