import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { AutoCompleteProps, Flex, Input } from 'antd';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as BankIcon } from 'src/assets/images/svg/bank.svg';
import { AutoCompleteBase } from 'src/components/AutoComplete';
import {
  AccountRefundType,
  BankAccountManyQuery,
  BankAccountVerifyQuery,
  RefundResponseStatus,
  useRefundAccount,
} from 'src/features/Refund';

interface RefundAccountProps {
  onShowBankAccount: () => void;
  accountRefund: AccountRefundType | null;
  savedAccounts: BankAccountManyQuery['bankAccounts'];
  onSelectAccountNumber: (account: AccountRefundType) => void;
}

const RefundAccount = ({
  onShowBankAccount,
  accountRefund,
  savedAccounts,
  onSelectAccountNumber,
}: RefundAccountProps) => {
  const { t } = useTranslation();
  const { refundId } = useParams();

  const { detectAccount, isLoading: isLoadingDetectAccount } = useRefundAccount(
    { refundRequestId: refundId }
  );
  const [accountDetect, setAccountDetect] =
    useState<BankAccountVerifyQuery | null>();
  const [accountOptions, setAccountOptions] = useState<
    AutoCompleteProps['options']
  >([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const defaultAccountOptions = useMemo(
    () =>
      savedAccounts.map(account => ({
        label: account.accountNumber,
        value: account.accountNumber,
        ...account,
      })),
    [savedAccounts]
  );

  const detectBankAccount = () => {
    const accountNumberDetect = accountDetect?.beneficiaryNumber?.trim() || '';
    if (accountRefund && !accountNumberDetect) {
      onSelectAccountNumber({
        ...accountRefund,
        account: {
          name: '',
          number: accountNumberDetect,
        },
      });
      return;
    }

    if (!accountRefund?.bank?.id) {
      return;
    }

    onSelectAccountNumber({
      ...accountRefund,
      account: {
        name: '',
        number: accountNumberDetect,
      },
    });

    detectAccount(
      {
        beneficiaryNumber: accountNumberDetect,
        bin: accountRefund?.bank?.id,
      },
      {
        onSuccess: data => {
          if (data?.valid) {
            setErrorMessage('');
            onSelectAccountNumber({
              ...accountRefund,
              account: {
                name: data.beneficiaryName || '',
                number: accountNumberDetect,
              },
            });
          } else {
            setErrorMessage(
              data?.resultCode === RefundResponseStatus.UserNameNotMatch
                ? data?.resultMsg
                : t('refund:message.error.invalidAccountNumber')
            );
            setAccountOptions(defaultAccountOptions);
          }
        },
        onError: () => {
          setErrorMessage(t('refund:message.error.invalidAccountNumber'));
        },
      }
    );
  };

  const handleSelectOption: AutoCompleteProps['onSelect'] = (_, option) => {
    const {
      accountNumber,
      accountName,
      bankId,
      bankShortName,
      default: isDefault,
      logo,
    } = option;
    setErrorMessage('');
    setAccountDetect({
      beneficiaryNumber: accountNumber,
      beneficiaryName: accountName,
    });
    onSelectAccountNumber({
      account: {
        name: accountName,
        number: accountNumber,
      },
      bank: {
        name: bankShortName,
        logo: logo,
        id: bankId,
      },
      isDefault: isDefault,
    });
  };

  useEffect(() => {
    setAccountDetect({
      beneficiaryName: accountRefund?.account.name,
      beneficiaryNumber: accountRefund?.account.number,
    });
  }, [JSON.stringify(accountRefund?.account)]);

  useEffect(() => {
    setAccountOptions(defaultAccountOptions);
  }, [defaultAccountOptions]);

  return (
    <div className="refund-account">
      <Flex vertical gap={8}>
        <p className="label label--xs subheading">
          {t('refund:titles.refundAccount')}
        </p>

        {accountRefund?.bank?.logo && accountRefund?.bank?.name ? (
          <div className="bank-info" onClick={onShowBankAccount}>
            <div className="bank-img">
              <img src={accountRefund.bank.logo} alt="bank logo" />
            </div>
            <div className="bank-detail">
              <Flex vertical>
                <span className="paragraph paragraph--sm">
                  {t('refund:labels.beneficiaryBank')}
                </span>
                <p className="bank-name">{accountRefund.bank.name}</p>
              </Flex>
              <RightOutlined />
            </div>
          </div>
        ) : (
          <div className="refund-account__item" onClick={onShowBankAccount}>
            <Flex align="center" gap={10}>
              <BankIcon filter="grayscale(1)" />
              <span className="paragraph paragraph--sm">
                {t('refund:labels.beneficiaryBank')}
              </span>
            </Flex>
            <RightOutlined />
          </div>
        )}

        <Flex vertical>
          <span className="paragraph paragraph--sm mb--8">
            {t('refund:labels.accountNumber')}
          </span>
          <AutoCompleteBase
            onBlur={detectBankAccount}
            filterOption
            showSearch={!!accountRefund?.bank.id} // Enable search function for selected bank only
            options={accountOptions}
            value={accountDetect?.beneficiaryNumber}
            optionRender={option => {
              const { accountNumber, accountName, bankShortName, logo } =
                option.data;

              return (
                <Flex justify="space-between" align="center">
                  <Flex gap={10}>
                    <div className="bank-img">
                      <img src={logo} alt={bankShortName} />
                    </div>
                    <Flex vertical justify="center">
                      <p className="paragraph paragraph--sm">{bankShortName}</p>
                      <p className="paragraph paragraph--sm color--muted">
                        {accountName}
                      </p>
                    </Flex>
                  </Flex>
                  <p className="paragraph paragraph--sm">{accountNumber}</p>
                </Flex>
              );
            }}
            onSelect={handleSelectOption}
          >
            <Input
              placeholder={t('refund:placeholder.accountNumber')}
              variant="filled"
              value={accountDetect?.beneficiaryNumber}
              onChange={e => {
                setErrorMessage('');
                setAccountDetect({ beneficiaryNumber: e.target.value });
              }}
              suffix={isLoadingDetectAccount ? <LoadingOutlined /> : <></>}
            />
          </AutoCompleteBase>

          <span className="paragraph paragraph--xs color--error mt--16">
            {errorMessage}
          </span>
        </Flex>
      </Flex>
    </div>
  );
};

export default RefundAccount;
