import { Layout } from 'antd';

import { ReactComponent as Logo } from 'src/assets/images/svg/logo.svg';
import Button from 'src/components/Button';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { QuinaryLayoutProps } from './QuinaryLayout.type';

const { Header, Content, Footer } = Layout;

const QuinaryLayout = ({
  children,
  confirmButtonLabel = '',
  onConfirmed = undefined,
  confirmDisabled = false,
}: QuinaryLayoutProps) => {
  return (
    <Layout className="quinary-layout">
      <Header>
        <Logo />
      </Header>

      <Content className="body">{children}</Content>

      {!!confirmButtonLabel && !!onConfirmed && (
        <Footer className="footer">
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.PrimaryGradient}
            size={ButtonSize.Large}
            title={confirmButtonLabel}
            onClickHandled={onConfirmed}
            disable={confirmDisabled}
          />
        </Footer>
      )}
    </Layout>
  );
};

export default QuinaryLayout;
