import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from 'src/components/Button';
import { OtpInput } from 'src/components/Input';
import { PrimaryLayout } from 'src/components/Layout';
import { useCountDown } from 'src/hooks/common';
import { useOtp } from 'src/hooks/auth';
import { routeStrings } from 'src/routes';
import { convertToTime } from 'src/utils';

import {
  ButtonColorType,
  ButtonSize,
  ButtonType,
} from 'src/components/Button/Button.type';
import { UserBusinessError } from 'src/types';

const OtpModule = () => {
  const { t } = useTranslation();
  const { referenceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');

  const {
    resendCountdown,
    errorMsg,
    errorCode,
    sentChannel,
    resendOtp,
    verifyOtp,
    isResending,
    setErrorMsg,
    setErrorCode,
  } = useOtp();
  const { countdownTime, resetCountdown } = useCountDown(resendCountdown);

  useEffect(() => {
    if (otp.length === 1) {
      setErrorMsg('');
      setErrorCode('');
    }
  }, [otp]);

  const onBack = () => {
    const paymentChannel = state?.paymentChannel
      ? `&paymentChannel=${state?.paymentChannel}`
      : '';
    const scanQr = state?.scanQr ? `&scanQr=${state?.scanQr}` : '';

    navigate(
      `${routeStrings.auth.defaultPath}/${referenceId}?sourceType=${(state?.sourceType || '').toLowerCase()}${paymentChannel}${scanQr}`,
      {
        state: {
          callbackUrl: state?.callbackUrl || '',
        },
      }
    );
  };

  const onOtpResent = () => {
    resendOtp();
    resetCountdown(resendCountdown);
    setOtp('');
  };

  return (
    <PrimaryLayout
      className="otp-module w--100"
      title={t('auth:otp.inputOtp')}
      subTitle={
        <span>
          {!!sentChannel
            ? t('auth:otp.sentOtpWithChannel', { sentChannel })
            : t('auth:otp.sentOtp')}
          <strong className="label label--sm">{` ${state?.phoneNumber || ''}.`}</strong>
        </span>
      }
      onBack={onBack}
      subTitleClassName="mb--16"
      isMasterTitle
    >
      <>
        <div className="mb--32">
          <OtpInput
            value={otp}
            syncOtp={setOtp}
            error={errorMsg}
            submit={verifyOtp}
            disabled={errorCode === UserBusinessError.PhoneNumberOtpLock}
          />
        </div>
        <div className="paragraph paragraph--sm otp-module__resend">
          {t('auth:otp.notReceiveOtp')}
          <Button
            title={
              errorCode === UserBusinessError.PhoneNumberOtpLock
                ? t('auth:otp.resendOtp', { sentChannel: 'SMS' })
                : countdownTime && !isResending
                  ? `${t('auth:otp.resendOtp', { sentChannel: 'SMS' })} (${convertToTime(countdownTime)})`
                  : t('auth:otp.resendOtp', { sentChannel: 'SMS' })
            }
            type={ButtonType.Text}
            colorType={ButtonColorType.Purple}
            onClickHandled={onOtpResent}
            disable={
              !!countdownTime ||
              errorCode === UserBusinessError.PhoneNumberOtpLock ||
              isResending
            }
            size={ButtonSize.Middle}
          />
        </div>
      </>
    </PrimaryLayout>
  );
};

export default OtpModule;
