import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import { ExportingReceipt } from 'src/components/Order/CheckoutDetails/ReceiptExporting';
import { routeStrings } from 'src/routes';
import { SourceType } from 'src/types';

const useGetBillings = (userId: string) => {
  const { sessionId = '' } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data, refetch } = useQuery({
    queryKey: ['billing-list'],
    queryFn: async () => {
      try {
        const response = await axios.post(apiStrings.billing.getList, {
          ...generateDefaultAuthValues(),
          userId: userId,
        });

        return response.data.data;
      } catch (error: any) {
        if (error?.response && error?.response?.status === 401) {
          const paymentChannel = searchParams.get('paymentChannel') || 'web';
          const scanQr = searchParams.get('scanQr') || false;

          navigate(
            `${routeStrings.auth.defaultPath}/${sessionId}?sourceType=${SourceType.Order.toLowerCase()}&paymentChannel=${paymentChannel}&scanQr=${scanQr}`,
            {
              state: {
                callbackUrl: window.location.pathname,
              },
              replace: true,
            }
          );
          throw error;
        }

        throw error;
      }
    },
    select: response => {
      const { billingInfo = [] } = response;
      return billingInfo.map((billing: any) => ({
        id: billing?.id || '',
        name: billing?.fullName || '',
        email: billing?.email || '',
        exportingAddress: billing?.address || '',
        taxNumber: billing?.tax || '',
      }));
    },
  });

  return {
    exportingReceipts: (data || []) as ExportingReceipt[],
    refetchBillings: refetch,
  };
};

export default useGetBillings;
