import classNames from 'classnames';
import { repeat } from 'lodash';
import { useEffect } from 'react';
import OtpInput from 'react-otp-input';

import ValidatedError from 'src/components/ValidatedError';

import {
  OtpInputProps,
  OtpType,
} from 'src/components/Input/OtpInput/OtpInput.type';

const InputOtp = ({
  value,
  submit,
  syncOtp,
  error,
  inputClassName = '',
  placeholder = '-',
  length = 6,
  isSecured = false,
  disabled = false,
}: OtpInputProps) => {
  useEffect(() => {
    if (!!error && value.length === length) {
      syncOtp('');
    }
  }, [error, value]);

  useEffect(() => {
    if (value.length === length && submit) {
      submit({ otpCode: value });
    }
  }, [value]);

  const onOtpChanged = (currentValue: string) => {
    syncOtp(currentValue);
  };

  return (
    <>
      <OtpInput
        value={value}
        onChange={onOtpChanged}
        numInputs={length}
        placeholder={repeat(placeholder, length)}
        renderInput={(props, index) => (
          <input {...props} disabled={disabled} autoFocus={index === 0} />
        )}
        inputType={OtpType.Tel}
        containerStyle="otp"
        inputStyle={classNames('otp__input label label--lg', {
          [inputClassName]: !!inputClassName,
          'is-secured': isSecured,
        })}
        shouldAutoFocus={!disabled && !!error}
        skipDefaultStyles
      />

      <div className="otp__error">
        <ValidatedError touched error={error} />
      </div>
    </>
  );
};

export default InputOtp;
