import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyEmail } from 'src/assets/images/svg/empty-mail.svg';
import { ReactComponent as LeadingIcon } from 'src/assets/images/svg/leading-icon.svg';
import { Drawer } from 'src/components/Drawer';
import BillingForm from 'src/components/Order/CheckoutDetails/BillingInfo/BillingForm';
import { ExportingReceipt } from 'src/components/Order/CheckoutDetails/ReceiptExporting';
import { useGetBillings } from 'src/hooks/billing';

import { BillingInfoProps } from 'src/components/Order/CheckoutDetails/BillingInfo/BillingInfo.type';

const initialBillingDetails: ExportingReceipt = {
  id: '',
  name: '',
  email: '',
  exportingAddress: '',
  taxNumber: '',
};

const BillingInfo = ({
  userId,
  openBillingDrawer,
  onBillingDrawerOpened,
  onBillingDrawerClosed,
  onBack,
  setExportingReceipt,
}: BillingInfoProps) => {
  const { t } = useTranslation();
  const { exportingReceipts, refetchBillings } = useGetBillings(userId);
  const [billingDetails, setBillingDetails] = useState<ExportingReceipt>(
    initialBillingDetails
  );

  useEffect(() => {
    if (!openBillingDrawer) {
      setBillingDetails(initialBillingDetails);
    }
  }, [openBillingDrawer]);

  const onBillingModified = (event: any, billing: ExportingReceipt) => {
    event.stopPropagation();
    setBillingDetails(billing);
    onBillingDrawerOpened();
  };

  const onExportingReceiptSelected = (exportingReceipt: ExportingReceipt) => {
    setExportingReceipt(exportingReceipt);
    onBack();
  };

  return (
    <>
      <section className="billing">
        {exportingReceipts.length < 1 ? (
          <div className="billing--empty">
            <EmptyEmail />
            <p className="paragraph paragraph--sm">
              {t('order:checkout.billing.emptyEmail')}
            </p>
          </div>
        ) : (
          <>
            <p className="ml--20 label label--sm">
              {t('order:checkout.billing.savedExportingEmail')}
            </p>

            <div className="billing-list">
              {exportingReceipts.map((exportingReceipt: ExportingReceipt) => (
                <div
                  key={exportingReceipt.id}
                  className="billing-wrapper"
                  onClick={() => onExportingReceiptSelected(exportingReceipt)}
                >
                  <div className="billing-wrapper-item">
                    <div className="billing-wrapper-item__label">
                      <p className="label label--sm">{exportingReceipt.name}</p>
                    </div>

                    <LeadingIcon
                      onClick={event =>
                        onBillingModified(event, exportingReceipt)
                      }
                    />
                  </div>
                  <p className="paragraph paragraph--sm">
                    {exportingReceipt.email}
                  </p>
                  <p className="paragraph paragraph--sm">
                    {exportingReceipt.exportingAddress}
                  </p>
                  {exportingReceipt.taxNumber && (
                    <p className="paragraph paragraph--sm">
                      {exportingReceipt.taxNumber}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </section>

      <Drawer
        open={openBillingDrawer}
        title={t(
          `order:checkout.billing.${!!billingDetails.id ? 'billingInfo' : 'addTitle'}`
        )}
        headerClassName="ant-billing-form-header"
      >
        <BillingForm
          userId={userId}
          billingDetails={billingDetails}
          onBillingDrawerClosed={onBillingDrawerClosed}
          refetchBillings={refetchBillings}
        />
      </Drawer>
    </>
  );
};

export default BillingInfo;
