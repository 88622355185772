import { TFunction } from 'i18next';
import { includes, isArray, isNumber, isString, split, trim } from 'lodash';

import { regexEmail, regexPhoneNumber } from 'src/constants';

export const validateRequired = (
  t: TFunction,
  value: any,
  fieldName: string
) => {
  if (
    (isString(value) && !trim(value)) ||
    (isNumber(value) && !value) ||
    (isArray(value) && value.length < 1)
  ) {
    return t('validation.required', { fieldName });
  }

  return '';
};

export const validatePhoneNumber = (
  t: TFunction,
  value: string,
  fieldName: string,
  isRequired: boolean = true
) => {
  let error = isRequired ? validateRequired(t, value, fieldName) : '';

  const phoneNumberRegex = new RegExp(regexPhoneNumber, 'g');
  if (
    !error &&
    (!phoneNumberRegex.test(trim(value)) ||
      (!includes(trim(value), '+') && split(trim(value), '')[0] !== '0'))
  ) {
    error = t('validation.invalidPhoneNumber');
  }

  if (!!error && !isRequired && !value) {
    error = '';
  }

  return error;
};

export const validateEmail = (
  t: TFunction,
  value: string,
  fieldName: string
) => {
  let error = validateRequired(t, value, fieldName);

  const emailRegex = new RegExp(regexEmail, 'g');

  if (!error && !emailRegex.test(trim(value))) {
    error = t('validation.invalidEmail');
  }

  return error;
};
