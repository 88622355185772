import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdleDetection = (idleTimeTrigger: number = 5000) => {
  const [isIdle, setIdle] = useState<boolean>(false);

  const onIdleAction = () => setIdle(!isIdle);

  const idleTimer = useIdleTimer({
    timeout: idleTimeTrigger,
    onIdle: onIdleAction,
    stopOnIdle: true,
    debounce: 500,
  });

  return {
    isIdle,
    idleTimer,
  };
};

export default useIdleDetection;
