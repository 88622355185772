import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { STORAGE_KEY } from 'src/constants';
import { routeStrings } from 'src/routes';
import { apiStrings } from 'src/services';
import { generateDefaultAuthValues } from 'src/utils';

import { ResponseStatus, SourceType } from 'src/types';

const useInitTransaction = () => {
  const { qrId = '' } = useParams();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (variables: {
      amount: number;
      currency: string;
      description: string;
      referralCode: string;
    }) => {
      try {
        const response = await axios.post(apiStrings.staticQr.initTransaction, {
          ...generateDefaultAuthValues(),
          qrId,
          description: variables.description,
          referralCode: variables.referralCode,
          amount: {
            value: variables.amount,
            currency: variables.currency,
          },
        });

        return response?.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: response => {
      const { data: initTransactionResponse } = response;

      if (response.status === ResponseStatus.BusinessError) {
        return navigate(`${routeStrings.error}`, {
          state: {
            resultCode: response.data.resultCode,
            resultMsg: response.data.resultMsg,
          },
        });
      }

      if (
        (initTransactionResponse?.nextStep || '').toUpperCase() ===
        SourceType.Order
      ) {
        sessionStorage.setItem(
          STORAGE_KEY.callbackUrl,
          `${routeStrings.staticQr}/${qrId}`
        );
        sessionStorage.setItem(
          STORAGE_KEY.checkoutSession,
          initTransactionResponse.sessionId || ''
        );
        return navigate(
          `${routeStrings.checkout.defaultPath}/${initTransactionResponse.sessionId || ''}`,
          {
            state: {
              callbackUrl: `${routeStrings.staticQr}/${qrId}`,
            },
          }
        );
      }

      window.location.href = process.env.REACT_APP_FUNDIIN_HOMEPAGE || '';
    },
    onError: (error: any) => {
      if (error?.response && error?.response?.status === 401) {
        return navigate(
          `${routeStrings.auth.defaultPath}/${qrId}?sourceType=${SourceType.Order.toLowerCase()}`,
          {
            state: {
              callbackUrl: window.location.pathname,
            },
            replace: true,
          }
        );
      }

      navigate(routeStrings.error);
    },
  });

  return {
    initTransaction: mutate,
    isTransactionInitiating: isLoading,
  };
};

export default useInitTransaction;
