import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import { Field, useField } from 'formik';

import ValidatedError from 'src/components/ValidatedError';

import { TextAreaProps } from 'src/components/Input/index.type';

const { TextArea: AntdTextArea } = AntdInput;

const TextArea = ({
  name,
  label,
  placeholder,
  validate,
  maxLength,
  required = false,
  className = '',
  showCount = false,
}: TextAreaProps) => {
  const [field, meta] = useField(name);

  return (
    <div className="d-flex flex-col">
      <label className="label label--md mb--8">
        {label}
        {required && <span className="color--error ml--8">*</span>}
      </label>

      <AntdTextArea
        {...field}
        className={classNames({
          [className]: !!className,
          'border--error': meta.touched && meta.error,
        })}
        placeholder={placeholder}
        maxLength={maxLength}
        showCount={showCount}
      />

      {validate && <Field {...field} validate={validate} hidden />}

      <ValidatedError touched={meta.touched} error={meta.error} />
    </div>
  );
};

export default TextArea;
